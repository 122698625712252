import gtcOne from '@/assets/pdfs/datenschutzhinweis_one.pdf';

export default {
    locale: 'en',
    messages: {
        'common.hide': 'Hide',
        'common.pistenkilometer': 'slope kilometers',
        'common.cablecars': 'cablecars',
        'common.wind': '{speed} wind speed',
        'common.showTickets': 'Show tickets',
        'common.userAccount': 'User account',
        'common.login': 'Login',
        'common.register': 'Register',
        'common.registerLogin': 'Register/Login',
        'common.basket': 'Basket',
        'common.passwordForgot': 'Forgot Password?',
        'common.toBasket': 'add to basket',
        'common.requestHelp': 'Request support',
        'common.singleDayTicket': 'Up to 1 day offers',
        'common.dayTicket': '{duration} day ticket',
        'common.dayTickets': '{from} - {to} day tickets',
        'common.dayTickets+': '{from}+ day tickets',
        'common.seasonTicket': 'Special and season tickets',
        'common.seasonTickets': 'Special and season tickets',
        'common.ticket': 'ticket',
        'common.tax': 'including tax',
        'common.save': 'Save',
        'common.orderCard': 'Order keycard',
        'common.abort': 'Cancel',
        'common.createNewCard': 'Order new keycard',
        'common.status': 'Status',
        'common.changePassword': 'Change password',
        'common.changeEmail': 'Change email',
        'common.pdf': 'PDF',
        'common.dataLoading': 'Loading...',
        'common.keycard': 'Keycard',
        'common.orderKeycard': 'Order keycard',
        'common.linkExternalCard': 'Register keycard',
        'common.required': 'Required',

        'footer.help': 'Support',
        'footer.gtc': 'GTC',
        'footer.imprint': 'Imprint',
        'footer.dataPrivacy': 'Data Privacy',
        'footer.payment': 'Payment',
        'footer.hotline':
            'For support contact us directly at +43.6246.73430 999',

        'footer.statusinformation.label': 'Status information',

        'footer.linkColumn.insuracenProductPage': 'Insurance Safety+ / Refund+',

        'navigation.bookTickets': 'Book Ski Tickets',
        'navigation.about': 'About starjack',
        'navigation.faq': 'FAQ',
        'navigation.voucher': 'Voucher',

        'liftoperators.tooltipRegion':
            'Enter a location from where you wish to start your search.',
        'liftoperators.tooltipPistenkilometer':
            'Search Ski Area by ski slope kilometers. The list of providers and tickets will be filtered to your selection.',
        'liftoperators.tooltipAnlagen':
            'Search Ski area by number of lifts. The list of providers and tickets will be filtered to your selection.',
        'liftoperators.maxDuration': 'Can be arrived in {duration} minutes',
        'liftoperators.chooseLocation': 'Choose location',
        'liftoperators.locationSearch': 'Location search',
        'liftoperators.pistenkilometer': 'Slope kilometers {km}+',
        'liftoperators.liftanlagen': 'Number of cablecars {count}+',
        'liftoperators.map': 'Map',
        'liftoperators.list': 'List',

        'LiftoperatorItem.tooltipBlueDot': 'easy slope',
        'LiftoperatorItem.tooltipRedDot': 'medium slope',
        'LiftoperatorItem.tooltipBlackDot': 'difficult slope',

        'LiftoperatorItemCompressed.chooseLiftoperator':
            'Choose another ski area',

        'SupportPage.help': 'Support',
        'SupportPage.faq': 'Search FAQs',
        'SupportPage.openFaq': 'Go to FAQs',
        'SupportPage.contactEmail': 'Contact us via mail',
        'SupportPage.contactPhone': 'Contact us via phone',

        'ActivationPage.activation': 'Activation',
        'ActivationPage.success':
            'Activation was successful. You are logged in automatically.',
        'ActivationPage.error': 'Activation not found or already done.',

        'FAQPage.faq': 'FAQ',
        'FAQPage.searchFaqs': 'Search FAQ',

        'ProductsPage.tooltipDatum':
            'Enter the date of your preferred ski day here, If no tariff is available on this day, the first day with an available Tariff will be selected instead.',
        'ProductsPage.tooltipDauer':
            'In order to find your tickets more quickly and precisely, a filter can be used here so that only these types of tickets are shown in the results window to the right.',
        'ProductsPage.tooltipTagespreis':
            'Here you can narrow your ticket by price, multi-day tickets will be shown as price per day.',
        'ProductsPage.tooltipTickettyp':
            'Here you can narrow your ticket search by the age of the person.',
        'ProductsPage.chooseStartDate': 'Choose start date',
        'ProductsPage.durationFilter': 'Filter duration',
        'ProductsPage.dayPrice': 'Day price {price}',
        'ProductsPage.noTicketsFound':
            'No tickets found for the choosen filter.',
        'ProductsPage.noTicketsFoundBeforeEarliestDay':
            'Next tickets available from {earliestDay}.',
        'ProductsPage.allDurations': 'Duration',
        'ProductsPage.allSkiers': 'Age Group',
        'ProductsPage.photoRequired':
            'photo required, only bookable with keycards including a photo',

        'Product.birthday': 'Birthday {from} - {to}',
        'Product.age': 'Age {from} - {to}',
        'Product.ageGroup': 'Age group {from} - {to}',
        'Product.availability': 'Available:',
        'Product.availability.at': 'on {day}',
        'Product.availability.fromTo': 'from {from} - {to}',
        'Product.startDay.from': 'Valid from:',
        'Product.startDay.at': 'Valid',

        'Cart.pay': 'Pay',
        'Cart.total': 'Total',
        'Cart.toCheckout': 'go to checkout',
        'Cart.empty': 'Your shopping basket is empty',
        'Cart.credit':
            'You have a credit of {amount} and will be used for payment.',
        'Cart.selectKeycardOption': 'Select the missing kecard option',

        'Order.orderedAt': 'Ordered at',
        'Order.free': 'FREE',

        'AboutPage.welcomeHeadline':
            'Welcome in the world of Starjack!<br/>With your Starjack Keycard you have access to',
        'AboutPage.skiRegions': 'Ski regions',
        'AboutPage.skiRegionsDescription':
            'regions just waiting to be discovered. Get inspired by our various offers',
        'AboutPage.liftoperators': 'Lifts',
        'AboutPage.liftoperatorsDescription':
            'To take you to the best slopes and the most unforgettable panoramas',
        'AboutPage.slopes': 'Ski Slopes km',
        'AboutPage.slopesDescription':
            'with {statisticSlopeKms} kilometers of ski slopes to exceed your expectations',
        'AboutPage.personalKeyCard':
            'Get your very own personal<br/>starjack Keycard.',
        'AboutPage.personalKeyCardDescription':
            'Order once and charge your ski tickets online for many years.',
        'AboutPage.fastAndSimpleKeyCardHeadline':
            'Get your personal Starjack Keycard<br/>fast and simple',
        'AboutPage.orderColumnLabel': 'Order',
        'AboutPage.orderColumnDescription':
            'Create your free starjack account, configure your Keycard and give us your delivery details.',
        'AboutPage.shippingColumnLabel': 'Keycard delivery',
        'AboutPage.shippingColumnDescription':
            'Receive your Keycard with express post, it is valid for many years and can be loaded with ski passes online as often as you like.',
        'AboutPage.deliveryColumnLabel': 'Skipass booking in real time',
        'AboutPage.deliveryColumnDescription':
            'From now on, you book your ski pass directly to your starjack Keycard in real-time, online or on your mobile via your starjack account.',
        'AboutPage.starjackSummitDescriptionTop':
            'Starjack was born of a loving passion for mountain sports which has made it into what it is today: Your Starjack Keycard is a fast and stress-free way of getting onto the most exciting and the most beautiful ski slopes that the world of skiing can offer.',
        'AboutPage.starjackSummitHeadline':
            "With Starjack<br/>you'll be first on the peak",
        'AboutPage.starjackSummitDescriptionBottom':
            "Book your ticket for your preferred ski area, in just a few clicks your ticket will be transferred directly onto your Starjack Keycard. At home or out-and-about. Within seconds you'll have your ski ticket loaded onto your personal Starjack Keycard. Then it's straight through the turnstile and onto the ski slopes - No more waiting in line at the ticket office.<br/><br/><b>Fast, Independent, Simple</b>",
        'AboutPage.advantagesHeadline': 'Advantages',
        'AboutPage.advantage1Label': 'Modern Keycard',
        'AboutPage.advantage1Description':
            'use it at all our partner ski resorts',
        'AboutPage.advantage2Label': 'Customisable',
        'AboutPage.advantage2Description': 'with your perspnal photo',
        'AboutPage.withoutWaiting': 'No waiting time',
        'AboutPage.withoutWaitingDescription':
            "You'll never have to stand in the ticket line of our partner ski resorts ever again.<br/>After making your ski resort reservation your booking will be immediately transferred onto your Startjack Keycard<br/>You can start using it as soon as you want.<br/>It's that easy!",
        'AboutPage.payment': 'Payment Methods',
        'AboutPage.configuration': 'Create your personal card now',
        'AboutPage.createCard': 'order your card',

        'AboutPageSummer.personalKeyCard':
            'Get your very own starjack keycard right now!',
        'AboutPageSummer.personalKeyCardDescription':
            'Whatever attracts you to the mountain, you can conveniently book your ticket online. Just order once and charge as often as you like with your tickets. In real time, this means that your ticket will be ready for use a few seconds after booking.',
        'AboutPageSummer.registerExternalKeycardButtonContainer':
            'REGISTER SKITICKET',
        'AboutPageSummer.registerExternalKeycardDescription':
            'You still have a ski ticket at home?<br/>Perfect, then log in to your Starjack account and see if this keycard fits for online bookings:',
        'AboutPageSummer.shippingColumnDescription':
            'Receive your Keycard with express post, it is valid for many years and you can recharge it with online mountain transport tickets as often as you like.',
        'AboutPageSummer.deliveryColumnLabel': 'Real-time ticket booking',
        'AboutPageSummer.deliveryColumnDescription':
            'From now on you can book your mountain transport tickets directly to your starjack Keycard within seconds –  either online or via your mobile device, using your starjack account.',

        'Address.email': 'Email',
        'Address.phone': 'Telephone',
        'Address.newEmail': 'New email',
        'Address.salutation': 'Gender/Title',
        'Address.gender': 'Gender',
        'Address.salutation.male': 'm',
        'Address.salutation.female': 'f',
        'Address.title': 'Title',
        'Address.firstname': 'First name',
        'Address.lastname': 'Surname',
        'Address.street': 'Street and streetnumber',
        'Address.zipcode': 'Postcode',
        'Address.city': 'Town/City',
        'Address.country': 'Country',
        'Address.company': 'Company',
        'Address.password': 'Password',
        'Address.password1': 'Password',
        'Address.password2': 'retype password',
        'Address.currentPassword': 'Current Password',
        'Address.newPassword': 'New Password',
        'Address.birthday': 'Birth date',
        'Address.birthday.day': 'Day',
        'Address.birthday.month': 'Month',
        'Address.birthday.year': 'Year',
        'Address.agb1': 'I agree to the',
        'Address.agb2': 'terms and conditions',
        'Address.agb3': 'of the company SJack GmbH (starjack.com).',
        'Address.newsletterDE': 'Newsletter in german',
        'Address.newsletterEN': 'Newsletter in english',
        'Address.serialNumber': 'Serial Number',
        'Address.serialNumberExplanation':
            'Serial Number: Enter the 23-digit serial number as stated on the reverse of your SKIDATA Keycard (without hyphens or spaces). Only keycards whose ID starts with "01" can be registered.',
        'Address.marketingAcceptance':
            'I agree to the forwarding of my personal data for advertising purposes to the mountain railways with which I make the ticket bookings. The consent can be withdrawn at any time via my account ("my data" menu).',

        'ProfileForm.dataChanged': 'Data changed',

        'ActivationForm.save': 'Finish registration',
        'ChangePasswordForm.save': 'Change password',
        'ChangePasswordForm.passwordChanged': 'Password changed',
        'ChangeEmailForm.save': 'Change email',
        'ChangeEmailForm.emailChanged':
            'We have sent you an email to confirm the change. Please click on the link.',
        'LoginForm.login': 'Login',
        'LoginForm.passwordForgotten': 'Forgot your password?',
        'PasswordForgottenForm.info':
            'We have sent you an email with directions for how you can reset your password.',
        'PasswordForgottenForm.reset': 'Reset Password',
        'RegisterForm.register': 'Register',

        'validation.error.email.required': 'Please enter your mail address.',
        'validation.error.birthday.missing': 'Please enter your birthdate.',
        'validation.error.email.invalid': 'Your mail address is invalid.',
        'validation.error.email.long': 'Your mail address is too long.',
        'validation.error.salutation.required': 'Please choose a salutation.',
        'validation.error.firstname.required': 'Please enter your firstname.',
        'validation.error.firstname.short': 'Your first name is too short.',
        'validation.error.firstname.long': 'Your first name is too long.',
        'validation.error.lastname.required': 'Please enter your last name.',
        'validation.error.lastname.short': 'Your last name is too short.',
        'validation.error.lastname.long': 'Your last name is too long.',
        'validation.error.title.long': 'Your title is too long.',
        'validation.error.street.required': 'Please enter a street.',
        'validation.error.zipcode.required': 'Please enter a zip code.',
        'validation.error.zipcode.number':
            'Only zip codes with numbers are allowed.',
        'validation.error.city.required': 'Please enter a city.',
        'validation.error.country.required': 'Please enter a country.',
        'validation.error.password.requiredShort': 'Please enter a password.',
        'validation.error.password.required':
            'Please enter a password with at least 6 characters.',
        'validation.error.password.match': "The passwords don't match.",
        'validation.error.password.current':
            'Please enter your current password.',
        'validation.error.gtc.required': 'Please read and accept the GTCs',
        'validation.error.assignKeycard':
            'Please assign a keycard or create a new one.',
        'validation.error.gender.required': 'Please choose the gender.',
        'validation.error.keycard.title.long': 'The title is too long.',
        'validation.error.keycard.firstname.required':
            'Please enter a first name.',
        'validation.error.keycard.firstname.long': 'First name is too long.',
        'validation.error.keycard.lastname.required':
            'Please enter the last name.',
        'validation.error.keycard.lastname.long': 'Last name is too long.',
        'validation.error.keycard.name.long':
            'First name and last name exceed the limit of 39 characters.',
        'validation.error.keycard.birthday.required':
            'Birthday is not complete.',
        'validation.error.keycard.birthday.invalid': 'Birthday is invalid.',
        'validation.error.orderAddress.firstname.required':
            'Please enter the first name.',
        'validation.error.orderAddress.firstname.long':
            'First name is too long.',
        'validation.error.orderAddress.lastname.required':
            'Please enter the last name.',
        'validation.error.orderAddress.lastname.long': 'Last name is too long.',
        'validation.error.orderAddress.company.long': 'Company is too long.',
        'validation.error.orderAddress.street.required':
            'Please enter the street and street number.',
        'validation.error.orderAddress.street.long': 'Street is too long.',
        'validation.error.orderAddress.zipcode.required':
            'Please enter the zip code.',
        'validation.error.orderAddress.zipcode.long': 'Zip code is too long.',
        'validation.error.orderAddress.city.required': 'Please enter the city.',
        'validation.error.orderAddress.city.long': 'City is too long.',
        'validation.error.orderAddress.deliveryLocked':
            'Unfortunately we can not currently deliver to the chosen country',
        'validation.error.product_not_found':
            'The tickets in your shopping basket are no longer valid, please delete this ticket order and start over.',
        'validation.error.duplicate_ticket':
            'You already have a ticket that is valid for this time period.',
        'validation.error.unknownError':
            'Unknown error occurred. Please try again later.',
        'validation.error.registration.userExists':
            'User already esists. Please use another mail address.',
        'validation.error.passwordForgotten.recoveryFailed':
            "Your password couldn't be reset.",
        'validation.error.login.failed': 'Login failed.',
        'validation.error.changePassword.incorrectPassword': 'Wrong password',
        'validation.error.changePassword.incorrect_password': 'Wrong password',
        'validation.error.changeEmail.email': 'Invalid email',
        'validation.error.changeEmail.incorrectPassword': 'Wrong password',
        'validation.error.changeEmail.incorrect_password': 'Wrong password',
        'validation.error.payment.unknownError':
            'Unknown error occurred during the payment. Please reload the page and try again.',
        'validation.error.checkout.unknownError': 'Unknown error occurred.',
        'validation.error.checkout.incert_voucher_not_found':
            'Invalid voucher code',
        'validation.error.checkout.incert_voucher_not_partly_redeemable':
            'Voucher is not partly redeemable',
        'validation.error.checkout.incert_voucher_not_redeemable':
            'Voucher is not redeemable',
        'validation.error.checkout.incert_voucher_depleted':
            'Voucher is depleted',
        'validation.error.checkout.lift_operator_not_active':
            'Unfortunately, tickets cannot be purchased from the selected mountain railway at the moment.',
        'validation.error.linkExternalKeycard.invalid_serial_number':
            'Invalid serial number',
        serial_number_already_registered: 'Keycard already registered',
        'validation.error.mustBeginWithZeroOne':
            'Only keycards with a serial number beginning with "01" can be registered',

        'validation.external_code_needs_keycard_identification':
            'A ticket with promotion code can only be used with a keycard that is activated for this specific promotion code',
        'validation.external_code_not_present':
            'Promotion code not existing on keycard',

        'validation.error.lengthMustBeTwentyThree':
            'The SKIDATA code must be 23 characters',

        'OrderCardPage.headline': 'Your own<br/>personal Card',
        'OrderCardPage.description1':
            "Order your own personal Starjack Keycard and you'll never have to wait in line again.<br>You can get straight onto the slopes and enjoy more time skiing.",
        'OrderCardPage.description2':
            'Your Starjack Keycard it is valid for many years from time of order and is not transferrable. You can also order more Starjack Keycards for the rest of your family using your personal Starjack account (first register), Cable Car tickets and Ski Passes can also be added to your Keycard.',

        'CheckoutPage.emptyBasket': 'Your basket is empty',
        'CheckoutPage.createAnotherCard': 'Order additional keycard',
        'CheckoutPage.goToPay': 'Go to payment',
        'CheckoutPage.orderWillBeChecked': 'Your order will be processed',
        'CheckoutPage.creditCardPrice':
            'The amount of {price} will be charged to your selected payment type',
        'CheckoutPage.creditCardBalancePrice':
            'The amount of {price} will be charged from your balance',
        'CheckoutPage.creditCardBalancePriceIncert':
            'The amount of {price} will be charged from your voucher',
        'CheckoutPage.orderCharge': 'Confirm payment',
        'CheckoutPage.abortPayment': 'Abort payment',
        'CheckoutPage.balancePaymentInfo':
            'Your remaining credit covers the total amount of the order meaning no further credit card payment is required. Please confirm the payment by credit and complete your order.',
        'CheckoutPage.balancePaymentInfoIncert':
            'Your voucher credit covers the total amount of the order meaning no further credit card payment is required. Please confirm the payment by voucher and complete your order.',
        'CheckoutPage.infoNewKeycard':
            'New keycard - please fill the fields below',
        'CheckoutPage.infoKeycard':
            '{title}, valid until: {expirationDate}, SN: {serialNumber}',
        'CheckoutPage.infoExternalKeycard': '{title}, SN: {serialNumber}',
        'CheckoutPage.profileChooser': 'Saved credit cards',
        'CheckoutPage.saveProfile':
            'Save your credit card securely for future transactions.',
        'CheckoutPage.useNewCreditCard': 'Enter new credit card data',
        'CheckoutPage.creditcard': 'Credit card',
        'CheckoutPage.sofort': 'Sofortüberweisung',
        'CheckoutPage.ideal': 'iDEAL',
        'CheckoutPage.eps': 'Bank transfer',
        'CheckoutPage.masterpass': 'Masterpass',
        'CheckoutPage.apple': 'Apple Pay',
        'CheckoutPage.google': 'Google Pay',
        'CheckoutPage.covidCheck':
            'I have read, understood and accepted the additional Covid19-declaration for purchasing online tickets',
        'CheckoutPage.covidCheckMore': 'View Covid19-declaration',
        'CheckoutPage.insuranceDataCard.title':
            'Insurance and emergency contact data',
        'CheckoutPage.insurance.phone.alert':
            'Your telephone number is required for an order with insurance cover',

        'CheckoutPage.insurance.birthdate.alert':
            'For an order with insurance protection, you  must provide your birthdate',

        'CheckoutPage.insurance.contatctData.warning':
            'For an order with insurance protection, you have to provide your phone number and birthdate.',

        'CheckoutPage.insurance.input.phone.label':
            'Telephone number with country code',

        'CheckoutPage.insurance.input.phone.error':
            'Please enter your phone number',

        'CheckoutPage.insurance.input.birthdate.error':
            'Please enter your birthdate',

        'CheckoutPage.insurance.enter.success':
            'Insurance contact saved successfully',
        'CheckoutPage.insurance.enter.error':
            'Error saving the insurance contact. Please try again later',

        'CheckoutPage.insurance.contactData.dialog.title':
            'Missing contact data',
        'CheckoutPage.consent.downloadButton.label':
            'Download pre-contractual documents',

        'CheckoutPage.consent.downloadButton.label.downloaded':
            'Pre-contractual downloaded',

        'CheckoutPage.insurance.explanation': `
            <ul style="padding-left: 16px;">
                <li>The insurance contract is only concluded when the insurance certificate is delivered. There is no insurance cover before this point in time. After delivery of the insurance policy, insurance cover is provided from the desired start date that you specified when booking, provided that you pay the premium on time and in full.</li>
                
                <li>You can find One's privacy policy <a href="${gtcOne}" target="_blank" rel="noreferrer">here</a></li>
                      
                <li>
                  You can find starjack's privacy policy <a href="/gtc" target="_blank" rel="noreferrer">here</a>
                </li>
            </ul>
        `,

        'Checkoutpage.insurance.consent.title': 'Consenst declaration',

        'CheckoutPage.consent.missing.error':
            'Please accept the terms and conditions in the consection declaration and download the explanation in order to finish the checkout',

        'CheckoutSuccessPage.headline': 'Thanks for your order!',
        'CheckoutSuccessPage.text':
            'You will receive a confirmation by e-mail.',

        'CheckoutErrorPage.headline': 'No payment made',
        'CheckoutErrorPage.text':
            'The order was canceled. No payment was made. Please try again.',

        'LoginPage.notRegistered': 'Not yet registered?',
        'LoginPage.notRegisteredText':
            'With your registration you are opening your Personal User Account which will enable you to instantly book all of your cable car, lift tickets and ski passes from all of our starjack partners. Waiting in the ticket line will be a memory from the past!',

        'MyCardsPage.headline': 'My Starjack keycards',
        'MyCardsPage.noKeycard': "You don't have a keycard yet.",
        'MyCardsPage.validTo': 'valid until<br/>{date}',
        'MyCardsPage.serialNumber': 'serial number',
        'MyCardsPage.delete': 'Delete card',
        'MyCardsPage.transfer': 'Transfer card',
        'MyCardsPage.transferErrorEmailNotFound': 'Receiver e-mail not found',
        'MyCardsPage.inTransfer': 'Keycard is in transfer to {email}',
        'MyCardsPage.cancelTransfer': 'Cancel transfer',
        'MyCardsPage.transferSuccessful': 'Transfer successful',
        'MyCardsPage.transferError': 'Transfer failed',
        'MyCardsPage.deletedAt': 'Card deleted at {deletionDate}',
        'MyCardsPage.showInactive': 'Show inactive cards',
        'MyCardsPage.confirmKeycardDeletion':
            'The keycard will be irrevocably deleted and can no longer be used for online bookings',
        'MyCardsPage.verify': 'Enter promotion code',
        'MyCardsPage.verifiedCodes': 'Promotion codes',

        'MyOrdersPage.headline': 'My Orders',
        'MyOrdersPage.invoice': 'Receipt',
        'MyOrdersPage.invalidInvoice': 'No invoice available for this order.',
        'MyOrdersPage.receiptSAsPdf': 'Receipt as PDF',

        'CreditCardsPage.headline': 'Saved credit cards',
        'CreditCardsPage.confirmProfileDeletion':
            'Do you really want to delete your credit card?',
        'CreditCardsPage.noDataFound':
            'No credit cards saved. Credit cards can be saved in the payment process.',

        'Profile.enterYourPassword': 'Enter your password here',
        'Profile.oldPassword': 'Old password',
        'Profile.newPassword': 'New password',
        'Profile.passwordRepeat': 'Repeat password',
        'Profile.myData': 'My Personal Information',
        'Profile.addMoreLogins': 'Add more logins',
        'Profile.lastLogin': 'Last logged in',
        'Profile.starjackLogin': 'starjack.com with e-mail and password',
        'Profile.manageLogins': 'Manage logins',

        'Profile.deleteAccount': 'Delete your account',
        'Profile.saveChanges': 'Save changes',
        'Profile.enterYourEmailAndPassword':
            'Enter your E-mail and your current password',
        'Profile.changeEmailActivation':
            'Change e-mail equest successful. In order to confirm this process, please click on the confirmation link in the e-mail we just sent you.',
        'Profile.changeEmailError':
            'Error while processing your request. Please check your input and try it again.',
        'Profile.confirmAccountDeletion':
            'Do you really want to delete your account?',
        'Profile.myCreditCards': 'My credit cards',
        'Profile.deleteAccountError':
            'Your account deletion failes. Please check the password.',
        'Profile.newsletter': 'Newsletter',
        'Profile.errorChangeEmailFetchUser':
            'Error fetching the user. Please try again later',

        'Newsletter.de': 'Newsletter in german',
        'Newsletter.en':
            "BEST NEWSLETTER EVER!<br/> Yeah okay, maybe we're exaggerating a little. But we promise you:<br/>no spam! You will only receive relevant information from us about the online booking service and your great options with your starjack keycard.",

        'PasswordForgottenPage.headline': 'Forgot your password?',

        'PasswordForgottenResetPage.headline': 'Reset your password',

        'RegisterPage.text':
            'Your registration was successful.<br/>We sent you a registration email<br/>. Please open it to complete your registration',

        'RegisterPage.gtcCheckbox.portal.label':
            'I agree to the <a href="/gtc">GTC</a> of SJack GmbH (starjack.com) and have read the <a href="{{statusinfo-pdf-link}}">status information</a> for insurance intermediaries as well as the data protection notices.',

        'RegisterPage.gtcCheckbox.integration.label':
            'I agree to the <a href="/gtc">GTC</a> of SJack GmbH (starjack.com).',

        'MyAccountNavigation.register': 'Register',
        'MyAccountNavigation.backToRegistration': 'Back to registration',
        'MyAccountNavigation.myCards': 'Keycards',
        'MyAccountNavigation.myTickets': 'Tickets',
        'MyAccountNavigation.myOrders': 'Orders',
        'MyAccountNavigation.myInsurances': 'My insurances',
        'MyAccountNavigation.myFavorites': 'My Favorites',
        'MyAccountNavigation.buyTickets': 'Buy Tickets',
        'MyAccountNavigation.myData': 'My information',
        'MyAccountNavigation.changeCredentials': 'Login data',
        'MyAccountNavigation.creditCardProfiles': 'Credit cards',
        'MyAccountNavigation.favorites': 'Favorites',
        'MyAccountNavigation.coupon': 'Redeem a coupon',
        'MyAccountNavigation.logout': 'Logout',
        'MyAccountNavigation.credit': 'Credit',
        'MyAccountNavigation.redeemVoucher': 'View / Redeem voucher',
        'MyAccountNavigation.reportClaim': 'Report Claim',

        'CheckoutAddressPage.headline': 'Delivery- and invoice address',

        'keycard.status.ORDERED': 'ordered',
        'keycard.status.ORDERED_INTERNAL': 'ordered',
        'keycard.status.PRODUCED': 'produced and sent',
        'keycard.status.NEW': 'ordered',
        'keycard.status.LOCKED': 'locked',
        'keycard.external': 'external keycard',

        'CheckoutKeyCardForm.clickHere': 'Upload Photo',
        'CheckoutKeyCardForm.foto':
            'We recommend including a photo where the face is clearly visible, without sunglasses etc. - in order to be able to book tickets that require a photo.',
        'CheckoutKeyCardForm.fotoDelete': 'Delete Photo ',
        'CheckoutKeyCardForm.subline1':
            'We recommend adding a photo when booking tickets. Some Ski Resorts may require keycards to have photos. ',
        'CheckoutKeyCardForm.subline2':
            'Please note: photos cannot be added after ordering! ',
        'CheckoutKeyCardForm.subline1external':
            'If no up-to-date photo is printed on the keycard you register here, we recommend ordering a starjack keycard with a photo.  ',
        'CheckoutKeyCardForm.subline2external':
            'The reason is possible ski pass checks in the ski area, especially when booking multi-day and season tickets! ',

        'CheckoutTicketsPage.redeemVoucherButton': 'Redeem voucher',
        'CheckoutTicketsPage.receiveKeyCard':
            'How do you want to book your ticket?',
        'CheckoutTicketsPage.born': 'Born',
        'CheckoutTicketsPage.bornOn': 'Born {from} - {to}',
        'CheckoutTicketsPage.chooseCard': 'Choose keycard',
        'CheckoutTicketsPage.newKeyCardOption': 'Order a new keycard',
        'CheckoutTicketsPage.newExternalKeyCardOption':
            'Register an existing keycard',
        'CheckoutTicketsPage.ignoreError':
            'Order the ticket anyway and transfer it onto the keycard',
        'CheckoutTicketsPage.barcodeOption':
            'Print@home-Ticket Does not require a Keycard. You will receive your barcode-ticket for direct access to the booked mountain railway / leisure facility by email. Print out recommendend.',
        'CheckoutTicketsPage.internalProductionOption':
            'Lift operator produced and shipped: Does not require a KeyCard. You will receive a KeyCard including the Ticket through the mail.',
        'CheckoutTicketsPage.pickupOption':
            'Pickup at front desk or vending machine: Does not require a KeyCard. You will receive a pickup code to Print via Email. Bring the pickup code to the cash desk to claim your Keycard and Ticket',
        'CheckoutTicketsPage.mobileFlowBookable':
            'Bookable as a smartphone ticket',

        'ConfirmEmailPage.headline': 'Change email',
        'ConfirmEmailPage.success': 'Email successfully changed.',
        'ConfirmEmailPage.error':
            'An error occurred while changing the email address.',

        'HomePage.headline':
            'Book your tickets from 100+ lift providers – using just one account!',
        'HomePage.subline':
            'A day of skiing, tobogganing or hiking through a winter wonderland – whatever your plans, with starjack you’re flexible enough to book your (ski) ticket in a matter of seconds, ready for instant use. From hour ticket to all-year pass –  check out the tickets on offer by your favorite resorts!',
        'HomePage.skiResortsHeadline': 'ONE KEYCARD FOR {regions} SKI RESORTS ',
        'HomePage.skiResortsText':
            "Simply because when you see the mountains, you just want to go and experience them. No compromises, stress-free and without waiting in line at the ticket offices. We also share these ideas and it's why we started starjack. With starjack you book your ski passes online in real-time. As soon as you press 'order' you can go directly to the lifts. You just need your own starjack Keycard.",
        'HomePage.advantagesHeadline': 'Enjoy a wide range of benefits',
        'HomePage.advantagesText1':
            '<ul>' +
            '<li>1 Keycard - 1 Account – Online ski pass booking for {regions} ski resorts. Wherever and whenever you want.</li>' +
            '<li>Simple real-time booking: log in to your starjack account, choose your destination, place your ticket into the shopping cart, pay and go straight to the slopes!​</li>' +
            '<li>As a starjack user, opening times or queues at the ticket offices will no longer exist.</li>' +
            '</ul>',
        'HomePage.advantagesText2':
            '<ul>' +
            "<li>One account, many users - in your starjack account you can manage the Keycards and bookings for your family, friends, skibuddy's … whomever you want!</li>" +
            '<li>Your starjack Keycard/s will be delivered to you by post and can be recharged online as often as you like for many years.</li>' +
            '<li>Are you more like the early bird type? Great! Book your ski passes with starjack comfortably at home on the couch. Start your skiing holiday completely relaxed.</li>' +
            '</ul>',
        'HomePage.voucherHeadline':
            'Give away a memorable guest experience from the valleys to the mountaintops – with a starjack gift coupon.',
        'HomePage.voucherText':
            'It’s the perfect gift of love for all mountain sports enthusiasts and fans of the Alps, as the coupon can be redeemed for any mountain experience offered by starjack.' +
            '<br /> <br />This means free choice of the destination and ticket, complete with stress-free access to a memorable mountain experience. All the coupon recipient needs is a starjack account.',
        'HomePage.voucherLink': 'Order voucher now',

        'HomePageSummer.headline':
            'Skiing - Biking - Hiking <br /> book your lift tickets online',
        'HomePageSummer.subline':
            'Whatever attracts you to the mountain - you can book your ticket online in seconds with starjack. ' +
            '<br /> Directly on your keycard or, if activated by the mountain railway, also as a PickUp Voucher / e-ticket.',
        'HomePageSummer.textHeadline':
            'Create an account • Order or register a Keycard •<br />Book your ticket online',
        'HomePageSummer.text':
            '...and you can go straight to the lift without waiting in line.\n' +
            'A few seconds after booking your ticket, you will receive a confirmation by email and your (ski)ticket is ready for use.',
        'HomePageSummer.subText':
            'Don’t have a Keycard handy? Just order your personal starjack Keycard and get it delivered to your home:',
        'HomePageSummer.getKeycard': 'Order keycard',
        'HomePageSummer.skiResortsHeadline':
            'EINE KEYCARD FÜR {regions} SKIREGIONEN​',
        'HomePageSummer.skiResortsText':
            'Weil du die Berge liebst und erleben willst. Kompromisslos stressfrei, ohne an den Ticketkassen auf das „Go“ zu warten. Und weil das auch unser Anspruch ist, gibt es starjack. Du buchst flexibel deine Skipässe online und gehst direkt zum Lift, kurz nach dem du den „Bestellen-Button“ gedrückt hast. Alles, was du dazu brauchst, ist deine persönliche starjack Keycard.',
        'HomePageSummer.advantagesHeadline': 'Enjoy a wide range of benefits',
        'HomePageSummer.advantagesText1':
            '<ul>' +
            '<li>1 Keycard - 1 Account – Online ski pass booking for {regions} ski resorts. Wherever and whenever you want.</li>' +
            '<li>Simple real-time booking: log in to your starjack account, choose your destination, place your ticket into the shopping cart, pay and go straight to the slopes!​</li>' +
            '<li>As a starjack user, opening times or queues at the ticket offices will no longer exist.</li>' +
            '</ul>',
        'HomePageSummer.advantagesText2':
            '<ul>' +
            "<li>One account, many users - in your starjack account you can manage the Keycards and bookings for your family, friends, skibuddy's … whomever you want!</li>" +
            '<li>Your starjack Keycard/s will be delivered to you by post and can be recharged online as often as you like for many years.</li>' +
            '<li>Are you more like the early bird type? Great! Book your ski passes with starjack comfortably at home on the couch. Start your skiing holiday completely relaxed.</li>' +
            '</ul>',
        'HomePageSummer.voucherHeadline':
            'Give someone the gift of pure joy and an unforgettable mountain experience - with a starjack voucher.',
        'HomePageSummer.voucherText':
            "It's a sure sign of love for every mountaineer and alpine lover because the voucher can be redeemed online for each and every mountain adventure offered on starjack.com. " +
            'This means your own choice of destination and ticket not to mention a hassle-free path to whichever mountain experience is calling. The recipient needs only a starjack keycard.',
        'HomePageSummer.voucherLink': 'Create voucher',
        'HomePageSummer.voucherSubText':
            'All the coupon recipient needs is a starjack account.',
        'HomePageSummer.sjAppTitle': 'Your starjack-App',
        'HomePageSummer.sjAppText':
            'The starjack app is your year-round, direct access to more than 100 mountain railways & bikeparks in Austria and Germany. With just a few clicks and in real time, you can book your (ski) tickets on the go, so that you can go straight to the lift just a few seconds after completing your order.' +
            '<br/><br/>' +
            'Book your ticket when and where you want with the starjack app.' +
            '<br/><br/>' +
            'Get the free starjack app now and leave the queues at the ticket booths behind you.',

        'HomePageSummer.oneCardForAll':
            '1 Keycard for hundreds of mountain railways and lifts',
        'HomePageSummer.keycardExplanation':
            'Create a free starjack account, order / register your keycard(s) with it and you can book the offers of all mountain railways directly onto the keycard - contactless and in a matter of seconds.',

        'HomePageSummer.ticketsUsable':
            'Your ticket is ready for immediate use',
        'HomePageSummer.ticketsUsableExplanation':
            'Your ticket booked with starjack is activated a few seconds after ordering (confirmation by email) and you can go straight to the lift without waiting. ',

        'HomePageSummer.accountForFamilyAndFriends':
            'Just 1 Account for all of your friends and family',
        'HomePageSummer.accountForFamilyAndFriendsExplanation':
            'You can store as many keycards as you like in your starjack account by registering or ordering. With one Account, you can book the tickets for all of your frineds and family.',
        'HomePageSummer.orderOrRegisterKeycard':
            'Register your old ski pass for online booking ',
        'HomePageSummer.orderOrRegisterKeycardExplanation':
            'Do you still have an old keycard at home? Register this in your webshop account and you can immediately book tickets on it - as often as you want. Alternatively, you can order a starjack keycard.',

        'HomePageSummer.keycardsReusable':
            'Online ticket booking possible without a keycard',
        'HomePageSummer.keycardsReusableExplanation':
            'Depending on the possibility, our mountain railways also offer booking options without a keycard. As a pick-up voucher for collection on site or as an electronic access ticket (e-ticket) on your mobile phone - simply add the ticket to your shopping basket and view the booking options.',

        'HomePageSummer.ticketReturn': 'Ticket return',
        'HomePageSummer.ticketReturnExplanation':
            '\n' +
            'Tickets that are marked "non-cancellable" in the online sales cannot be cancelled. All other unused tickets & vouchers can return by 8 a.m. on the first day of validity and up to 1 hour after the order has been placed. You`ll receive the value as credit in your webshop account. The credit will automatically be used as payment for your next orders until it is used up.',

        'HomePageSummer.sjApp': 'Your starjack-App',
        'HomePageSummer.sjAppExplanation':
            'With the free starjack app, mobile ticket bookings are even faster and more convenient.',

        'HomePageSummer.enjoyTime': 'It`s your precious time',
        'HomePageSummer.enjoyTimeExplanation':
            "Because that's what it's all about: your enjoyable time! We make booking tickets easy and convenient for you. Whenever and wherever you want. Opening times or ticket sales are no longer available to you - you are a starjacker!",

        'MyTicketsPage.headline': 'My starjack - Tickets',
        'MyTicketsPage.loading': 'Tickets are loading...',
        'MyTicketsPage.keycards': 'Keycards:',
        'MyTicketsPage.cancel': 'Cancel ticket:',
        'MyTicketsPage.success':
            'Ticket purchase has been reversed, the credit will be transferred back onto your starjack user account. You will receive a confirmation by e-mail.',
        'MyTicketsPage.ticket_not_found': 'Ticket does not exist',
        'MyTicketsPage.ticket_already_billed': 'Ticket not cancelable',
        'MyTicketsPage.ticket_already_canceled': 'Ticket already canceled',
        'MyTicketsPage.ticket_not_cancelable': 'Ticket not cancelable',
        'MyTicketsPage.could_not_cancel_ticket': 'Ticket not cancelable',

        'MyTicketsPage.insuranceAdded.chip.label': 'Protection added',
        'MyTicketsPage.insuranceNotAdded.chip.label': 'Protection not added',

        'MyTicketsPage.cancelDialog.ticketWithoutInsurance': 'Cancel ticket',
        'MyTicketsPage.cancelDialog.ticketWithInsurance':
            'Cancel ticket incl. insurance',
        'MyTicketsPage.cancelDialog.body.ticketWithInsurance':
            'Do you really want to cancel your ticket incl. insurance?',
        'MyTicketsPage.cancelDialog.body.ticketWithoutInsurance':
            'Do you really want to cancel your ticket?',

        'MyTicketsPage.cancelDialog.caption.ticketWithInsurance': `
            By clicking on “Cancel” you confirm that the amount will be credited to your webshop account. 
            If you would like a payout to your chosen payment method, please send an e-mail to <a href='mailto:support@starjack.com'>support@starjack.com</a> with your ticket number and insurance policy number or 
            call <a href='tel:+43624673430999'>+43624673430999</a>. 
            We will then transfer the amount back to your chosen means of payment. We will charge a fee for a chargeback of the ticket amount.
            You can find more information in our <a href='/faq'>FAQs</a>.
        `,

        'MyTicketsPage.cancelDialog.success': 'Ticket canceled successfully',

        'MyInsurances.currentInsurances': 'Current insurances',
        'MyInsurances.expiredInsurances': 'Expired insurances',

        'ticketStatus.REFUNDED': 'Ticket canceled and amount refunded',
        'ticketStatus.PAID': 'Ticket paid',
        'ticketStatus.PRODUCED': 'Ticket booked on keycard',
        'ticketStatus.NEW': 'Ticket new',
        'ticketStatus.ORDERED': 'Ticket ordered',

        'MyFavoritesPage.headline': 'Favorites',
        'MyFavoritesPage.noFavoritesFound': 'No favorites found',

        'Favorites.addFavorite': 'Add favorite',
        'Favorites.removeFavorite': 'Remove favorite',

        'CouponPage.headline': 'Redeem a coupon',
        'CouponPage.redeem': 'Redeem',
        'CouponPage.code': 'Coupon code',
        'CouponPage.coupon_code_not_found': 'Code not found',
        'CouponPage.coupon_already_used': 'Code already used',
        'CouponPage.coupon_code_expired': 'Code expired',
        'CouponPage.coupon_code_not_yet_valid': 'Code not yet valid',
        'CouponPage.coupon_code_over_contingent': 'Code not available',
        'CouponPage.success': 'Coupon code successfully redeemed',

        'VoucherRedeemPage.headline': 'Buy a voucher',
        'VoucherRedeemPage.redeem': 'Redeem',
        'VoucherRedeemPage.code': 'Voucher code',
        'VoucherRedeemPage.success': 'Voucher credit successfully booked',
        'VoucherRedeemPage.voucher_not_found': 'Voucher invalid',
        'VoucherRedeemPage.voucher_not_paid': 'Voucher invalid',
        'VoucherRedeemPage.voucher_already_redeemed':
            'Voucher already redeemed',

        'CardTransferPage.headline': 'Transfer Keycard',
        'CardTransferPage.targetEmail': 'Recipient email',
        'CardTransferPage.transfer': 'Transfer keycard',

        'CardTransfer.accept': 'Accept',
        'CardTransfer.reject': 'Reject',
        'CardTransfer.email': 'Email',
        'CardTransfer.firstname': 'Firstname',
        'CardTransfer.lastname': 'Lastname',
        'CardTransfer.birthday': 'Birthday',
        'CardTransfer.rejected': 'Transfer rejected',

        'KeycardTransferAlert.newTransfer':
            'Keycard transfer ready for confirmation. Click here.',

        'CreditPage.headline': 'Credit',
        'CreditPage.creditInfo':
            'Your current credit is {balance}. Credits are considered automatically during the next orders.',

        'VoucherPage.headline': 'Voucher',
        'VoucherPage.description':
            'Choose a voucher template and complete it with your personal words. You will immediately receive your voucher by email to print out.',
        'VoucherPage.descriptionListTitlePortal':
            'This is how you can redeem the voucher on starjack.com:',

        'VoucherPage.descriptionListTitleWebshop':
            'This is how you can redeem the voucher in our webshop:',

        'VoucherPage.descriptionListPortal':
            '1. Log in with your starjack account or register a new one <br>' +
            '2. Go to "view / redeem voucher" in your account  <br>' +
            '3. Enter your voucher code in the field provided - you now have the voucher value available online and it will automatically be used for your next payment (s) until it is used up. <br>' +
            'You can use it to book every online ticket offered on starjack.com.',

        'VoucherPage.descriptionListWebshop':
            '1. Log in with your starjack account or register a new one <br>' +
            '2. Go to "view / redeem voucher" in your account  <br>' +
            '3. Enter your voucher code in the field provided - you now have the voucher value available online and it will automatically be used for your next payment (s) until it is used up. <br>' +
            'You can use it to book every online ticket offered in our webshop.',

        'VoucherPage.amount': 'Amount',
        'VoucherPage.sender': 'Sender',
        'VoucherPage.receiver': 'Receiver',
        'VoucherPage.title': 'Title',
        'VoucherPage.text': 'Text',
        'VoucherPage.preview': 'Preview',
        'VoucherPage.previewGenerating': 'Preview is creating....',
        'VoucherPage.back': 'Back',
        'VoucherPage.cart': 'add to cart',
        'VoucherPage.notnullvoucherTemplateId': 'Choose a voucher template',
        'VoucherPage.notemptytitle': 'Title is required',
        'VoucherPage.notemptytext': 'Text is required',
        'VoucherPage.notemptysender': 'Sender is required',
        'VoucherPage.notemptyreceiver': 'Receiver is required',
        'VoucherPage.maxtitle': 'Title is too long',
        'VoucherPage.maxtext': 'Text is too long',
        'VoucherPage.lengthsender': 'Sender is too long',
        'VoucherPage.lengthreceiver': 'Receiver is too long',
        'VoucherPage.minamount': 'Minimum amount is € 5,00',
        'VoucherPage.invalid_requestundefined':
            'Please fill all fields correctly',

        'VoucherPage.fillVoucher': 'Fill out voucher',
        'VoucherPage.viewDetail': 'View voucher data',
        'VoucherPage.editVoucher': 'Edit voucher',
        'VoucherPage.previewTile': 'Voucher preview',
        'VoucherPage.selectTemplateTitle': 'Select a voucher template',
        'VoucherPage.errorAddingVoucher':
            'Error saving the voucher. Please try again later',

        'VoucherDownloadPage.headline': 'Voucher Download',
        'VoucherDownloadPage.download': 'Create and load voucher',
        'VoucherDownloadPage.downloadInProgress':
            'Voucher is creating and downloading...',

        'LinkExternalCardPage.description':
            'Each registered Keycard may be used for online ticket booking by ' +
            'only one person, whose personal details you can register here.<br/><br/>' +
            'Enter the details of the person who will be using the Keycard for online bookings.<br/><br/>' +
            '<b>Incorrect input may lead to the ticket being invalidated</b>',

        'LiftoperatorSelector.placeholder': 'Enter a location or destination',
        'LiftoperatorSelector.sectionLiftoperators':
            'Back to all Liftoperators',
        'LiftoperatorSelector.sectionLocations': 'Locations',
        'LiftoperatorSelector.showAll': 'Show all',

        'ErrorPage.pageNotFound': 'Page not found',
        'ErrorPage.loginRequired': 'Login required',
        'ErrorPage.error': 'Error',
        'ErrorPage.pageNotFoundDescription':
            'This page is not available. Please try again.',
        'ErrorPage.loginRequiredDescription': 'Requested page requires login.',
        'ErrorPage.errorDescription': 'Unknown error. Please try again.',
        'ErrorPage.back': 'Back',
        'ErrorPage.home': 'Home',
        'ErrorPage.login': 'Login',

        'IncertVoucherRedemption.incertVoucherCode':
            'Enter your incert voucher code here',
        'IncertVoucherRedemption.inputLabel': 'Barcode-Number',
        'IncertVoucherForm.tooltip':
            'The voucher value will be deducted from the order amount after pressing the "Pay" button. If the voucher value is higher than your order total, the difference remains on your voucher.',
        'IncertVoucherForm.success': 'Incert voucher successfully redeemed',

        bootstrapTableOptions: {
            noDataText: 'No data available',
            insertText: 'New',
            sizePerPage: 25,
        },

        'Cookie.title': 'Surprise surprise – Cookies :-)',
        'Cookie.dataPrivacyMessage':
            "Our website uses cookies, not only ours, also those of third parties. These cute little text files are necessary in order to offer you the most convenient and otherwise great booking experience possible. Select cookies according to your own preferences and click ACCEPT in good spirits (guaranteed calorie-free) - and you're ready to go!",
        'Cookie.dataPrivacyLabel': 'Learne more about our cookies',
        'Cookie.buttonLabel': 'OK',
        'Cookie.buttonLabelSelection': 'Accept selection',
        'Cookie.buttonLabelAll': 'Accept cookies',
        'Cookie.necessaryCookies': 'Necessary cookies',
        'Cookie.statisticCookies': 'Statistic cookies',
        'Cookie.necessaryExplanation':
            'Necessary cookies help to make a website usable by enabling basic functions such as page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
        'Cookie.statisticExplanation':
            'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',

        'VerifyForm.verify': 'Submit promotion code',
        'Verify.header': 'Add Promotion Codes',
        'VerifyForm.code': 'Code',
        'Verify.message':
            'Enter here your starjack promotion code, then go to the offers of the Lift Operator, who provided you the code. A promotion code entitles you to a single booking of the Promotion offer on the below keycard.',
        'validation.error.code.required': 'Code is required',
        'validation.error.verificationFailed':
            'The entered lift operator code is invalid',
        'validation.error.linkExternalKeycard.token_expired':
            'Token is expired',
        'validation.error.checkout.shipping_costs_do_not_match':
            'Shipping costs do not match',
        'validation.error.checkout.notnull':
            'Please check your details in the shopping cart and correct the red outlined fields',
        'validation.error.checkout.invalid_request':
            'Please check your details in the shopping cart and correct the red outlined fields',

        'ExternalKeycardForm.success':
            'External keycard registration was successful',
        'NewKeycardForm.success': 'Keycard registration was successful',
        'LinkExternalKeycard.toCheckout': 'To Checkout',
        'LinkExternalKeycard.toTickets': 'To Ticket Offerings',

        tomorrow: 'tomorrow',
        today: 'today',

        'Header.login': 'Login',
        'Header.register': 'Register',
        'Header.myKeycards': 'My Keycards',
        'Header.myData': 'My Profile',
        'Header.myTickets': 'My Tickets',
        'Header.myOrders': 'My Orders',
        'Header.changePassword': 'Change Password',
        'Header.manageCreditCards': 'Credit cards',
        'Header.manageCredit': 'Credit balance',
        'Header.couponManagement': 'Redeem Voucher',
        'Header.redeemCoupon': 'Redeem Coupon',
        'Header.faq': 'FAQs',
        'Header.logout': 'Logout',

        'Error.required': 'Required',

        'ProductInfoModal.pictureRequired':
            'A digital photo is required to book this ticket; on your keycard or as an upload in the order form.',

        'Core.password': 'password',
        'Core.delete': 'delete',
        'Core.table.rowsPerPage': 'Rows per page',
        'Core.dayTickets': 'day & multiday tickets',
        'Core.seasonTickets': 'season tickets',
        'Core.seasonTicket': 'season & annual tickets',
        'Core.combinationTicket': 'packages & family tickets',
        'Core.combinationTickets': 'combination tickets',
        'Core.chooseDate': 'CHOOSE DATE',
        'Core.chose': 'Choose',
        'Core.otherDuration': 'Other Duration',
        'Core.day': ' Day',
        'Core.days': ' Days',
        'Core.validAt': 'valid on: ',
        'Core.validFrom': 'valid from: ',
        'Core.validTo': 'valid to',
        'Core.more': 'More',
        'Core.bookableWithoutKeycard': 'Booking possible without keycard',
        'Core.photoRequired': 'Photo required',
        'Core.contingent': '{{tickets}} tickets available',
        'Core.addToCart': 'Add to cart',
        'Core.ticketAddedToCart': 'Ticket has been added to the shopping cart',
        'Core.bookMoreTickets': 'Add another ticket',
        'Core.toCart': 'To cart',
        'Core.back': 'back',
        'Core.deliveryAddress': 'Delivery Address:',
        'Core.redeemCoupon': ' Redeem coupon',
        'Core.total': 'Total: ',
        'Core.accountWithBalance': 'Your account has a balance of {{val}}.',
        'Core.balanceWillBeUsed': 'and will be paid toward this purchase.',
        'Core.redeemPromotionCode': 'Redeem Promotion Code',
        'Core.next': 'Next',
        'Core.changeDeliveryAddress': 'Change delivery address',
        'Core.goToPayment': 'To payment',
        'Core.cancel': 'Cancel',
        'Core.birthdayFrom': 'Birthday from: ',
        'Core.newKeycard': 'Keycard',
        'Core.to': 'to:',
        'Core.or': 'or',
        'Core.from': 'from',
        'Core.change': 'Change',
        'Core.orderKeycard': 'Order keycard',
        'Core.registerExternalKeycard': 'Register external keycard',
        'Core.noTicketsFound':
            'The selected company does not currently offer tickets for online purchase',
        'Core.chooseAnother': 'Choose another ski resort',
        'Core.cancellationBlocked': 'non-cancellable',
        'TripLengthModal.headline': 'Choose another duration',
        'ExternalKeycard.headline': 'Register a Keycard ',
        'ExternalKeycard.button':
            'Confirm the correctness of the data – register keycard',
        'ExternalKeycard.explanation':
            'Register and personalize any keycard (ID number starts with 01-....) and use it immediately to book your online tickets!<br/>FormEach manually registered keycard may only be used for the online ticket booking by the person, whose personal data is stored here digitally. When booking a ticket via starjack, these digital data are transmitted to the cablecar-company and are binding / valid.<br/>Incorrect information can lead to invalid tickets and other legal consequences (see terms and conditions of the mountain railway with which you book) ',
        'ExternalKeycard.processing': 'Keycard is being added...',
        'ExternalKeycard.serialNumberExplanation':
            'Enter the 23-digit serial number from the back of the keycard here, without a hyphen or space. Only keycards whose ID begins with 01 can be registered',
        'ExternalKeycard.register': 'Register',
        'RenderImageField.instructions':
            'We recommend that you upload a photo of the keycard user. Tickets that require a photo can only be booked with a digitally stored image.',
        'NewKeycard.headline': 'Order Keycard ',
        'NewKeycard.explanation': `<strong>
                Order home once, enjoy many years: Get your starjack keycard and book your tickets online in a matter of seconds -
                directly to your keycard via your account. The starjack keycard is personalised, therefore it can‘t be changed or cancelled after the order has been placed.
               
                <br /><br/>
                Please note: The keycard will be produced immediately after ordering, please plan a few days for the mail dispatch to you!  

            </strong>
         `,

        'Keycard.pickup':
            'You will receive your pick-up-voucher as a PDF by email soon after you`ve placed your order. This allows you to collect your keycard with the booked ticket directly from the cable car at the cash desk or, if available, from the pick-up-machine.',
        'Keycard.barcode':
            'Your eTicket will be sent to your e-mail address as a PDF and can be used for direct access for the day (s) booked. Enter the data of the person who will use the eTicket here. The eTicket is not transferable!',
        'Keycard.eTicket':
            'Your eTicket will be sent to your e-mail address as a PDF and can be used for direct access for the day (s) booked. Enter the data of the person who will use the eTicket here. The eTicket is not transferable!',
        'NewKeycard.order': 'Order Keycard',
        'Core.bookKeycard': 'Ticket auf eine Keycard buchen.',
        'Core.bookeTicket':
            'You will receive your access ticket (eTicket) by e-mail. Print out recommended. A keycard is not required for the booking.',
        'Core.bookVoucher':
            'Booking as a pick-up ticket (no keycard required), delivery by e-mail. You will receive an entrance ticket upon presentation of the voucher to the booked company at the ticket desk or at the pick-up machine (if available). ',
        birthday_mismatch:
            'The person is not allowed to use this ticket, because of the age.',
        FEMALE: 'female',
        MALE: 'male',
        bad_credentials: 'Wrong Login',
        bad_order_status: '',
        'checkoutError.deliveryAddressInvalid':
            'The delivery address is not valid. Please ensure the form is complete.',

        'CreditModal.title': 'Enter your coupon code here',
        'CreditModal.placeholder': 'Coupon code',
        'CreditModal.submit': 'Redeem coupon',

        deliveryCost: 'Delivery',
        'Core.buyVoucher': 'Purchase a Voucher',
        'Core.nextHelperText':
            'Please select the production type per ticket to proceed with the order.',
        'Core.invalidBirthday':
            'Ticket can only be booked when the cardholder is born between {expectedFrom} and {expectedTo}.',
        'Core.selectDifferentKeycard': 'Change keycard',
        'NotFound.headline': '404 Not Found',
        'NotFound.message': 'Oops! No page exists for this URL',
        'Core.invalidKeycardAssignment':
            'The selected keycard is not valid for the ticket',
        'Request.error.isocountry': 'Please select your country.',
        'Request.error.login': 'Wrong user credentials. Please try again.',
        'Request.error.internal':
            'Internal error. Please refresh the page and try again.',
        'Request.error.contingent':
            'Contingent exhausted. Currently this ticket can no longer be booked for the selected day',
        'Request.error.internal_incert_error':
            'The voucher cannot be redeemed here in the shopping cart. If you have a starjack voucher, redeem it in the webshop account, "Redeem credit" menu.',
        'Core.ticket.cancel': 'Do you really want to cancel the ticket',
        'Core.ticket.cancel.error':
            'There was an error when deleting your ticket. Please try it later again',
        'Core.ticket.cancel.title': 'Cancel ticket',
        'Core.backToMain': 'Back to main',

        'Keycard.order.existing': 'Order on existing keycard',
        'Keycard.order.new': 'Order new keycard',
        'Keycard.order.register': 'Register new keycard',

        'Registration.navigation.toLogin': 'login',
        'Registration.navigation.back': 'back',
        'Registration.error': 'An error occurred. Please try it again later',
        'Registration.error.customerAlreadyExists':
            'An account with this e-mail already exists',
        'Registration.EMAIL_PASSWORD': 'Mit E-Mail anmelden',

        'Promotion.codeAdd': 'Promtion code added successfully',

        'ProductsPage.productOverview': 'Products overview',
        'ProductsPage.payment': 'Payment method',
        'ProductsPage.allSkiers.select': 'Select person type',

        'liftoperators.search': 'Enter lift operator or location',
        'ProductsPage.combi.person.select': 'Select person',

        'Keycard.order.no.available':
            "You don't have a valid keycard. Please select another option",

        'Keycard.added': 'The new keycard was successfully added to the cart',

        'Home.show.allOffers': 'Show all offers',

        'MyAccountNavigation.login': 'Login',

        'ProductsPage.toWebsite': 'Website',

        'Cart.yourCartIsEmpty': 'Your cart is empty. Choose a product.',

        'ProductsPage.noTicketsFoundForLiftOperator':
            'Currently the selected company doesn`t offer tickets for online purchase',

        'Core.totalCost': 'Costs total',

        'Cart.tickets': 'Tickets',

        'Cart.discounts': 'Discounts',

        'MyAccountNavigation.faq': 'Faq',

        'Core.yourDeliveryAddress': 'Your Invoice- / Delivery address',

        'Cart.vouchers': 'Vouchers',

        'Core.payWith': 'Payment',

        'Core.nextPerson': 'next person',

        'Core.previousPerson': 'previous person',

        'MyCardsPage.chose': 'Choose',

        'Keycard.option.send': 'Keycard will be sent to you',

        'Keycard.option.useExisting': 'Use existing keycard',

        'Keycard.option.pickup': 'Pick up at cash desk or pick up machine',

        'Cart.keycards': 'Keycards',

        'Home.oneCardForAll':
            'One Keycard suitable for hundreds of mountain transport and lift services',
        'Home.keycardExplanation':
            'You can book all the tickets from your favorite providers onto your (starjack) Keycard – contactless and within seconds: any kind, from Hour Ticket to Season Pass.',

        'Home.ticketsUsable': 'Your ticket is immediately ready to use!',
        'Home.ticketsUsableExplanation':
            'Your starjack-bought ticket is activated within seconds after completing your booking (confirmed by e-mail), allowing you to proceed straight to the lift – no need to wait in line at the ticket booth.',

        'Home.ticketReturn': 'Ticket return policy',
        'Home.ticketReturnExplanation':
            'You may return your ticket under certain conditions, as long as it is not marked as non-refundable in the web shop. You can choose between two return options under “My Tickets” in your web shop account.',

        'Home.flexible': 'Flexible booking options, even without a Keycard',
        'Home.flexibleExplanation':
            'Where possible, our mountain transport providers also offer booking options without a Keycard: with a pick-up voucher for collecting your ticket at the resort, or as an e-ticket sent straight to your smartphone. Just place your ticket into the shopping basket and select your preferred booking option from the ones offered by the service provider.',

        'Home.insurance': 'Insurance Safety+ / Refund+',
        'Home.insuranceExplanation':
            'Do you want accident protection on the slopes? If something happens, you will receive, among other things, a refund for the ski pass and ski course.',

        'Home.oldKeycard':
            'Registering an existing ski pass for online booking',
        'Home.oldKeycardExplanation':
            'Have a used Keycard at home? Just register it in your web shop account and start loading it with ski tickets straight away – as often as you like. Alternatively, you can also order a new starjack branded Keycard.',

        'Home.orderOrRegisterKeycard':
            'Registering an existing ski pass for online booking',
        'Home.orderOrRegisterKeycardExplanation':
            'Have a used Keycard at home? Just register it in your web shop account and start loading it with ski tickets straight away – as often as you like. Alternatively, you can also order a new starjack branded Keycard.',

        'Home.keycardsReusable': 'Reusable Keycard',
        'Home.keycardsReusableExplanation':
            'You can reuse your Keycard any number of times by loading it with tickets via your starjack account. Your starjack Keycard will remain reusable for several years.',

        'Home.accountForFamilyAndFriends':
            'One web shop account for all your friends & family',
        'Home.accountForFamilyAndFriendsExplanation':
            'Your starjack account lets you register and order any number of Keycards and load them with the tickets of your choice. It’s an ideal solution, e.g. for families.',

        'Home.sjApp': 'The starjack app',
        'Home.sjAppExplanation':
            'With the free starjack app, booking mobile tickets is even faster and even more convenient. The app is available for iOS as well as Android phones.',

        'Home.enjoyTime': 'Have a great time!',
        'Home.enjoyTimeExplanation':
            'Because that’s what it’s all about: making sure you enjoy your time in the mountains! To get you on the way, we make booking tickets super easy and convenient. Whenever and wherever you like. Forget restrictions like opening hours or waiting in line at the ticket booth – after all, you’re a starjack user now!',

        'HomePage.sjAppTitle': 'Your starjack app',
        'HomePage.sjAppText':
            'With your Keycard in your ski jacket and the starjack app on your smartphone, you’re all set to go where snowsport enthusiasts like yourself belong: on the slopes!' +
            '<br/><br/>' +
            'The starjack app makes booking your (ski) tickets even more convenient for you. That’s because all your registration details are stored in your web shop account, ready and waiting for your next booking – all at a simple click or touch. This way, you can book your tickets and have them ready for use within just seconds.' +
            '<br/><br/>' +
            'Get the free starjack app now and skip those waiting lines at the ticket booth!',

        'HomePage.voucherSubText':
            'All the coupon recipient needs is a starjack account.',

        'HomePageWinter.headline':
            'It’s as easy as 1-2-3:<br /> create an account – register your Keycard – book the (ski) ticket of your choice online',

        'HomePageWinter.text':
            '...and proceed straight to the lift, skipping the line at the ticket booth. A few seconds after completing your booking, you’ll receive a confirmation e-mail to let you know your (ski) tickets are ready for use.',

        'HomePageWinter.subText':
            'Don’t have a Keycard handy? Just order your personal starjack Keycard and have it delivered to your home address:',

        'HomePage.getKeycard': 'Order your keycard now',

        'Keycard.order.explanation':
            'Every guest also needs their own keycard when booking tickets online. Order your starjack keycard at home and use it for many years to book your tickets online now. In a matter of seconds, your online booked tickets will be ready for use on your keycard.',

        'Core.invalidLink': 'Invalid Link',
        'Core.backToHome': 'Back to Home',

        'LocationSearch.location': 'Location',
        'LocationSearch.liftoperator': 'Lift operator',

        'GTC.title':
            'Online Booking Platform “starjack” – General Terms and Conditions of Business',

        'KeycardOption.pickup': 'Order as a pick-up-Voucher',
        'KeycardOption.barcode': 'Order as an eTicket',

        'RequestError.externalKeycardNotAllowed':
            'Unfortunately, tickets from the selected company cannot be booked on a manually registered keycard.',

        'KeycardTransfer.noTransfers':
            'Currently, there are no keycard tansfers waiting',
        'KeycardTransfer.linkToTransfers': 'view the transfer',
        'KecardTransfer.waitingTransfer':
            '{{email}} wants to send you a keycard',

        'Link.backToHome': 'back to home',
        'Ticket.identificationNames': 'Booked for',

        'ticketStatus.INTERNAL_PRODUCTION.REFUNDED': 'Ticket canceled',
        'ticketStatus.INTERNAL_PRODUCTION.PAID': 'Ticket paid',
        'ticketStatus.INTERNAL_PRODUCTION.PRODUCED':
            'Ticket booked on the keycard',
        'ticketStatus.INTERNAL_PRODUCTION.NEW': 'Ticket new',
        'ticketStatus.INTERNAL_PRODUCTION.ORDERED':
            'Ticket ordered - in processing',
        'ticketStatus.INTERNAL_PRODUCTION.CANCELED': 'Ticket canceled',
        'ticketStatus.KEYCARD.REFUNDED':
            'Ticket canceled, credit booked in your webshop account',
        'ticketStatus.KEYCARD.PAID': 'Ticket paid',
        'ticketStatus.KEYCARD.PRODUCED': 'Ticket booked on the keycard',
        'ticketStatus.KEYCARD.NEW': 'Ticket new',
        'ticketStatus.KEYCARD.ORDERED': 'Ticket ordered - in processing',
        'ticketStatus.KEYCARD.CANCELED': 'Ticket canceled',
        'ticketStatus.PICKUP.REFUNDED':
            'Ticket canceled, credit booked in your webshop account',
        'ticketStatus.PICKUP.PAID': 'Ticket Voucher paid',
        'ticketStatus.PICKUP.PRODUCED':
            'Ticket Voucher created and sent by email',
        'ticketStatus.PICKUP.NEW': 'Ticket Voucher new',
        'ticketStatus.PICKUP.ORDERED': 'Ticket Voucher ordered - in processing',
        'ticketStatus.PICKUP.CANCELED': 'Ticket canceled',
        'ticketStatus.BARCODE.REFUNDED':
            'Ticket canceled, credit booked in your webshop account',
        'ticketStatus.BARCODE.PAID': 'eTicket paid',
        'ticketStatus.BARCODE.PRODUCED': 'eTicket created and sent by email',
        'ticketStatus.BARCODE.NEW': 'eTicket new',
        'ticketStatus.BARCODE.ORDERED': 'eTicket ordered - in processing',
        'ticketStatus.BARCODE.CANCELED': 'Ticket canceled',
        'ticketStatus.E_TICKET.PRODUCED':
            'Smartphone ticket successfully booked',

        'keycard.registerNew.error.general':
            'Error registering your keycard. Please try again later',
        'keycard.registerNew.error.invalidSerialNumber':
            'This serial number is invalid',

        'MyTickets.myFavorites': 'My favorites',
        'MyTickets.ticketsExpired': 'Expired Tickets',
        'MyTickets.ticketsCurrent': 'Current Tickets',

        'MyTickets.toTicketsOffer': 'To the ticket offer',

        'Core.birthday': 'Date of birth',
        'Core.birthday.dayFormat': 'DD',
        'Core.birthday.monthFormat': 'MM',
        'Core.birthday.yearFormat': 'YYYY',
        'Core.birthday.invalidBirthday': 'Invalid birthday',

        'Keycard.register.success': 'Your keycard was successfully registered',

        'country.AT': 'Austria',
        'country.DE': 'Germany',
        'country.IT': 'Italy',

        'ActivationPage.activate':
            'To activate your account, please enter your password.',
        'PasswordForgottenForm.error': 'Password not changed',
        'PasswordForgottenForm.success': 'Pasword reset successful',
        'validation.error.password.addNew': 'Please enter a new password.',
        'validation.error.password.addNewAgain':
            'Please enter your new password again.',
        'validation.error.serialNumber':
            'Please enter your 23-digit serial number.',
        invalid_activation_code:
            'The activation link is invalid. Please repeat registration.',
        'MyCardsPage.deleteIt': 'delete',
        'Profile.accountUpdated': 'Your account has been updated',
        'Newsletter.title': 'sign up for newsletter',
        'CheckoutKeyCardForm.dragDropText':
            'Drag new file in here or click here ',
        'VoucherPage.senderError': 'Please enter a valid email address',
        'VoucherPage.receiverError': 'Please enter a valid email address',
        'Core.dayTicket': 'Daytickets - multi-day tickets',
        'Core.close': ' close',
        'Core.season': 'Season and annual tickets',
        'Core.error.deliveryAddress':
            'Please enter your billing / delivery address',
        'Promotion.enter.aha': 'Please enter your code',
        'Promotion.enter.title': 'enter code',
        'Promotion.enter.ahaText':
            'Young people / adults (born 2004 - 2008) receive a reduction of € 30 with their AHA CARD. Simply enter the card number for verification (if there are hyphens, enter them as well). After a successful check, the selected ticket with the discount is placed in the shopping cart and the booking can be completed.',

        'Promotion.oeamtc.dialog.title': "Enter ÖAMTC member's card number",
        'Promotion.oeamtc.enter.label': "ÖAMTC member's card number",
        'Promotion.oeamtc.enter.title': 'Enter OEAMTC promotion code',
        'Promotion.oeamtc.enter.body': 'ÖAMTC Body Text',
        'Promotion.oeamtc.enter.redeem': 'Redeem ÖAMTC promotion',
        'Promotion.oeamtc.enter.error':
            'Error entering the ÖAMTC promotion code.',
        'Promotion.oeamtc.enter.error.notavailable':
            'There is not ÖAMTC discount for this ticket available',
        'Promotion.oeamtc.enter.error.doubleUsage':
            'The ÖAMTC promotion code has already been used.',
        'Promotion.oeamtc.enter.invalidMemberCode':
            "The ÖAMTC member's card number is invalid",
        'Promotion.oeamtc.enter.success':
            'ÖAMTC promotion code entered successful',
        'Promotion.oeamtc.enter.activated': 'ÖAMTC discount activated',
        'Promotion.oeamtc.enter.activate': 'Activate promotion',

        'Promotion.enter.familyText':
            'Vorarlberg and Tyrolean family pass holders receive a reduction of € 30 per parent when purchasing a 3TälerPass family card. Simply enter the card number for verification (if there are hyphens, enter them as well). After a successful check, the selected ticket with the discount is placed in the shopping cart and the booking can be completed.',
        'Promotion.enter.error':
            'No booking approval for the discount. The card number could not be released for booking the discount. Please check your entry or contact the 3TälerPass office at info@3taeler.at or +43 5512 2365-30.',
        'Promotion.enter.success': ' Discount was taken into account',
        'Promotion.enter.labelAha': 'AHA - Card',
        'Promotion.enter.labelFamily': 'family pass',
        'Promotion.enter': 'enter Code',
        'Promotion.enter.titleMoenichkirchen':
            'ÖSV, Family Pass NÖ and Family Extra Card Wien',
        'Promotion.enter.moenichkirchen': 'Enter your card number',
        'Promotion.enter.labelMoenichkirchen': 'Card number',
        'Promotion.enter.errorMoenichkirchen':
            'The card number could not be released to book the discount. Please check the entry of the number.',
        'Promotion.enter.successMoenichkirchen':
            'Reduction was taken into account.',

        'Covid.enter.title':
            'Enter the serial number of the 3Täler season or annual ticket',
        'Covid.enter.text':
            'All holders of a 3Täler season or annual ticket 2019/20 are entitled to one-time booking for the Covid-19 allowance. Simply enter the 20-digit serial number of the keycard, the 2019/20 season or annual pass (omit the first two and the last number). After a successful check, the selected ticket with the Covid-19 payment is placed in the shopping cart and the booking can be completed.',
        'Covid.enter.label': 'C-Code',
        'Covid.enter.error':
            'No booking release for the Covid-19 allowance The keycard serial number could not be released for the booking of the Covid-19 allowance. Please check the entry again in 15 minutes if the code was used and the purchase was canceled or contact the 3TälerPass office at info@3taeler.at or +43 5512 2365-30.',
        'Covid.enter': 'reedem code',
        'Core.ticket.cancel.button.label': 'cancel',
        'Core.ticket.refund.button.label': 'revoke',
        'Core.ticket.notCancelable': 'Ticket not cancelable',
        'My.keycards.order.new': '+ order a new keycard',
        'My.keycards.order.register': '+ register existing keycard',

        'salutation.-': '--',
        'salutation.male': 'Mr',
        'salutation.female': 'Mrs',

        'core.person': 'person',
        'core.persons': 'persons',
        'core.selected': 'selected',

        'productSelection.noResult':
            'No products found with this filter selection',

        'productCard.info': 'Product information',

        'core.buyKeycard': 'Buy Keycard',

        'Keycard.validityTitle': 'Validity of the ticket',
        'Keycard.validitySendInformation':
            'Your keycard including the ticket will be delivered by post. The validity begins at the earliest upon receipt of the keycard.',

        'dialog.infoConfirmation': 'Accept and continue',
        'Keycard.validityInformationAccept': 'Accept and continue',

        'Oauth.registerLoginTitle': 'Register and login',

        'Registration.registerWithFacebook': 'Register with facebook',
        'Registration.registerWithGoogle': 'Register with Google',
        'Login.loginWithFacebook': 'Login with facebook',
        'Login.loginWithGoogle': 'Login with Google',
        'Oauth.redirect': 'You will be redirected shortly',

        'Oauth.mergeAccountExplanation':
            'A account with this e-mail already exists. The account will be added to the already existing one',
        'Oauth.mergeAccountButtonLabel': 'Add account',

        'core.contact': 'contact',

        'Favorite.like': 'Favorite',
        'Favorite.dislike': 'Remove favorite',

        'core.day': 'day',
        'core.days': 'days',

        'core.selectNone': 'All',

        'IdentificationSelect.addKeycard': '+ order a new keycard',
        'IdentificationSelect.addSuggestion': '+ add new personal data',

        'ProductSelection.chooseDay': 'Select number of days',

        'Core.noContingentLeft':
            'No more tickets available (contingent fully booked)',

        'Core.singleTicket': 'Single ticket',
        'Core.packageTicket': 'Package ticket',

        'Core.selectQuantity': 'Select quantity',

        proceedToCheckout: 'Proceed to checkout',

        personIdentSelect: 'Book the ticket for: ',
        personIdentSelects: 'Book the tickets for: ',
        personSelectedSuccessful: 'All persons successfully selected',

        identificationFormButton: 'Save and proceed',

        INTERNAL_PRODUCTION: 'New Keycard',
        KEYCARD: 'Keycard',
        PICKUP: 'Pickup Voucher',
        BARCODE: 'eTicket',
        E_TICKET: 'Mobile phone',
        KEYCARDAlternativeType:
            'On which keycard do you want to book your ticket?',
        TicketAlternativeType: 'Who do you want to book your ticket for?',
        mobileFlowText:
            '<b>To use the smartphone ticket, you need the key2ski by Skidata app.</b><br/>' +
            'First download this to your smartphone to check whether your smartphone is compatible<br/>' +
            '(only available for compatible devices). Then book your smartphone ticket.<br/>' +
            '<a href="https://play.google.com/store/apps/details?id=com.skidata.key2ski" target="_blank">Android</a><br/>' +
            '<a href="https://apps.apple.com/at/app/key2ski/" target="_blank">iOS</a><br/>' +
            'Please be sure to follow the introduction of the app!',

        productionTypeProDirectUsage:
            'Direct, contactless lift access without queues at the ticket corner',
        productionTypeProRechargeable:
            ' Keycards are rechargeable, you can use it again for your online bookings',
        productionTypeProFastBooking:
            'Your keycard data is stored digitally in your webshop account - for future bookings in a matter of seconds',

        productionTypeConDeliveryTime:
            'For new orders: note delivery time! Austria / Germany = up to 4 working days, Europe = up to 7 working days',
        productionTypeConDeliveryTimeInternal:
            'For new orders: note delivery time! Austria / Germany = up to 4 working days Europe, = up to 7 working days',

        productionTypeProNoKeycard: 'No Keycard required',
        productionTypeConDeposit:
            ' Deposit fees may be charged, you will receive them back on site when you return your ticket',

        productionTypeProSmartphone:
            'Direct, contactless lift access with your smartphone',
        productionTypeConAppRequired:
            'Important: First download the „key2ski by Skidata“ app onto your smartphone (only available for compatible devices) and then book your ticket using the booking option',

        chooseTicketType: 'How would you like to book your ticket?',

        depositCosts: 'Deposit costs',
        keycardCosts: 'Keycard deposit / costs',
        insuranceCosts: 'Insurance (incl. 11 % insurance tax)',

        coreTo: 'to',

        gtcNoticePart1: 'By clicking on "Pay" I agree to the applicable',
        gtcNoticePart2:
            'and confirm the correctness of the data I have entered.',

        gtc: 'terms and conditions',
        dataPrivacyPolicies: 'data protection provisions',

        photoUpdateSuccessful: 'Your photo was successfully updated',

        and: 'and',

        changeKeycardImageExplanation:
            'You can upload or change a digital photo for this keycard here',

        changePhotoData: 'Change photo data',

        chooseBookingBeforePay: 'Please choose booking options for all tickets',

        chooseBookingBeforePayMobile:
            'Please choose booking options for all tickets',

        productFilterNoTickets:
            'The lift operator does not offer any tickets for this selection',

        productFilterSelectPerson: 'Select person',

        productSelectionNoResults: 'There are no results for this selection',

        identificationForm: `The person has to be born between {{from}} and {{to}}.`,

        resetPasswordCustomerHasNoPassword:
            'This account is connected with a social media account and does not have an own password',

        registerKeycardExplanation:
            'Every keycard whose serial number starts with "01-..." can be registered here and used for online booking immediately!<br/><br/> <strong>Important notice:</strong>Each manually registered keycard may only be used for online ticket booking by the person whose personal data is digitally stored here. This digital data is transmitted to the mountain railroad when a ticket is booked and is binding / valid. Incorrect data can lead to ticket invalidity and further legal consequences (see terms and conditions of the mountain railroad with which you book).',

        productFilterDateDisabled: 'No tickets available for this day',

        invalidProfile:
            'Your saved credit card details are invalid or expired and have been deleted. To complete the payment, you can activate "card saving" here and enter your new credit card details in the next step.',

        select: 'Select',
        resetSelection: 'Unselect all',
        profileExpirationDate: 'Expiration date',

        customerService: 'Customer service',
        links: 'Links',
        ticketOffers: 'Ticket offers',
        orderKeycards: 'Order Keycards',
        buyVouchers: 'Buy Vouchers',
        forB2b: 'For B2B',
        followUs: 'Follow us',
        loginDeleted: 'Login deleted',
        loginDeleteError:
            'Error deleting this login. Please try it again later',
        openingHours: 'Mo—Fr from 8.00 a.m. to 4.00 p.m.',
        selectDate: 'Select date',
        accept: 'accept',

        toPayment: 'go to payment',
        placeOrder: 'place and finish your order',
        lookFurther: 'shop more',
        back: 'back',

        backToSuggestions: 'Show suggestions',
        addressMissingError:
            'Address data is missing. Please fill out your address',

        backToProductsPage: 'back to overview mountain lifts',

        yourCart: 'your cart',
        personalizeTickets: 'personalize tickets',
        personalizeTicketsSubtitle:
            'Choose a booking option and add the information of the person that is going to use the ticket.',

        personIdentificationSelectionTitle:
            'Choose the booking option and the person for your ticket',

        pleaseSelectPerson: 'Please select person',
        changeBookingOption: 'Change booking option',
        chosenBookingOption: 'Chosen booking option:',

        personIdentSelectError: 'Please add all missing person data',
        personIdentSelectSuccess: 'Successfully selected',
        personIdentSelectInfo:
            'Please choose which person the ticket is booked for',

        duration: 'duration',
        season: 'season',
        price: 'Price',

        updateCart: 'Update cart',
        add: 'add',

        selectWayOfBookingOption: 'Select booking option',

        productionType_KEYCARD:
            'You can load or register an existing keycard or you can order a new keycard.',
        productionType_INTERNAL_PRODUCTION:
            'The keycard will be sent to you by the mountain railway company by post.',
        productionType_PICKUP:
            'You will receive a pick-up-voucher by email and you use it to collect your ticket at the ticket office or pick-up machines (if available).',
        productionType_BARCODE:
            'You will receive your eTicket incl. QR-Code by email and you can go directly to the lift access.',
        productionType_E_TICKET:
            'Tickets with this booking option can only be used in combination with the „key2ski by SKIDATA“ app!',

        yourOrderSummary: 'Your order summary',
        deliveryAndBillingAddress: 'Your delivery-/billingaddress',
        changeAddress: 'change address',

        selectPaymentOption: 'Select payment option',
        generalFaqs: "General FAQ's",
        howToBookYourTicket:
            'Please choose which person the ticket is booked for',
        additionalTicketInformation: 'Additional ticket information',

        kilometers: 'kms',

        slopeOverview: 'Slope overview',
        toProductsSelection: 'Tickets available',
        'oeamtc.toProductsSelection.noOffers': 'Details',

        numberOfLifts: 'Number of lifts',
        checkout: 'Checkout',
        toLiftOperators: 'Liftoperators',

        customFaqTitle: 'FAQ especially for our destination & offers',

        personalizeYourTicket: '{{productionType}} personalisieren',
        personalizeYourKeycard: 'On which keycard should the ticket be booked',

        choosePersonToUse: 'Select the person who will use the ticket.',
        noSuggestionAvailable:
            'Desired person not listed? Create new person data with the button below.',

        choosePersonToUseKeycard:
            'Register your old ski pass here for ticket booking at starjack.',
        noSuggestionAvailableKeycard:
            'Enter your personal data and the serial number of the existing keycard.',

        enterPersonalDataSuggestion:
            'Enter the personal data of the person who will use the ticket.',

        registerKeycardTitle:
            'Register your old ski pass here for ticket booking at starjack.',
        registerKeycardSubtitle:
            'Enter your personal data and the serial number of the existing keycard.',

        enterRedeemCode: 'Enter redeem code',

        chooseAndAddTicket:
            'Choose your desired ticket and enter the number of people. Then add the ticket to the shopping cart.',

        selectTicketType: 'Choose the desired ticket type',

        chooseBetweenSingleOrPackage:
            'Choose between single or package tickets',

        chooseDate: 'Choose the date you want to go',

        chooseGroup: 'Choose your group',

        noSuggestionKeycard:
            'No keycard listed? Order or register a keycard using the buttons below.',
        noSuggestionEticket:
            'No person selectable? Create new person data with the button below.',
        noSuggestionPickup:
            'No person selectable? Create new person data with the button below.',

        crop: 'crop',

        imageOnlyCropped: 'Please click on "CROP" to properly size the image',

        voucherTeaserTitle: 'Gift voucher',
        voucherTeaserText:
            'The starjack print@home gift voucher is the ultimate declaration of love for mountain sports enthusiasts. Redeemable at all starjack mountain lifts for a variety of summer and winter offers. The perfect last-minute gift.',
        voucherTeaserButtonText: 'to the gift vouchers',

        searchFaqs: 'Search FAQ',

        choosePhoto: 'Choose photo',

        chooseNumberOfPerson: 'Choose the number of tickets above',
        discountName: 'Discount',

        lifOperatorsNearby: 'Liftoperators close to {{location}}',
        liftOperators: 'Mountain Railway',

        ordersMerchandise: 'merchandise',
        ordersKeycards: 'keycards(s)',
        ordersTickets: 'ticket(s)',
        ordersVouchers: 'voucher(s)',
        orderNumber: 'Order number',
        orderPosition: 'Billing position',

        keycardPrice: 'Keycard costs',

        deliveryAddress: 'Delivery address',
        billingAddress: 'Billing address',

        billingSameAsDelivery:
            'Billing address corresponds to the delivery address',

        lockedBalance:
            'You have {{value}} EUR of blocked balance. Blocked balance will be released within the next 30 minutes',

        buyStarjackVoucher: 'buy starjack-voucher',

        'error.addressValidation.missing_street_number':
            'Please enter a house number',
        'error.addressValidation.missing_postal_code':
            'Please enter the zip code to your address',
        'error.addressValidation.missing_locality':
            'Please enter a locality to your adress',
        'error.addressValidation.missing_general':
            'An error occurred while searching the address. Please enter the address manually',

        'addressDialog.title': 'Add new address',
        'googleAddressAutoComplete.enterAndSearch': 'Enter address and search',

        enterManually: 'Enter manually',
        searchAddress: 'Search address',

        'redeem_voucher.incert_voucher_not_redeemable':
            'Voucher not redeemable',
        'redeem_voucher.incert_voucher_not_partly_redeemable':
            'Voucher not partly redeemable',
        'redeem_voucher.incert_voucher_depleted': 'Voucher depleted',
        'redeem_voucher.incert_only_possible_with_integration_token':
            'Voucher is only usable at valid operators',
        'redeem_voucher.incert_not_active': 'Voucher not active',
        'redeem_voucher.invalid_currency': 'Invalid currency',
        'redeem_voucher.invalid_amount': 'Invalid amount',
        'redeem_voucher.min': 'Minimum value not reached',
        'redeem_voucher.voucher_not_paid': 'Voucher not paid',
        'redeem_voucher.incert_not_supported_in_portal':
            'This incert code is not supported on this platform',
        'redeem_voucher.voucher_already_exists':
            'This voucher code has already been used',

        'keycard.multi_usage.title': 'Keycard used for multiple tickets',
        'keycard.multi_usage.text_body':
            'You can book as many tickets as you like for different days and also for different mountain railway companies on your personalized starjack keycard. However, a maximum of 2 tickets for the same validity day or the same validity period. Each person needs a separate keycard.',

        'information_dialog.accept': 'I understand',

        checkoutIdMissing:
            'Your checkout session is invalid. Please try again later.',

        invoiceAddressSameAsShipping:
            'Shipping address is same as invoice address',

        addressDialogInvoiceTitle: 'Update invoice address',
        addressDialogShippingTitle: 'Update shipping address',

        invoiceAddressSaved: 'Invoice address saved',
        shippingAddressSaved: 'Shipping address saved',

        personIdentificationAdd: 'Person was successfully added and assigned',
        personIdentificationAddError:
            'Error while saving person data. Please try again later',

        checkSerialNumberInput: 'Please check your serial number input',

        POSSIBLE: 'POSSIBLE',
        START_DATE_MAYBE_TOO_EARLY:
            'This keycard might not be available in time for the start date. Please check the delivery time',
        START_DATE_TOO_EARLY:
            'This keycard will not be available in time for the start date',
        AGE_OUTSIDE_RANGE: 'Birthday outside the valid age range',
        IDENTIFICATION_ALREADY_USED:
            'This person has already been selected (possibly for another ticket)',
        INCOMPATIBLE_TICKET_PRODUCER:
            'This ticket cannot be booked on the keycard for this lift operator',
        INCOMPATIBLE_IDENTIFICATION_TYPE:
            'This person is not eligible for the selected booking type',
        IDENTIFICATION_PHOTO_REQUIRED: 'Photo required',
        EXTERNAL_CODE_NOT_PRESENT: 'External code not present',
        NO_SHIPPING_ADDRESS: 'No shipping address entered',

        checkoutValidation_ticket_identification_type_not_set:
            'Please select a booking type',

        createKeycardError: 'Error saving keycard data. Please try again later',

        registerAnotherKeycard: 'Register another keycard',
        paymentFailed: 'Payment failed. Please try again later',
        toPay: 'Amount to pay',
        remainingCredit: 'Remaining Credit',
        checkoutValidationTicketGeneralError: 'Error booking this ticket',
        checkoutValidationTicketGeneralWarning:
            'Please check your booking input',
        readMore: 'more',

        checkoutValidation_no_identification_assigned:
            'Please select a person for whom the ticket should be booked',
        checkoutValidation_photo_required:
            'Photo missing for the keycard using this ticket',
        checkoutValidation_start_date_too_early:
            'The validity of your tickets start before the estimated arrival of your keycard, Please select a later date of validity by removing the ticket from the shoping cart and placing it back into the shopping cart with a later validity date',
        checkoutValidation_start_date_too_early_hard:
            'The validity of your tickets start before the estimated arrival of your keycard, Please select a later date of validity by removing the ticket from the shoping cart and placing it back into the shopping cart with a later validity date',
        checkoutValidation_start_date_in_past:
            'There are invalid tickets in the shopping cart (start date in the past). Delete the ticket and add it to the shopping cart again to complete the booking.',
        checkoutValidation_startdate_too_early_soft:
            'It could be that the date that your ticket commences preceeds the arrival of your keycard.',
        checkoutValidation_invalid_ref:
            'An error has occurred while assigning a ticket to your keycard, Please select your keycard and try again.',
        checkoutValidation_internal_error:
            'An error has occurred with our payment provider, please note that we currently only accept payments from Mastercard and Visa.',
        checkoutValidation_token_expired:
            'Your registration has expired, Please register again to complete your order.',
        checkoutValidation_duplicate_ticket:
            'You already booked a ticket for this card and the period.',
        checkoutValidation_no_product_in_checkout:
            'There are no producst in the shopping cart',
        checkoutValidation_no_shipping_address:
            'No shipping address for this order',

        checkoutValidation_ticket_expired:
            'Ticket expired please remove it from the cart and add it again to finish the booking',

        identificationDialogNoOptionTitle:
            'How would you like to receive your ticket?',

        checkoutTicketsTitle: 'Tickets',
        checkoutIdentificationsTitle: 'Keycards',
        checkoutVouchersTitle: 'Vouchers',

        productsAvailable: 'Tickets available',
        noProductsAvailable: 'Currently no tickets available',

        'orderedVouchers.status.PAID': 'Paid',
        'orderedVouchers.status.NEW': 'New',
        'orderedVouchers.status.REDEEMED': 'Redeemed',

        myVouchers: 'My Vouchers',

        productFilterChooseTicketType: 'Day or season ticket',
        productFilterProductCategory: 'Product category',

        productFilterChooseTicketVariant: 'Single or group ticket',

        'orderedVouchers.redemptionDate': 'Redempted at',
        productFilterChoosePersons: 'Who is joining',

        productSelectionCompleteFilter:
            'Use the filter above to find the right ticket for you',

        productSelectionFilterNoMoreOptions: 'No more options available',

        'insurance.status.CREATED': 'Created',
        'insurance.status.BOOKED': 'Booked',
        'insurance.status.PERMISSION_BLOCKED': 'Claim report in progress',
        'insurance.status.PAID': 'Paid',
        'insurance.status.NEW': 'New',
        'insurance.status.CANCELED': 'Canceld',
        'insurance.status.REVOKED': 'Revoked',

        'myInsrancePage.title': 'My insurances',
        'MyAccountNavigation.insuranceProduct': 'Insurance Safety+ / Refund+',
        'myInsurancePage.tabs.label.current': 'Current insurances',
        'myInsurancePage.tabs.label.expired': 'Expired insurances',

        'myInsurancePage.content.label.insuranceTaker': 'Insurance for',
        'myInsurancePage.deleteDialog.title.cancel': 'Cancel insurance',
        'myInsurancePage.deleteDialog.title.refund': 'Refund insurance',
        'myInsurancesPage.deleteDialog.body.cancel':
            'Would you really like to cancel your ticket, including insurance?',
        'myInsurancesPage.deleteDialog.body.refund':
            'Would you really like to refund your ticket, including insurance?',

        'myInsurancesPage.deleteDialog.caption.cancel':
            'By clicking on "Cancel", the amount will be credited to your starjack account and you waive the payout of the insurance.\n' +
            'If you wish to receive a payout to your chosen payment method, please send us an email at support@starjack.com with your policy number and we will transfer the amount to your chosen payment method.\n' +
            'More information can be found in our FAQs.',

        'myInsurancesPage.deleteDialog.caption.refund':
            'By clicking “Revoke”, you confirm that the amount will be credited to your starjack account.\n' +
            'If you would like a payout to your chosen payment method, please send us an email at support@starjack.com with your policy number or call us at +43624673430999, and we will transfer the amount to your selected payment method.\n' +
            'For more information, please check our FAQs.',

        'myInsurancePage.deleteDialog.cancel.response.success':
            'Insurance successfully canceled',
        'myInsurancePage.deleteDialog.refund.response.success':
            'Insurance successfully refunded',
        'myInsurancePage.deleteDialog.cancel.response.error':
            'Error canceling the insurance. Please try again later',

        'myInsurancePage.deleteDialog.caption.cancel': `
            By clicking on “Cancel insurance” you confirm that the amount will be credited to your webshop account. 
            If you would like a payout to your chosen payment method, please send an email to <a href="mailto:support@starjack.com">support@starjack.com</a>
            with your insurance policy number or call <a href="tel:+43624673430999">+43624673430999</a>. 
            We will then transfer the amount back to your chosen payment method. 
            You can find more information in our <a href="/faq">FAQs</a>.
 
        `,

        'myInsurancePage.deleteDialog.caption.refund': `
            By clicking on “Revoke insurance” you confirm that the amount will be credited to your webshop account. 
            If you would like a payout to your chosen payment method, please send an email to 
            <a href="mailto:support@starjack.com">support@starjack.com</a> with your insurance policy number or call  <a href="tel:+43624673430999">+43624673430999</a>. 
            We will then transfer the amount back to your chosen payment method. 
            You can find more information in our <a href="/faq">FAQs</a>.
        `,

        'myInsurancePage.deleteDialog.button.cancelInsurance':
            'Cancel insurance',
        'myInsurancePage.deleteDialog.button.revokeInsurance':
            'Revoke insurance',

        'myInsurancePage.deleteDialog.body.refund':
            'Would you really like to refund your insurance?',

        'myInsurancePage.deleteDialog.body.cancel':
            'Would you really like to cancel insurance?',

        'myInsurancePage.deleteDialog.refund.response.error':
            'Error refunding the insurance. Please try again later',

        'myInsurancePage.noInsurance.current': 'No current insurances',
        'myInsurancePage.noInsurance.expired': 'No expired insurances',

        'myInsurancePage.policyNumber': 'Policy number',

        'myInsurancePage.insuranceName.label': 'Insurance',
        'myInsurancePage.liftoperator.label': 'Liftoperator',
        'myInsurancePage.ticketName.label': 'Ticket',

        'myInsurancePage.claimInProcess.alert.label': 'Claim processing',

        'insurance.productAddContainer.body.safteyPlus':
            'Do you want to protect yourself against the costs of your ski pass, ski lessons or ski instructor training if you or someone else has an accident on a ski slope? Furthermore, do you want to protect yourself and/or other people against the additional financial risks associated with the accident and shown in our overview? If so, then select ‘Add protection’.',
        'insurance.productAddContainer.body.refundPlus':
            'Do you want to cover the cost of a ski pass, ski lessons or ski instructor training if you or someone else has an accident on a ski slope? If yes, then select ‘Add protection’.',

        'insurance.productAddContainer.button.label': 'Add protection',
        'insurance.productAddContainer.button.labelAdded': 'Protection added',
        'insurance.productAddContainer.chip.label':
            'Insurance {{tariffName}} added',

        'insurance.pricePerPersonPrice.label':
            'incl. {{tariffPrice}} insurance',

        'checkoutPage.insuranceAlert.label':
            'For an order with insurance protection, accept and then download the declaration to complete the purchase.',

        'insuranceProductPage.title':
            'Safety+ or Refund+ from 3.99 € per ski pass ensures carefree skiing & snowboarding fun!',

        'insuranceProductPage.content': `
                We also offer you the opportunity to take out insurance for your ticket, as we are now also acting as an insurance agent. 
                Thanks to our cooperation with Société Générale Insurance and One Underwriting Agency GmbH, 
                we can provide you with excellent accident insurance for a worry-free winter vacation. Simply add it to your ticket.
        `,

        'insuranceProductPage.list.item0':
            'Simply add the appropriate insurance to your ticket to enjoy your winter vacation worry-free.',
        'insuranceProductPage.list.item1':
            'Do you have questions about the insurance product? You can find more information <a>here</a>',
        'insuranceProductPage.list.item2':
            'Information on how to report an accident/damage can be found <a target="_blank" rel="noreferrer" href="/report-claim">here</a>.',
        'insuranceProductPage.list.item3':
            'Information on canceling, revocation and the revocation period can be found <a target="_blank" rel="noreferrer" href="/faq">here</a>.',
        'insuranceProductPage.list.item4':
            'You can find more general information in the <a target="_blank" rel="noreferrer" href="/faq">faqs</a>.',

        'insuranceProductPage.openingHours': 'Mo . - Fr. 8:00 to 16:00 Uhr',

        'insuranceProductPage.supportCenter.help':
            'If you have technical problems during the conclusion of the contract, please contact our Service Center:',

        'insuranceReportClaimPage.title':
            'Report a claim? This is how it works:',
        'insuranceReportClaimPage.content': `
            If you have had a skiing or snowboarding accident and wish to report a claim, you can do so online 24/7.<br/>
            <strong style="display:inline">Important:</strong> Every insured person* should report the claim themselves, so please forward this information 
            if someone for whom you have paid for premium has had an accident. Each insured person needs their own insurance certificate/policy to do this.
        `,

        'insuranceReportClaimPage.list.step0.title':
            'Have your insurance policy number ready:',
        'insuranceReportClaimPage.list.step0.subtitle':
            'You can find this on your insurance certificate/policy.',

        'insuranceReportClaimPage.list.step1.title':
            'Click on the button below or link to the claims portal of our partner CallUs:',
        'insuranceReportClaimPage.list.step1.subtitle':
            'CallUS acts on behalf of the insurance company. You can find the button below and the link in the <a target="_blank" rel="noreferrer" href="/faq">faqs</a>.',
        'insuranceReportClaimPage.list.step2.title':
            'Fill out the claims form:',
        'insuranceReportClaimPage.list.step2.subtitle':
            'Now fill out the online claim form – please complete it in full.',
        'insuranceReportClaimPage.list.step3.title': 'Upload documents:',
        'insuranceReportClaimPage.list.step3.subtitle':
            'These include, for example, medical reports, invoices or ski course booking receipts.',
        'insuranceReportClaimPage.list.step4.title': 'Submit',
        'insuranceReportClaimPage.list.step4.subtitle':
            'Send the form. From now on, the insurer will take care of the claim. You will be contacted as soon as possible.',

        'insuranceReportClaimPage.bottom.label':
            'If you would like to contact us by phone, the CallUs team will be happy to help you: <a href="tel:+42131670833">+42131670833</a>. Service hours: daily from 8:00 - 16:00.',

        'insuranceReportClaimPage.asterix':
            '* This does not apply to children.',

        'insuranceReportClaimPage.button.label': 'Report claim',

        'myKeycardsPage.registration.success':
            'The keycard was successfully registered',

        'checkoutTicketCollection.insuranceSwitch.insurance': 'insurance',

        'sidebar.merch.title': 'Merchandise',

        'home.merch.title':
            'Show your love for winter sports - with our exclusive merchandise!',

        'home.merch.description':
            "Do you love hitting the slopes as well and want to show off your enthusiasm off them too= Then we've got just the thing for you! - Wheter on the slope, in everyday life or during après-ski. Discover our products now and discover your new favourite pieces!",

        'home.merch.list.material.hightlight': 'High-Quality Materials',
        'home.merch.list.material.text': 'For maximum comfort and durability',

        'home.merch.list.sustainable.hightlight': 'Sustainable',
        'home.merch.list.sustainable.text':
            'We value environmentally friendly production and long-lasting products.',

        'home.merch.button': 'Shop now',
        'home.merch.caption': '... and take your passion into everyday life!',

        'complaintsPage.title': 'Complaints',
        'complaintsPage.header': 'Would you like to file a complaint?',
        'complaintsPage.intro':
            'We always strive to serve customers in all matters of the insurance business to the best of our ability regarding their wishes and needs. Unfortunately, there may be cases where a customer is not satisfied with the service provided. In such cases, we ask you to inform us of these shortcomings.',
        'complaintsPage.instructions':
            'To process your complaint efficiently, we ask you to provide the following information:',
        'complaintsPage.fullName': 'Please provide your full name and address',
        'complaintsPage.reason':
            'Please describe the reason for your dissatisfaction in as much detail as possible',
        'complaintsPage.policyNumber':
            'Please provide your policy or claim number',
        'complaintsPage.documents':
            'If documents are relevant for processing or if you have questions about them, please attach them',
        'complaintsPage.contact': 'Policyholders are asked to contact',
        'complaintsPage.sjack': 'SJack GmbH or',
        'complaintsPage.insuranceCompany': 'directly to the insurance company.',
        'complaintsPage.email':
            'Please send your suggestions or any complaints about SJack GmbH to:',
        'complaintsPage.postal':
            'You can also send your complaint to us by post:',
        'complaintsPage.address':
            "SJack GmbH<br />Am Bühel 6<br />AT - 6830 Rankweil / Vorarlberg<br />(hereinafter referred to as 'Starjack')",
        'complaintsPage.investors':
            'Retail investors who file a complaint against a manufacturer of insurance-based investment products or against a key information document for insurance-based investment products will be informed about the forwarding of their complaint to the responsible insurance company and will receive a relevant response.',
        'complaintsPage.otherOptions':
            'You also have the option to forward your complaint to other authorities, in particular: Complaint office for insurance intermediaries at the BMAW, Federal Ministry for Labor and Economy, Department VI/A/1 (Trade Law) Stubenring 1, 1010 Vienna,',
        'complaintsPage.consumerArbitration':
            'Arbitration board for consumer transactions Mariahilfer Straße 103/1/18, 1060 Vienna,',
        'complaintsPage.dataProtection':
            'Austrian Data Protection Authority Barichgasse 40-42, 1030 Vienna,',
    },
};

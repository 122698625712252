import { Page } from '@/models/page';
import { useTranslation } from '@/hooks/useTranslation';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { Box, Container, Link, Typography } from '@mui/material';
import List from '@mui/material/List';

export const ComplaintsPage: Page = () => {
    const { getTranslated } = useTranslation();
    return (
        <SubPageTemplate
            title={getTranslated('complaintsPage.title')}
            widthExtended
        >
            <Container
                sx={{
                    py: 4,
                }}
                maxWidth='lg'
            >
                <Typography
                    variant='h5'
                    sx={{
                        fontWeight: 600,
                        mb: 2,
                    }}
                >
                    {getTranslated('complaintsPage.header')}
                </Typography>
                <Typography
                    sx={{
                        mb: 2,
                    }}
                >
                    {getTranslated('complaintsPage.intro')}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 600,
                    }}
                >
                    {getTranslated('complaintsPage.instructions')}
                </Typography>
                <List
                    sx={{
                        listStyle: 'disc',
                        ml: 5,
                    }}
                >
                    <Typography component='li'>
                        {getTranslated('complaintsPage.fullName')}
                    </Typography>
                    <Typography component='li'>
                        {getTranslated('complaintsPage.reason')}
                    </Typography>
                    <Typography component='li'>
                        {getTranslated('complaintsPage.policyNumber')}
                    </Typography>
                    <Typography component='li'>
                        {getTranslated('complaintsPage.documents')}
                    </Typography>
                </List>

                <Box>
                    <Typography
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        {getTranslated('complaintsPage.contact')}
                    </Typography>
                    <List
                        sx={{
                            listStyle: 'disc',
                            ml: 5,
                        }}
                    >
                        <Typography component='li'>
                            {getTranslated('complaintsPage.sjack')}
                        </Typography>
                        <Typography component='li'>
                            {getTranslated('complaintsPage.insuranceCompany')}
                        </Typography>
                    </List>
                </Box>

                <Box
                    sx={{
                        my: 2,
                    }}
                >
                    <Typography>
                        {getTranslated('complaintsPage.email')}{' '}
                        <Link href='mailto:support@starjack.com'>
                            support@starjack.com
                        </Link>
                    </Typography>
                </Box>

                <Box
                    sx={{
                        my: 2,
                    }}
                >
                    <Typography
                        sx={{
                            mb: 2,
                        }}
                    >
                        {getTranslated('complaintsPage.postal')}
                    </Typography>

                    <Typography
                        sx={{
                            ml: 2,
                            fontWeight: 600,
                        }}
                        variant='subtitle2'
                        dangerouslySetInnerHTML={{
                            __html: getTranslated('complaintsPage.address'),
                        }}
                    ></Typography>
                </Box>

                <Box
                    sx={{
                        mt: 3,
                    }}
                >
                    <Typography>
                        {getTranslated('complaintsPage.investors')}
                        <br />
                        <br />
                        {getTranslated('complaintsPage.otherOptions')}{' '}
                        <Link rel='noreferrer' href='www.bmaw.gv.at'>
                            www.bmaw.gv.at
                        </Link>
                        .
                        <br />
                        <br />
                        {getTranslated(
                            'complaintsPage.consumerArbitration'
                        )}{' '}
                        <Link
                            rel='noreferrer'
                            href='www.verbraucherschlichtung.at'
                        >
                            www.verbraucherschlichtung.at,
                        </Link>{' '}
                        {getTranslated('complaintsPage.dataProtection')}{' '}
                        <Link rel='noreferrer' href='www.dsb.gv.at'>
                            www.dsb.gv.at
                        </Link>
                        .
                    </Typography>
                </Box>
            </Container>
        </SubPageTemplate>
    );
};

ComplaintsPage.path = '/complaints';

import gtcOne from '@/assets/pdfs/datenschutzhinweis_one.pdf';

export default {
    locale: 'de',
    messages: {
        'common.hide': 'Ausblenden',
        'common.pistenkilometer': 'Pistenkilometer',
        'common.cablecars': 'Bahnen',
        'common.wind': '{speed} Wind',
        'common.showTickets': 'Tickets ansehen',
        'common.userAccount': 'Benutzerkonto',
        'common.passwordForgot': 'Passwort vergessen?',
        'common.login': 'Anmelden',
        'common.register': 'Registrieren',
        'common.registerLogin': 'Registrieren/Anmelden',
        'common.basket': 'Warenkorb',
        'common.toBasket': 'in den Warenkorb',
        'common.requestHelp': 'Hilfe anfordern',
        'common.singleDayTicket': 'Angebote bis 1 Tag',
        'common.dayTicket': '{duration} Tageskarte',
        'common.dayTickets': '{from} - {to} Tageskarten',
        'common.dayTickets+': '{from}+ Tageskarten',
        'common.seasonTicket': 'Sonder- und Saisontickets',
        'common.seasonTickets': 'Sonder- und Saisontickets',
        'common.ticket': 'Ticket',
        'common.tax': 'inkl. Ust.',
        'common.save': 'Speichern',
        'common.orderCard': 'Karte bestellen',
        'common.abort': 'Abbrechen',
        'common.createNewCard': 'Neue Keycard bestellen',
        'common.status': 'Status',
        'common.changePassword': 'Passwort ändern',
        'common.changeEmail': 'E-Mail ändern',
        'common.pdf': 'PDF',
        'common.dataLoading': 'Daten werden geladen...',
        'common.keycard': 'Keycard',
        'common.orderKeycard': 'Keycard bestellen',
        'common.linkExternalCard': 'Keycard registrieren',
        'common.required': 'Pflichtfeld',

        'footer.help': 'Hilfe',
        'footer.gtc': 'AGB',
        'footer.imprint': 'Impressum',
        'footer.dataPrivacy': 'Datenschutz',
        'footer.payment': 'Zahlungsmittel',
        'footer.hotline':
            'Bei Fragen sind wir unter +43.6246.73430 999 erreichbar',

        'footer.statusinformation.label': 'Statusinformationen',

        'footer.linkColumn.insuracenProductPage':
            'Versicherung Safety+ / Refund+',

        'navigation.bookTickets': 'Skitickets buchen',
        'navigation.about': 'Was ist starjack?',
        'navigation.faq': 'FAQ',
        'navigation.voucher': 'Gutschein',

        'liftoperators.tooltipRegion':
            'Gib hier einen Standort ein, von dem aus du die Umkreissuche starten möchtest.',
        'liftoperators.tooltipPistenkilometer':
            'Skigebiet nach Pistenkilometer finden. Liste und Karte passen sich deiner Auswahl an.',
        'liftoperators.tooltipAnlagen':
            'Skigebiet nach Anzahl der Liftanlagen finden. Liste und Karte passen sich deiner Auswahl an.',
        'liftoperators.maxDuration': 'Fahrzeit max. {duration} Min.',
        'liftoperators.chooseLocation': 'Standort wählen',
        'liftoperators.locationSearch': 'Umkreissuche',
        'liftoperators.pistenkilometer': 'Pistenkilometer {km}+',
        'liftoperators.liftanlagen': 'Anzahl Liftanlagen {count}+',
        'liftoperators.map': 'Karte',
        'liftoperators.list': 'Liste',
        'liftoperators.search': 'Bergbahn oder Ort eingeben',

        'LiftoperatorItem.tooltipBlueDot': 'leichte Piste',
        'LiftoperatorItem.tooltipRedDot': 'mittelschwere Piste',
        'LiftoperatorItem.tooltipBlackDot': 'schwere Piste',

        'LiftoperatorItemCompressed.chooseLiftoperator':
            'Anderes Skigebiet wählen',

        'SupportPage.help': 'Hilfe',
        'SupportPage.faq': 'Häufige Fragen und Antworten durchsuchen',
        'SupportPage.openFaq': 'FAQ öffnen',
        'SupportPage.contactEmail': 'E-Mail:',
        'SupportPage.contactPhone': 'Telefon:',

        'ActivationPage.activation': 'Aktivierung',
        'ActivationPage.success':
            'Die Aktivierung konnte erfolgreich durchgeführt werden.<br/>Du wurdest automatisch angemeldet.',
        'ActivationPage.error':
            'Die Aktivierung konnte nicht gefunden werden oder wurde bereits bestätigt.',
        'ActivationPage.activate':
            'Um deinen Account zu aktivieren, gib bitte dein Passwort ein.',

        'FAQPage.faq': 'FAQ',
        'FAQPage.searchFaqs': 'FAQs durchsuchen',

        'ProductsPage.tooltipDatum':
            'Hier wird das Datum des gewünschten ersten Skitags eingegeben. Sollte am heutigen Tag kein Tarif verfügbar sein wird automatisch der erste Tag mit verfügbaren Tarifen gewählt.',
        'ProductsPage.tooltipDauer':
            'Um schneller und präziser zu den gewünschten Tickets zu kommen kann hier ein Filter z.B. 2 Tage Tickets oder Wochentickets usw. eingestellt werden sodass nur noch diese Tickets im Ergebnis rechts angezeigt werden.',
        'ProductsPage.tooltipTagespreis':
            'Hier kann der Ticketpreis eingeschränkt werden. Bei Mehrtagestickets wird der Preis pro Tag berechnet.',
        'ProductsPage.tooltipTickettyp':
            'Hier kann die Auswahl nach dem passenden Tickettypen eingeschränkt werden.',
        'ProductsPage.chooseStartDate': 'Gültigkeitsdatum wählen',
        'ProductsPage.durationFilter': 'Dauer einschränken',
        'ProductsPage.dayPrice': 'Tagespreis {price}',
        'ProductsPage.noTicketsFound':
            'Es wurden keine Tickets für den gewählten Filter gefunden.',
        'ProductsPage.noTicketsFoundForLiftOperator':
            'Das gewählte Unternehmen bietet derzeit keine Tickets zum Onlinekauf an.',
        'ProductsPage.noTicketsFoundBeforeEarliestDay':
            'Die nächsten Tickets sind ab dem {earliestDay} verfügbar.',
        'ProductsPage.combi.person.select': 'Personen wählen',
        'ProductsPage.allSkiers': 'Personentyp',
        'ProductsPage.allSkiers.select': 'Personentyp auswählen',
        'ProductsPage.photoRequired':
            'fotopflichtig, nur mit Keycards inkl Foto buchbar',
        'ProductsPage.toWebsite': 'zur Webseite',
        'ProductsPage.productOverview': 'Produktübersicht',
        'ProductsPage.payment': 'Bezahlung',

        'Product.birthday': 'Geboren {from} - {to}',
        'Product.age': 'Alter {from} - {to}',
        'Product.ageGroup': 'Jahrgang {from} - {to}',
        'Product.availability': 'Gültig ab',
        'Product.availability.at': 'am {day}',
        'Product.availability.fromTo': 'von {from} - {to}',
        'Product.startDay.from': 'Gültig ab',
        'Product.startDay.at': 'Gültig',

        'Cart.pay': 'Kostenpflichtig Bestellen',
        'Cart.total': 'Gesamt',
        'Cart.toCheckout': 'zur Bestellung',
        'Cart.empty': 'Dein Warenkorb ist leer',
        'Cart.yourCartIsEmpty':
            'Dein Warenkorb ist leer.<br/>Wähle links ein Produkt aus, um eine Bestellung durchzuführen.',
        'Cart.credit':
            'Dein Guthaben beträgt {amount} und wird bei der Bezahlung berücksichtigt.',
        'Cart.tickets': 'Tickets',
        'Cart.discounts': 'Rabatte',
        'Cart.vouchers': 'Gutscheine',
        'Cart.keycards': 'Keycards',
        'Cart.selectKeycardOption': 'Wähle die fehlenden Keycardoptionen aus',

        'Order.orderedAt': 'Bestellt am',
        'Order.free': 'GRATIS',

        'AboutPage.welcomeHeadline':
            'Willkommen in der Welt von starjack!<br/>Mit deiner starjack-Keycard hast du Zugang zu',
        'AboutPage.skiRegions': 'Skiregionen',
        'AboutPage.skiRegionsDescription':
            'die von dir entdeckt werden wollen. Lass dich von den vielfältigen Angeboten inspirieren.',
        'AboutPage.liftoperators': 'Liftanlagen',
        'AboutPage.liftoperatorsDescription':
            'die dich auf die spannendsten Pisten und zu den beeindruckendsten Panoramen bringen.',
        'AboutPage.slopes': 'Pistenkilometer',
        'AboutPage.slopesDescription':
            'mit insgesamt {statisticSlopeKms} Pistenkilometern für deinen anspruchsvollsten Skigenuss.',
        'AboutPage.personalKeyCard':
            'Hol Dir jetzt deine persönliche<br/>starjack Keycard nach Hause.',
        'AboutPage.personalKeyCardDescription':
            'Einmal bestellen und viele Jahre lang beliebig oft online mit deinen Skitickets aufladen.',
        'AboutPage.fastAndSimpleKeyCardHeadline':
            'Schnell und einfach zu deiner<br/>persönlichen starjack Keycard',
        'AboutPage.orderColumnLabel': 'Bestellung',
        'AboutPage.orderColumnDescription':
            'Kostenlosen starjack-Account anlegen, persönliche starjack Keycard(s) erstellen und per Post nach Hause senden lassen.',
        'AboutPage.shippingColumnLabel': 'Keycard Versand',
        'AboutPage.shippingColumnDescription':
            'Per Express erhältst du deine starjack Keycard, welche viele Jahre lang gültig ist und beliebig oft mit Skipässen aufgeladen werden kann.',
        'AboutPage.deliveryColumnLabel': 'Skipassbuchung in Echtzeit',
        'AboutPage.deliveryColumnDescription':
            'Ab jetzt buchst du in Sekundenschnelle deinen Skipass direkt auf deine starjack Keycard. Online oder mobile über deinen starjack-Account.',
        'AboutPage.starjackSummitDescriptionTop':
            'Begeisterung und Liebe zum Bergsport brachte starjack zum Leben und macht es zu dem, was es heute ist: Deine starjack Keycard, die dich stressfrei schneller auf die spannendsten Pisten und schönsten Pfade der Bergwelt bringt.',
        'AboutPage.starjackSummitHeadline':
            'Mit starjack<br/>schneller zum Gipfel',
        'AboutPage.starjackSummitDescriptionBottom':
            'Buche das Ticket für dein gewünschtes Skigebiet mit wenigen Klicks direkt auf deine starjack Keycard. Bequem von Zuhause aus oder ganz spontan unterwegs. Binnen Sekunden hast du dein Skiticket auf deiner persönlichen starjack Keycard und kannst das Drehkreuz ins Skivergnügen passieren - ohne Warteschlangen an den Ticketkassen.<br/><br/><b>Ungebremst. Unkompliziert. Ungebunden.</b>',
        'AboutPage.advantagesHeadline': 'Vorteile',
        'AboutPage.advantage1Label': 'Moderne Keycard',
        'AboutPage.advantage1Description': 'Funktioniert in jedem Skigebiet',
        'AboutPage.advantage2Label': 'Personalisierbar',
        'AboutPage.advantage2Description': 'Mit deinem persönlichen Foto',
        'AboutPage.withoutWaiting': 'Ohne<br/>Wartezeit',
        'AboutPage.withoutWaitingDescription':
            'Du brauchst nicht mehr an den Kassen bei unseren starjack Vertragspartnern anzustehen.<br/>Nach deiner erfolgten Buchung wird dein gebuchtes Ticket innert Sekunden auf deiner starjack Keycard gebucht<br/>und du kannst ganz bequem direkt mit deinem Freizeitspass beginnen.<br/>So geht Ticket!',
        'AboutPage.payment': 'Verschiedene<br/>Zahlungsmöglichkeiten',
        'AboutPage.configuration': 'Jetzt persönlich Karte<br/>konfigurieren',
        'AboutPage.createCard': 'Karte erstellen',

        'AboutPageSummer.personalKeyCard':
            'Hol sie dir jetzt: Deine persönliche starjack Keycard!',
        'AboutPageSummer.personalKeyCardDescription':
            'Was auch immer dich auf den Berg lockt, dein Ticket dafür buchst du bequem online. Bestelle dir einmal deine persönliche starjack-Keycard per express nach Hause und lade beliebig oft deine Tickets darauf. In Echtzeit, das heißt wenige Sekunden nach der Buchung ist dein Ticket schon aktiv.',
        'AboutPageSummer.registerExternalKeycardButtonContainer':
            'SKITICKET REGISTRIEREN',
        'AboutPageSummer.registerExternalKeycardDescription':
            'Du hast noch ein Skiticket zuhause?<br/>Logg dich in deinen starjack Account ein und schau nach, ob du dieses registrieren und zur Buchung verwenden kannst.',
        'AboutPageSummer.shippingColumnDescription':
            'Per Express erhältst du deine starjack Keycard, welche viele Jahre lang gültig ist und beliebig oft online mit Bergbahntickets aufgeladen werden kann.',
        'AboutPageSummer.deliveryColumnLabel': 'Ticketbuchung in Echtzeit',
        'AboutPageSummer.deliveryColumnDescription':
            'Ab jetzt buchst du in Sekundenschnelle deine Bergbahntickets direkt auf deine starjack Keycard. Online oder mobile über deinen starjack-Account.',

        'Address.email': 'E-Mail',
        'Address.phone': 'Telefon',
        'Address.newEmail': 'Neue E-Mail',
        'Address.salutation': 'Anrede',
        'Address.gender': 'Geschlecht',
        'Address.salutation.male': 'Herr',
        'Address.salutation.female': 'Frau',
        'Address.title': 'Titel',
        'Address.firstname': 'Vorname',
        'Address.lastname': 'Nachname',
        'Address.street': 'Straße und Hausnummer',
        'Address.zipcode': 'PLZ',
        'Address.city': 'Ort',
        'Address.country': 'Land',
        'Address.company': 'Firma',
        'Address.password': 'Passwort',
        'Address.password1': 'Passwort',
        'Address.password2': 'Passwort wiederholen',
        'Address.currentPassword': 'Aktuelles Passwort',
        'Address.newPassword': 'Neues Passwort',
        'Address.birthday': 'Geburtsdatum',
        'Address.birthday.day': 'Tag',
        'Address.birthday.month': 'Monat',
        'Address.birthday.year': 'Jahr',
        'Address.agb1': 'Ich stimme den',
        'Address.agb2': 'AGB’s',
        'Address.agb3': 'der Firma SJack GmbH (starjack.com) zu.',
        'Address.newsletterDE': 'Newsletter in Deutsch',
        'Address.newsletterEN': 'Newsletter in Englisch',
        'Address.serialNumber': 'Seriennummer',
        'Address.serialNumberExplanation':
            'Seriennummer: Hier 23-stellige Seriennummer auf der Rückseite der SKIDATA Keycard angeben, Eingabe ohne Trennstrich oder Leerzeichen. Registriert werden können nur Keycards, deren ID mit "01" beginnt.',
        'Address.marketingAcceptance':
            'Ich willige der Weiterleitung meiner persönlichen Daten zu Werbezwecke an die Bergbahnen ein, bei welchen ich die Ticketbuchungen vornehme. Die Einwilligung kann jederzeit über meinen Account (Menü „Meine Daten“) zurückgezogen werden.',

        'ProfileForm.dataChanged': 'Daten wurde geändert',

        'ActivationForm.save': 'Registrierung abschließen',
        'ChangePasswordForm.save': 'Passwort ändern',
        'ChangePasswordForm.passwordChanged': 'Passwort wurde geändert',
        'ChangeEmailForm.save': 'E-Mail ändern',
        'ChangeEmailForm.emailChanged':
            'Zur Bestätigung der Änderung haben wir dir eine E-Mail gesendet. Bitte klicke den darin enthaltenen Link an.',
        'LoginForm.login': 'Anmelden',
        'LoginForm.passwordForgotten': 'Passwort vergessen?',
        'PasswordForgottenForm.info':
            'Wir haben dir ein E-Mail mit einer Anleitung zum Zurücksetzen des Passwortes gesendet.',
        'PasswordForgottenForm.reset': 'Passwort zurücksetzen',
        'PasswordForgottenForm.error':
            'Passwort konnte nicht zurückgesetzt werden.',
        'PasswordForgottenForm.success': 'Passwort wurde zurückgesetzt',
        'RegisterForm.register': 'Registrieren',

        'validation.error.email.required':
            'Bitte gib deine E-Mail Adresse ein.',

        'validation.error.birthday.missing': 'Bitte gib dein Geburtsdatum ein.',
        'validation.error.email.invalid': 'Deine E-Mail Adresse ist ungültig.',
        'validation.error.email.long': 'Deine E-Mail Adresse ist zu lang.',
        'validation.error.salutation.required': 'Bitte wähle eine Anrede aus.',
        'validation.error.firstname.required': 'Bitte gib deinen Vornamen ein.',
        'validation.error.country.required': 'Bitte gib ein Land ein.',
        'validation.error.firstname.short': 'Dein Vorname ist zu kurz.',
        'validation.error.firstname.long': 'Dein Vorname ist zu lang.',
        'validation.error.lastname.required': 'Bitte gib deinen Nachnamen ein.',
        'validation.error.lastname.short': 'Dein Nachname ist zu kurz.',
        'validation.error.lastname.long': 'Dein Nachname ist zu lang.',
        'validation.error.title.long': 'Dein Titel ist zu lang.',
        'validation.error.street.required': 'Bitte gib eine Straße ein.',
        'validation.error.zipcode.required': 'Bitte gib eine PLZ ein.',
        'validation.error.zipcode.number': 'Die PLZ muss eine ganze Zahl sein.',
        'validation.error.city.required': 'Bitte gib einen Ort ein.',
        'validation.error.password.requiredShort':
            'Bitte gib dein Passwort ein.',
        'validation.error.password.addNew': 'Bitte gib ein neues Passwort ein.',
        'validation.error.password.addNewAgain':
            'Bitte gib dein neues Passwort noch einmal ein.',
        'validation.error.password.required':
            'Bitte gib ein Passwort mit mindestens 6 Zeichen ein.',
        'validation.error.password.match':
            'Die Passwörter stimmen nicht überein.',
        'validation.error.password.current':
            'Bitte gib dein aktuelles Passwort ein.',
        'validation.error.gtc.required': 'Bitte lese und akzeptiere die AGBs.',
        'validation.error.assignKeycard':
            'Bitte ordne eine Keycard zu oder erstelle eine neue.',
        'validation.error.gender.required': 'Bitte wähle ein Geschlecht aus.',
        'validation.error.keycard.title.long': 'Der Titel ist zu lang.',
        'validation.error.keycard.firstname.required':
            'Bitte gib einen Vornamen ein.',
        'validation.error.keycard.firstname.long': 'Der Vorname ist zu lang.',
        'validation.error.keycard.lastname.required':
            'Bitte gib einen Nachnamen ein.',
        'validation.error.keycard.lastname.long': 'Der Nachname ist zu lang.',
        'validation.error.keycard.name.long':
            'Vorname und Nachname dürfen 39 Zeichen nicht überschreiten.',
        'validation.error.keycard.birthday.required':
            'Geburtsdatum ist nicht vollständig angegeben.',
        'validation.error.keycard.birthday.invalid':
            'Geburtsdatum ist fehlerhaft.',
        'validation.error.orderAddress.firstname.required':
            'Bitte gib einen Vornamen ein.',
        'validation.error.orderAddress.firstname.long':
            'Der Vorname ist zu lang.',
        'validation.error.orderAddress.lastname.required':
            'Bitte gib einen Nachnamen ein.',
        'validation.error.orderAddress.lastname.long':
            'Der Nachname ist zu lang.',
        'validation.error.orderAddress.company.long':
            'Die Firmenbezeichnung ist zu lang.',
        'validation.error.orderAddress.street.required':
            'Bitte gib deine Straße und Hausnummer ein.',
        'validation.error.orderAddress.street.long': 'Die Straße ist zu lang.',
        'validation.error.orderAddress.zipcode.required':
            'Bitte gib deine PLZ ein.',
        'validation.error.orderAddress.zipcode.long': 'Die PLZ ist zu lang.',
        'validation.error.orderAddress.city.required':
            'Bitte gib deinen Ort ein.',
        'validation.error.orderAddress.city.long': 'Der Ort ist zu lang.',
        'validation.error.orderAddress.deliveryLocked':
            'In das ausgewählte Land können leider keine Keycards versendet werden.',
        'validation.error.serialNumber':
            'Bitte gib deine 23-stellige Seriennummer ein.',
        'validation.error.product_not_found':
            'Die Tickets in deinem Warenkorb sind nicht mehr gültig. Bitte entferne die Tickets und befülle den Warenkorb erneut.',

        'validation.external_code_needs_keycard_identification':
            'Ein Ticket mit einem Promotionscode muss einer aktivierten Keycard mit diesem Promotionscode zugeordnet sein',
        'validation.external_code_not_present':
            'Promotionscode auf Keycard nicht vorhanden',

        'validation.error.duplicate_ticket':
            'Du hast bereits ein Ticket, das in dem gewählten Zeitraum gültig ist.',
        'validation.error.unknownError':
            'Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es später nochmal.',
        'validation.error.registration.userExists':
            'Dieser Benutzer existiert bereits. Bitte verwende eine andere E-Mail Adresse.',
        'validation.error.passwordForgotten.recoveryFailed':
            'Passwort konnte nicht zurückgesetzt werden.',
        'validation.error.login.failed': 'Deine Anmeldedaten sind ungültig.',
        'validation.error.changePassword.incorrectPassword':
            'Ungültiges Passwort',
        'validation.error.changePassword.incorrect_password':
            'Ungültiges Passwort',
        'validation.error.changeEmail.email': 'Ungültige E-Mail Adresse',
        'validation.error.changeEmail.incorrectPassword': 'Ungültiges Passwort',
        'validation.error.changeEmail.incorrect_password':
            'Ungültiges Passwort',
        'validation.error.payment.unknownError':
            'Es ist ein unbekannter Fehler bei der Bezahlung aufgetreten. Bitte lade die Seite neu und versuche es erneut.',
        'validation.error.checkout.unknownError':
            'Es ist ein unbekannter Fehler aufgetreten.',
        'validation.error.checkout.incert_voucher_not_found':
            'Ungültiger Gutscheincode',
        'validation.error.checkout.incert_voucher_not_partly_redeemable':
            'Gutscheincode kann nicht zur Teilzahlung verwendet werden',
        'validation.error.checkout.incert_voucher_not_redeemable':
            'Gutscheincode kann nicht zur Zahlung verwendet werden',
        'validation.error.checkout.incert_voucher_depleted':
            'Gutscheincode aufgebraucht',
        'validation.error.checkout.lift_operator_not_active':
            'Bei der gewählten Bergbahn können derzeit leider keine Tickets erworben werden.',
        'validation.error.linkExternalKeycard.invalid_serial_number':
            'Ungültige Seriennummer',
        serial_number_already_registered: 'Keycard bereits verwendet',
        'validation.error.mustBeginWithZeroOne':
            'Registriert werden können nur Keycards, deren ID mit "01" beginnt.',
        'validation.error.lengthMustBeTwentyThree':
            'Der SKIDATA-Code muss 23 Zeichen lang sein',

        'OrderCardPage.headline': 'Deine persönliche<br/>Karte',
        'OrderCardPage.description1':
            'Bestelle dir deine persönliche starjack Keycard und du brauchst nie mehr an den Kassen der starjack Bergbahnpartner warten.<br/>Somit kannst du direkt loslegen und hast mehr Zeit für dein Freizeitvergnügen.',
        'OrderCardPage.description2':
            'Deine persönliche starjack Keycard ist nicht übertragbar und ab Bestellung für viele Jahre gültig. Für deine Familie kannst du auch mit deinem Benutzerkonto mehrere starjack Keycard verwalten und Bergbahntickets / Skipässe direkt auf die jeweilige Keycard buchen.',

        'CheckoutPage.emptyBasket': 'Dein Warenkorb ist noch leer',
        'CheckoutPage.createAnotherCard': 'Weitere Keycard erstellen',
        'CheckoutPage.goToPay': 'Weiter zur Zahlung',
        'CheckoutPage.orderWillBeChecked': 'Deine Bestellung wird geprüft',
        'CheckoutPage.creditCardPrice':
            'Der Betrag von {price} wird vom gewählten Zahlungsmittel eingezogen.',
        'CheckoutPage.creditCardBalancePrice':
            'Der Betrag von {price} wird von deinem Guthaben abgezogen.',
        'CheckoutPage.creditCardBalancePriceIncert':
            'Der Betrag von {price} wird von deinem Gutschein abgezogen.',
        'CheckoutPage.orderCharge': 'Kostenpflichtig bestellen',
        'CheckoutPage.abortPayment': 'Bezahlung abbrechen',
        'CheckoutPage.balancePaymentInfo':
            'Dein Guthaben deckt den Gesamtbetrag der Bestellung vollständig ab. Daher ist keine Bezahlung per Kreditkarte notwendig. Bitte bestätige die Bezahlung per Guthaben und schließe die Bestellung somit ab.',
        'CheckoutPage.balancePaymentInfoIncert':
            'Das Gutschein Guthaben deckt den Gesamtbetrag der Bestellung vollständig ab. Daher ist keine Bezahlung per Kreditkarte notwendig. Bitte bestätige die Bezahlung per Gutschein und schließe die Bestellung somit ab.',
        'CheckoutPage.infoNewKeycard':
            'Neue Keycard - bitte untere Felder befüllen',
        'CheckoutPage.infoKeycard':
            '{title}, Gültig bis: {expirationDate}, SN: {serialNumber}',
        'CheckoutPage.infoExternalKeycard': '{title}, SN: {serialNumber}',
        'CheckoutPage.profileChooser': 'Gespeicherte Kreditkarten',
        'CheckoutPage.saveProfile':
            'Kreditkartendaten sicher für zukünftige Transaktionen speichern.',
        'CheckoutPage.useNewCreditCard': 'Neue Kreditkartendaten eingeben',
        'CheckoutPage.creditcard': 'Kreditkarte',
        'CheckoutPage.sofort': 'Sofortüberweisung',
        'CheckoutPage.ideal': 'iDEAL',
        'CheckoutPage.eps': 'Banküberweisung',
        'CheckoutPage.masterpass': 'Masterpass',
        'CheckoutPage.apple': 'Apple Pay',
        'CheckoutPage.google': 'Google Pay',
        'CheckoutPage.covidCheck':
            'Die Covid19-Zusatzerklärung für den Kauf von Online-Tickets habe ich gelesen, verstanden und akzeptiert',
        'CheckoutPage.covidCheckMore': 'Covid19-Zusatzerklärung abrufen',
        'CheckoutPage.insuranceDataCard.title':
            'Versicherungs- und Kontaktdaten',
        'CheckoutPage.insurance.phone.alert':
            'Für eine Bestellung mit Versicherungsschutz ist deine Telefonnummer nötig',

        'CheckoutPage.insurance.birthdate.alert':
            'Für eine Bestellung mit Versicherungsschutz ist das Geburtsdatum des Versicherungsnehmers nötig',

        'CheckoutPage.insurance.contatctData.warning':
            'Für eine Bestellung mit Versicherungsschutz musst du deine Telefonnummer und dein Geburtsdatum angeben.',

        'CheckoutPage.insurance.input.phone.label':
            'Telefonnummer mit Ländervorwahl',
        'CheckoutPage.insurance.input.phone.error':
            'Bitte gib deine Telefonnummer an',

        'CheckoutPage.insurance.input.birthdate.error':
            'Bitte gib dein Geburtsdatum an',

        'CheckoutPage.insurance.enter.success':
            'Versicherungskontaktdaten gespeichert',
        'CheckoutPage.insurance.enter.error':
            'Fehler beim Speichern der Kontaktdaten. Bitte versuche es später noch einaml.',

        'CheckoutPage.insurance.contactData.dialog.title':
            'Fehlende Kontaktdaten',
        'CheckoutPage.consent.downloadButton.label':
            'Vorvertragliche Dokumente herunterladen',

        'CheckoutPage.consent.downloadButton.label.downloaded':
            'Vorvertragliche Dokumente bereits heruntergeladen',

        'CheckoutPage.consent.missing.error':
            'Bitte stimme den Versicherungsbedingungen zu bevor du den Kauf abschließen kannst.',

        'CheckoutPage.insurance.explanation': `

            <ul style="padding-left: 16px;">
            
                <li>Der Versicherungsvertrag kommt erst mit der Zustellung des Versicherungsscheins zustande. Vor diesem Zeitpunkt besteht kein Versicherungsschutz. Nach Zustellung des Versicherungsscheines besteht Versicherungsschutz ab dem gewünschten Startdatum, das du bei der Buchung angegeben hast, sofern du die Prämie rechtzeitig und vollständig bezahlst.</li>
                
                <li>Datenschutzhinweise von One findest du <a href="${gtcOne}" target="_blank" rel="noreferrer">hier</a> </li>      
                <li>
                  Datenschutzhinweise von Starjack Insurance findest du <a href="/gtc" target="_blank" rel="noreferrer">hier</a>
                </li>
            </ul>  
        `,

        'Checkoutpage.insurance.consent.title': 'Zustimmungserklärung',

        'CheckoutSuccessPage.headline': 'Vielen Dank für deine Bestellung!',
        'CheckoutSuccessPage.text': 'Deine Bestätigung erhältst du per E-Mail.',
        'CheckoutErrorPage.headline': 'Keine Bezahlung erfolgt',
        'CheckoutErrorPage.text':
            'Die Bestellung wurde abgebrochen. Es erfolgte keine Zahlung. Bitte wiederhole den Vorgang.',

        'LoginPage.notRegistered': 'Noch nicht registriert?',
        'LoginPage.notRegisteredText':
            'Mit deiner Registrierung eröffnest du dein persönliches Kundenkonto bei starjack.com und hast ab sofort die Möglichkeit bei allen starjack Vertragspartner Bergbahntickets / Skipässe direkt zu buchen. Damit ist Anstehen an der Kassa Vergangenheit!',

        'MyCardsPage.headline': 'Meine starjack-Keycards',
        'MyCardsPage.noKeycard': 'Du hast noch keine Keycard.',
        'MyCardsPage.validTo': 'Gültig bis<br/>{date}',
        'MyCardsPage.serialNumber': 'Seriennummer',
        'MyCardsPage.delete': 'Keycard löschen',
        'MyCardsPage.deleteIt': 'Löschen',
        'MyCardsPage.transfer': 'Keycard übertragen',
        'MyCardsPage.transferSuccessful': 'Übertragung erfolgreich',
        'MyCardsPage.transferError': 'Übertragung fehlgeschlagen',
        'MyCardsPage.transferErrorEmailNotFound':
            'Empfänger E-mail nicht gefunden',
        'MyCardsPage.chose': 'Übernehmen',
        'MyCardsPage.inTransfer':
            'Keycard ist zur Übertragung an {email} freigegeben',
        'MyCardsPage.cancelTransfer': 'Übertragung abbrechen',
        'MyCardsPage.deletedAt': 'Keycard am {deletionDate} gelöscht',
        'MyCardsPage.showInactive': 'Inaktive Keycards einblenden',
        'MyCardsPage.confirmKeycardDeletion':
            'Die Keycard wird unwiderruflich gelöscht und kann nicht mehr zur Onlinebuchung genutzt werden\n',
        'MyCardsPage.verify': 'Promotion Code eingeben',
        'MyCardsPage.verifiedCodes': 'Promotion codes',

        'MyOrdersPage.headline': 'Meine Bestellungen',
        'MyOrdersPage.invoice': 'Zahlungsbestätigung',
        'MyOrdersPage.invalidInvoice':
            'Für diese Bestellung liegt keine Rechnung vor.',

        'MyOrdersPage.receiptSAsPdf': 'PDF Download',

        'CreditCardsPage.headline': 'Gespeicherte Kreditkarten',
        'CreditCardsPage.confirmProfileDeletion':
            'Kreditkarte wirklich löschen?',
        'CreditCardsPage.noDataFound':
            'Keine Kreditkarten gespeichert. Du kannst bei der nächsten Bestellung die Kreditkarten speichern und hier verwalten.',

        'Profile.enterYourPassword': 'Gib hier dein Passwort ein',
        'Profile.oldPassword': 'Altes Passwort',
        'Profile.newPassword': 'Neues Passwort',
        'Profile.passwordRepeat': 'Passwort wiederholen',
        'Profile.changeEmailActivation':
            'E-Mail Änderung erfolgreich. Um die Änderung abzuschließen, klicke auf den Link in der E-Mail, die du gerade von uns bekommen hast.',
        'Profile.changeEmailError':
            'Fehler beim Ändern deiner E-Mail Addresse. Bitte überprüfe deine Eingabe noch einmal.',
        'Profile.myData': 'Meine Daten',
        'Profile.addMoreLogins': 'Weitere Logins hinzufügen',
        'Profile.lastLogin': 'Zuletzt eingeloggt am',
        'Profile.starjackLogin': 'starjack.com mit E-mail und Passwort',
        'Profile.manageLogins': 'Logins verwalten',

        'Profile.myCreditCards': 'Meine Kreditkarten',
        'Profile.accountUpdated': 'Dein Konto wurde aktualisiert',
        'Profile.enterYourEmailAndPassword':
            'Gib hier deine neue E-Mail Addresse und dein bestehendes Passwort ein',
        'Profile.deleteAccount': 'Konto löschen',
        'Profile.confirmAccountDeletion':
            'Willst du dein Konto wirklich löschen?',
        'Profile.deleteAccountError':
            'Dein Konto konnte nicht gelöscht werden. Bitte prüfe dein Passwort.',
        'Profile.newsletter': 'Newsletter',
        'Profile.saveChanges': 'Änderungen speichern',
        'Profile.errorChangeEmailFetchUser':
            'Fehler beim Abrufen des Benutzers. Bitte versuchen Sie es später noch einmal',

        'Newsletter.title': 'Newsletter Anmeldung',
        'Newsletter.de':
            'BEST NEWSLETTER EVER!<br/> Ja okay, vielleicht übertreiben wir ein wenig. Aber wir versprechen dir:<br/>Kein Spam! Du erhältst von uns nur relevante Infos rund um den Online-Buchungsservice und deine grandiosen Möglichkeiten mit deiner starjack-Keycard.',
        'Newsletter.en':
            "BEST NEWSLETTER EVER!<br/> Yeah okay, maybe we're exaggerating a little. But we promise you:<br/>no spam! You will only receive relevant information from us about the online booking service and your great options with your starjack keycard.",

        'PasswordForgottenPage.headline': 'Passwort vergessen',

        'PasswordForgottenResetPage.headline': 'Passwort zurücksetzen',

        'RegisterPage.text':
            'Deine Registrierung war erfolgreich.<br/>Wir haben dir eine E-Mail mit einem Aktivierungslink gesendet.<br/>Bitte öffne die E-Mail und schließe die Registrierung ab.<br/>',

        'RegisterPage.gtcCheckbox.portal.label':
            'Ich stimme den <a href="/gtc">AGB</a> der Firma SJack GmbH (starjack.com) zu und habe die <a href="{{statusinfo-pdf-link}}">Statusinformationen</a> für Versicherungsvermittler sowie die Datenschutzhinweise gelesen.',

        'RegisterPage.gtcCheckbox.integration.label':
            'Ich stimme den <a href="/gtc">AGB</a> der Firma SJack GmbH (starjack.com) zu.',

        'MyAccountNavigation.myCards': 'Meine Keycards',
        'MyAccountNavigation.myTickets': 'Tickets',
        'MyAccountNavigation.myOrders': 'Meine Bestellungen',
        'MyAccountNavigation.myInsurances': 'Meine Versicherungen',
        'MyAccountNavigation.insuranceProduct':
            'Versicherung Safety+ / Refund+',
        'MyAccountNavigation.myFavorites': 'Meine Favoriten',
        'MyAccountNavigation.buyTickets': 'TIckets kaufen',
        'MyAccountNavigation.myData': 'Meine Daten',
        'MyAccountNavigation.changeCredentials': 'Zugangsdaten',
        'MyAccountNavigation.creditCardProfiles': 'Kreditkarten',
        'MyAccountNavigation.favorites': 'Favoriten',
        'MyAccountNavigation.coupon': 'Gutscheine',
        'MyAccountNavigation.logout': 'Abmelden',
        'MyAccountNavigation.login': 'Anmelden',
        'MyAccountNavigation.register': 'Registrieren',
        'MyAccountNavigation.backToRegistration': 'Zurück zur Registerierung',
        'MyAccountNavigation.credit': 'Abzüglich Guthaben',
        'MyAccountNavigation.redeemVoucher': 'Guthaben abrufen / einlösen',
        'MyAccountNavigation.redeemVoucher2': 'Guthaben einlösen',
        'MyAccountNavigation.faq': 'Faq',
        'MyAccountNavigation.reportClaim': 'Schaden melden',

        'CheckoutAddressPage.headline': 'Liefer- und Rechnungsadresse',

        'keycard.status.ORDERED': 'Bestellt',
        'keycard.status.ORDERED_INTERNAL': 'Bestellt',
        'keycard.status.PRODUCED': 'Produziert und versendet',
        'keycard.status.NEW': 'Bestellt',
        'keycard.status.LOCKED': 'Gesperrt',
        'keycard.external': 'Manuell eingegebene Keycard',

        'CheckoutKeyCardForm.foto':
            'Wir empfehlen, ein Foto hochzuladen, auf dem das Gesicht deutlich erkennbar ist: ohne Sonnenbrille etc.- damit du auch fotopflichtige Tickets buchen kannst.',
        'CheckoutKeyCardForm.fotoDelete': 'Foto löschen ',
        'CheckoutKeyCardForm.subline1':
            'Es ist erforderlich ein Foto einzufügen, um auch fotopflichtige Tickets buchen zu können. ',
        'CheckoutKeyCardForm.subline2':
            'Bitte beachten: Foto kann nach der Bestellung nicht mehr hinzugefügt werden! ',
        'CheckoutKeyCardForm.subline1external':
            'Wenn auf der Keycard, die Sie hier registrieren, kein aktuelles Foto aufgedruckt ist, empfehlen wir eine starjack Keycard mit Fotodruck zu bestellen. Wenn Sie ein Foto auf der Keycard, die Sie hier registrieren, aufgedruckt haben, müssen Sie dieses zusätzlich auch digital hinterlegen um fotopflichtige Tickets buchen zu können.',
        'CheckoutKeyCardForm.subline2external':
            'Der Grund sind eventuelle Skipass-Kontrollen im Skigebiet,  insbesondere bei Buchung von Mehrtages- und Saisonkarten! ',

        'CheckoutKeyCardForm.clickHere': 'Bild hochladen',
        'CheckoutKeyCardForm.dragDropText':
            'Neue Datei hier hereinziehen oder hier klicken',

        'CheckoutTicketsPage.redeemVoucherButton': 'Guthaben einlösen',
        'CheckoutTicketsPage.receiveKeyCard':
            'Wie möchtest du dein Ticket buchen?',
        'CheckoutTicketsPage.born': 'Geboren',
        'CheckoutTicketsPage.bornOn': 'Geboren {from} - {to}',
        'CheckoutTicketsPage.chooseCard': 'Karte wählen',
        'CheckoutTicketsPage.newKeyCardOption': 'Neue Keycard bestellen',
        'CheckoutTicketsPage.newExternalKeyCardOption':
            'Vorhandene Keycard registrieren',
        'CheckoutTicketsPage.ignoreError':
            'Ticket trotzdem bestellen und auf die Keycard buchen',
        'CheckoutTicketsPage.barcodeOption':
            'Print@home-Ticket: Keine Keycard zur Buchung notwendig. Per Email erhältst du dein Barcode-Ticket für den direkten Zugang bei der gebuchten Bergbahn / Freizeiteinrichtung. Ausdruck empfohlen.',
        'CheckoutTicketsPage.internalProductionOption':
            'KeyCard Produktion und Versand durch die Bergbahn: Keine Keycard zur Buchung notwendig. KeyCard inkl. Ticket erhältst du automatisch direkt von der Bergbahn per Post an deine angegebene Adresse zugeschickt.',
        'CheckoutTicketsPage.pickupOption':
            'Abholung Automat oder Kassa: Keine KeyCard zur Buchung notwendig. Per E-Mail erhältst du einen Abholungscode zum Ausdrucken, mit welchem du deine KeyCard inkl. Ticket im gebuchten Skigebiet bei der Kasse oder am Ticketautomaten abholst.',
        'CheckoutTicketsPage.mobileFlowBookable':
            'Als Smartphone-Ticket buchbar',

        'ConfirmEmailPage.headline': 'E-Mail Änderung',
        'ConfirmEmailPage.success':
            'Die E-Mail Adresse wurde erfolgreich geändert.',
        'ConfirmEmailPage.error':
            'Bei der Änderung der E-Mail Adresse ist ein Fehler aufgetreten.',

        'HomePageWinter.headline':
            'Konto anlegen - Keycard registrieren <br/> (Ski)tickets online buchen',
        'HomePageWinter.text':
            '… und du kannst direkt zum Lift gehen, ohne vorher an die Kasse zu müssen.<br/> Wenige Sekunden nach der Ticketbuchung bekommst du eine Bestätigung per E-Mail und dein (Ski)Ticket ist schon einsatzbereit.',
        'HomePageWinter.subText':
            'Keine Keycard zur Hand? Dann hol dir deine persönliche starjack-Keycard nach Hause:',
        'HomePage.getKeycard': 'Jetzt Keycard bestellen',
        'HomePage.headline':
            'Mit 1 Account Tickets für 100+ Bergbahnen online buchen',
        'HomePage.subline':
            'Ob Skitag, Rodelausflug oder Winterwanderung – mit starjack bist du flexibel, denn du buchst dein (Ski)Ticket in Sekundenschnelle und kannst es auch gleich nutzen. Vom Stundenticket bis zur Jahreskarte: Schau gleich nach, welche Tickets dir die Skigebiete online anbieten:',
        'HomePage.skiResortsHeadline':
            'EINE KEYCARD FÜR {regions} SKIREGIONEN​',
        'HomePage.skiResortsText':
            'Weil du die Berge liebst und erleben willst. Kompromisslos stressfrei, ohne an den Ticketkassen auf das „Go“ zu warten. Und weil das auch unser Anspruch ist, gibt es starjack. Du buchst flexibel deine Skipässe online und gehst direkt zum Lift, kurz nach dem du den „Bestellen-Button“ gedrückt hast. Alles, was du dazu brauchst, ist deine persönliche starjack Keycard.',
        'HomePage.advantagesHeadline':
            'Dein Vorteil beim Online-Ticketbuchen mit starjack',
        'HomePage.advantagesText1':
            '<ul>' +
            '<li>1 Keycard – 1 Account – Skipässe für {regions} Skigebiete online buchen. Wann und wo du willst.</li>' +
            '<li>Einfache Echtzeitbuchung: online in deinen starjack Account einloggen, Destination wählen, Ticket in den Warenkorb legen, zahlen und….sofort auf die Piste!</li>' +
            "<li>Öffnungszeiten oder Warteschlangen an Ticketkassen gibt's für dich nicht mehr!</li>" +
            '</ul>',
        'HomePage.advantagesText2':
            '<ul>' +
            "<li>Ein Account für viele Personen - in deinem starjack Account verwaltest du die Keycards und Buchungen für deine Familie, Freunde, Pistenbuddy's…</li>" +
            '<li>Deine starjack Keycard(s) erhältst du bequem per Post nach Hause und kannst diese viele Jahre lang beliebig oft online mit Tickets aufladen.</li>' +
            '<li>Du bist eher so der Frühbucher-Typ? Fein, dann buche mit starjack deine Skipässe bequem zuhause auf der Couch und starte völlig entspannt in deinen Skiurlaub.</li>' +
            '</ul>',
        'HomePage.voucherHeadline':
            'Verschenke echte Bergerlebnisse und pure Gipfelfreude – <br/> mit einem starjack-Gutschein.',
        'HomePage.voucherText':
            'Die Liebeserklärung für alle Bergsportler und Alpenliebhaber, denn der Gutschein kann für jedes auf starjack.com angebotene Bergerlebnis online eingelöst werden.<br/> <br />Das heißt freie Destinationswahl, freie Ticketwahl und ein stressfreier Zugang zum Bergabenteuer.',
        'HomePage.voucherSubText':
            'Der Beschenkte benötigt lediglich ein starjack-Konto und ggf. eine Keycard.',
        'HomePage.voucherLink': 'Gutschein erstellen',

        'HomePage.sjAppTitle': 'Deine starjack-App',
        'HomePage.sjAppText':
            'Keycard in der Skijacke, starjack-App am Smartphone – und du bist an deinen Skitagen in Sekundenschnelle dort, wo du hingehörst – auf der Piste.' +
            '<br/><br/>' +
            'Mit der starjack App buchst du noch bequemer deine (Ski)Tickets. Denn alle Daten, die du bei einer Bestellung eingibst, werden in deinem Webshop-Konto gespeichert und stehen dir für zukünftige Buchungen mit einem Klick zur Verfügung. So buchst du in der starjack-App deine Tickets in Sekundenschnelle.' +
            '<br/><br/>' +
            'Hol dir jetzt die kostenlose starjack-App und lass die Warteschlangen an den Ticketkassen hinter dir!',

        'HomePageSummer.headline':
            'Ski - Biken - Hiken <br /> buche deine Lifttickets online',
        'HomePageSummer.subline':
            'Ob Biken, Wandern oder Skifahren am Gletscher - mit starjack bist du flexibel, denn du buchst dein Ticket in Sekundenschnelle und kannst es auch gleich nutzen. Vom Stundenticket bis zur Jahreskarte: Schau gleich nach, welche Tickets dir die Bergbahnen online anbieten',
        'HomePageSummer.textHeadline':
            'Konto anlegen • Keycard bestellen oder registrieren •<br />Tickets online buchen',
        'HomePageSummer.text':
            '...und du kannst direkt zum Lift gehen, ohne zu warten. \nWenige Sekunden nach der Ticketbuchung bekommst du eine Bestätigung per E-Mail und dein (Ski)ticket ist schon einsatzbereit.',
        'HomePageSummer.subText':
            'Keine Keycard zur Hand? Dann hol dir deine persönliche starjack-Keycard nach Hause:',
        'HomePageSummer.getKeycard': 'Jetzt Keycard bestellen',
        'HomePageSummer.skiResortsHeadline':
            'EINE KEYCARD FÜR {regions} SKIREGIONEN​',
        'HomePageSummer.skiResortsText':
            'Weil du die Berge liebst und erleben willst. Kompromisslos stressfrei, ohne an den Ticketkassen auf das „Go“ zu warten. Und weil das auch unser Anspruch ist, gibt es starjack. Du buchst flexibel deine Skipässe online und gehst direkt zum Lift, kurz nach dem du den „Bestellen-Button“ gedrückt hast. Alles, was du dazu brauchst, ist deine persönliche starjack Keycard.',
        'HomePageSummer.advantagesHeadline':
            'Profitiere von den vielen Vorteilen',
        'HomePageSummer.advantagesText1':
            '<ul>' +
            '<li>1 Keycard – 1 Account – Skipässe für {regions} Skigebiete online buchen. Wann und wo du willst.</li>' +
            '<li>Einfache Echtzeitbuchung: online in deinen starjack Account einloggen, Destination wählen, Ticket in den Warenkorb legen, zahlen und….sofort auf die Piste!</li>' +
            "<li>Öffnungszeiten oder Warteschlangen an Ticketkassen gibt's für dich nicht mehr!</li>" +
            '</ul>',
        'HomePageSummer.advantagesText2':
            '<ul>' +
            "<li>Ein Account für viele Personen - in deinem starjack Account verwaltest du die Keycards und Buchungen für deine Familie, Freunde, Pistenbuddy's…</li>" +
            '<li>Deine starjack Keycard(s) erhältst du bequem per Post nach Hause und kannst diese viele Jahre lang beliebig oft online mit Tickets aufladen.</li>' +
            '<li>Du bist eher so der Frühbucher-Typ? Fein, dann buche mit starjack deine Skipässe bequem zuhause auf der Couch und starte völlig entspannt in deinen Skiurlaub.</li>' +
            '</ul>',
        'HomePageSummer.voucherHeadline':
            'Verschenke echte Bergerlebnisse und pure Gipfelfreude – <br/> mit einem starjack-Gutschein.',
        'HomePageSummer.voucherText':
            'Die Liebeserklärung für alle Bergsportler und Alpenliebhaber, denn der Gutschein kann für jedes auf starjack.com angebotene Bergerlebnis online eingelöst werden.<br/>Das heißt freie Destinationswahl, freie Ticketwahl und ein stressfreier Zugang zum Bergabenteuer.',
        'HomePageSummer.voucherSubText':
            'Der Beschenkte benötigt lediglich ein starjack-Konto und ggf. eine Keycard.',
        'HomePageSummer.voucherLink': 'Gutschein erstellen',

        'HomePageSummer.sjAppTitle': 'Deine starjack-App',
        'HomePageSummer.sjAppText':
            'Die starjack-App ist dein ganzjähriger, direkter Zugang zu mehr als 100 Bergbahnen & Bikeparks in Österreich und Deutschland. Mit wenigen Klicks und in Echtzeit buchst du mobile deine (Ski-)Tickets, sodass du bereits wenige Sekunden nach Bestellabschluss direkt zum Lift gehen kannst.' +
            '<br/><br/>' +
            'Buche dein Ticket, wann und wo du willst mit der starjack-App.' +
            '<br/><br/>' +
            'Hol dir jetzt die kostenlose starjack-App und lass die Warteschlangen an den Ticketkassen hinter dir.',

        'HomePageSummer.oneCardForAll':
            '1 Keycard zur Ticketbuchung von mehr als 100 Bergbahnen',
        'HomePageSummer.keycardExplanation':
            'Kostenloses starjack-Konto anlegen, deine Keycard(s) darüber bestellen / registrieren und schon kannst du die Angebote aller Bergbahnen direkt auf die Keycard buchen – kontaktlos und sekundenschnell.',

        'HomePageSummer.ticketsUsable': 'Dein Ticket ist sofort einsatzbereit',
        'HomePageSummer.ticketsUsableExplanation':
            'Dein bei starjack gebuchtes Ticket ist wenige Sekunden nach der Bestellung schon aktiviert (Bestätigung per E-Mail) und du kannst direkt zum Lift gehen, ohne zu warten.',

        'HomePageSummer.accountForFamilyAndFriends':
            '1 Webshop-Konto für alle Freunde & Familie',
        'HomePageSummer.accountForFamilyAndFriendsExplanation':
            'In deinem starjack-Konto kannst du beliebig viele Keycards durch Registrieren oder Bestellen hinterlegen und Tickets darauf buchen. Ideal für Familien.',
        'HomePageSummer.orderOrRegisterKeycard':
            'Alten Skipass zur Onlinebuchung registrieren ',
        'HomePageSummer.orderOrRegisterKeycardExplanation':
            'Du hast noch eine alte Keycard zuhause? Registriere diese in deinem Webshop-Konto und du kannst sofort Tickets darauf buchen – so oft du willst. Alternativ kannst du eine starjack-Keycard bestellen.',

        'HomePageSummer.keycardsReusable':
            'Ticketbuchung auch ohne Keycard möglich',
        'HomePageSummer.keycardsReusableExplanation':
            'Unsere Bergbahnen bieten, je nach deren Möglichkeiten, auch Buchungsoptionen ohne Keycard an. Als PickUp-Voucher zur Abholung vor Ort oder als elektronisches Zutrittsticket (e-Ticket) auf dein Handy - einfach Ticket in den Warenkorb legen und Buchungsoptionen ansehen.',

        'HomePageSummer.keycardsUnlimited':
            'Keycards wiederaufladbar mit Tickets',
        'HomePageSummer.keycardsUnlimitedExplanation':
            'Über dein starjack-Konto kannst du beliebig oft Tickets auf deine Keycard aufbuchen. Die starjack-Keycard kannst du mehrere Jahre hinweg nutzen.',
        'HomePageSummer.ticketReturn': 'Rückgabe deines Tickets möglich',
        'HomePageSummer.ticketReturnExplanation':
            'Tickets, die im Onlineverkauf mit dem Hinweis „nicht stornierbar“ ausgewiesen sind, können nicht mehr storniert werden. Alle anderen ungenutzten Tickets & Voucher kannst du bis 8 Uhr am ersten Gültigkeitstag bzw. 1 Stunde nach Bestellabschluss zurückgeben und erhältst den Ticketwert als Guthaben in dein Benutzerkonto gebucht.',
        'HomePageSummer.sjApp': 'starjack-App',
        'HomePageSummer.sjAppExplanation':
            'Mit der kostenfreien starjack-App geht das mobile Ticketbuchungen noch schneller, noch komfortabler.',

        'HomePageSummer.enjoyTime': 'Wertvolle Zeit genießen',
        'HomePageSummer.enjoyTimeExplanation':
            'Denn darum geht`s: Um deine genussvolle Zeit! Wir machen das Ticketbuchen für dich einfach und bequem. Wann und wo du willst. Öffnungszeiten oder Warteschlangen an Ticketkassen gibt`s für dich nicht mehr – du bist starjacker!',

        'MyTicketsPage.headline': 'Meine starjack - Tickets',
        'MyTicketsPage.loading': 'Tickets werden geladen...',
        'MyTicketsPage.keycards': 'Keycards:',
        'MyTicketsPage.cancel': 'Ticket zurückgeben und Guthaben erhalten',
        'MyTicketsPage.success':
            'Der Ticketkauf wurde rückgängig gemacht, der Ticketwert wird dir auf dein Guthabenkonto gut geschrieben. Eine Bestätigung erhältst du per E-Mail.',
        'MyTicketsPage.ticket_not_found': 'Ticket nicht vorhanden',
        'MyTicketsPage.ticket_already_billed':
            'Ticket konnte nicht mehr storniert werden',
        'MyTicketsPage.ticket_already_canceled': 'Ticket bereits storniert',
        'MyTicketsPage.ticket_not_cancelable': 'Ticket nicht stornierbar',
        'MyTicketsPage.could_not_cancel_ticket':
            'Ticket konnte nicht storniert werden',

        'MyTicketsPage.insuranceAdded.chip.label': 'Schutz gebucht',
        'MyTicketsPage.insuranceNotAdded.chip.label': 'Schutz nicht gebucht',

        'MyTicketsPage.cancelDialog.ticketWithoutInsurance':
            'Ticket stornieren',
        'MyTicketsPage.cancelDialog.ticketWithInsurance':
            'Mein Ticket inkl. Versicherung stornieren',

        'MyTicketsPage.cancelDialog.body.ticketWithInsurance':
            'Möchtest du dein Ticket inkl. Versicherung wirklich stornieren?',
        'MyTicketsPage.cancelDialog.body.ticketWithoutInsurance':
            'Möchtest du dein Ticket wirklich stornieren?',

        'MyTicketsPage.cancelDialog.caption.ticketWithInsurance': `
            Mit dem Klick auf „Stornieren“ bestätigst du, dass der Betrag auf deinem Webshop-Konto gutgeschrieben wird. 
            Wenn du eine Auszahlung auf dein gewähltes Zahlungsmittel wünschst, sende bitte eine E-Mail an  <a href='mailto:support@starjack.com'>support@starjack.com</a>
            mit deiner Ticket- und Versicherungsscheinnummer/Polizzennummer oder rufe unter der Telefonnummer 
            <a href='tel:+43624673430999'>+43624673430999</a> an. 
            Wir überweisen den Betrag dann auf dein gewähltes Zahlungsmittel zurück. 
            Für eine Rückbuchung des Ticket-Geldbetrages verrechnen wir einen Unkostenbeitrag. 
            Mehr Informationen gibt es in unseren <a href='/faq'>FAQs</a>.
        `,

        'MyTicketsPage.cancelDialog.success': 'Ticket erfolgreich storniert.',

        'ticketStatus.REFUNDED': 'Ticket storniert und Betrag rückerstattet',
        'ticketStatus.PAID': 'Ticket bezahlt',
        'ticketStatus.PRODUCED': 'Ticket wurde auf Keycard aufgebucht',
        'ticketStatus.NEW': 'Ticket neu',
        'ticketStatus.ORDERED': 'Ticket bestellt',

        'MyFavoritesPage.headline': 'Favoriten',
        'MyFavoritesPage.noFavoritesFound': 'Keine Favoriten vorhanden',

        'Favorites.addFavorite': 'Favorit hinzufügen',
        'Favorites.removeFavorite': 'Favorit entfernen',

        'CouponPage.headline': 'Coupon einlösen',
        'CouponPage.redeem': 'Einlösen',
        'CouponPage.code': 'Coupon Code',
        'CouponPage.coupon_code_not_found': 'Code ungültig',
        'CouponPage.coupon_already_used': 'Code bereits verwendet',
        'CouponPage.coupon_code_expired': 'Code abgelaufen',
        'CouponPage.coupon_code_not_yet_valid': 'Code ist noch nicht gültig',

        'CouponPage.coupon_code_over_contingent': 'Code nicht mehr verfügbar',
        'CouponPage.success': 'Coupon Code erfolgreich eingelöst',

        'VoucherRedeemPage.headline': 'Gutschein kaufen',
        'VoucherRedeemPage.redeem': 'Einlösen',
        'VoucherRedeemPage.code': 'Gutschein Code',
        'VoucherRedeemPage.success': 'Voucher Gutschein erfolgreich aufgebucht',
        'VoucherRedeemPage.voucher_not_found': 'Gutschein ungültig',
        'VoucherRedeemPage.voucher_not_paid': 'Gutschein ungültig',
        'VoucherRedeemPage.voucher_already_redeemed':
            'Gutschein bereits eingelöst',

        'CardTransferPage.headline': 'Keycard übertragen',
        'CardTransferPage.targetEmail': 'Empfänger E-Mail',
        'CardTransferPage.transfer': 'Keycard übertragen',

        'CardTransfer.accept': 'Akzeptieren',
        'CardTransfer.reject': 'Ablehnen',
        'CardTransfer.email': 'E-Mail',
        'CardTransfer.firstname': 'Vorname',
        'CardTransfer.lastname': 'Nachname',
        'CardTransfer.birthday': 'Geburtsdatum',
        'CardTransfer.rejected': 'Übertragung abgebrochen',

        'KeycardTransferAlert.newTransfer':
            'Eine Keycard Übertragung wartet auf deine Bestätigung. Hier klicken.',

        'CreditPage.headline': 'Guthaben',
        'CreditPage.creditInfo':
            'Dein aktuelles Guthaben beträgt {balance}. Guthaben werden bei Bestellungen automatisch vorrangig abgezogen.',

        'VoucherPage.headline': 'Gutschein',
        'VoucherPage.description':
            'Wähle eine Gutscheinvorlage und vollende diese mit deinen persönlichen Worten. Deinen Gutschein erhältst du sofort per E-Mail zum Ausdrucken.',
        'VoucherPage.descriptionListTitlePortal':
            'So kannst du den Gutschein auf starjack.com einlösen:',

        'VoucherPage.descriptionListTitleWebshop':
            'So kannst du den Gutschein in unserem Webshop einlösen:',

        'VoucherPage.descriptionListPortal':
            '1. Melde dich mit deinem starjack-Konto an oder registriere dich neu <br>' +
            '2. Gehe in deinem Konto auf „Guthaben abrufen/einlösen“  <br>' +
            '3. Gib deinen Gutscheincode in das vorgesehene Feld ein – somit ist der Gutscheinwert als Guthaben in deinem Webshop-Konto. Das Guthaben wird automatisch für deine nächste(n) Zahlung(en) verwendet, bis es aufgebraucht ist. <br>' +
            'Jedes auf starjack.com angebotene Onlineticket kannst du damit buchen.',

        'VoucherPage.descriptionListWebshop':
            '1. Melde dich mit deinem starjack-Konto an oder registriere dich neu <br>' +
            '2. Gehe in deinem Konto auf „Guthaben abrufen/einlösen“  <br>' +
            '3. Gib deinen Gutscheincode in das vorgesehene Feld ein – somit ist der Gutscheinwert als Guthaben in deinem Webshop-Konto. Das Guthaben wird automatisch für deine nächste(n) Zahlung(en) verwendet, bis es aufgebraucht ist. <br>' +
            'Jedes in unserem Webshop angebotene Onlineticket kannst du damit buchen.',

        'VoucherPage.amount': 'Betrag',
        'VoucherPage.sender': 'Von',
        'VoucherPage.receiver': 'An',
        'VoucherPage.title': 'Titel',
        'VoucherPage.text': 'Text',
        'VoucherPage.preview': 'Vorschau',
        'VoucherPage.previewGenerating': 'Vorschau wird generiert....',
        'VoucherPage.back': 'Zurück',
        'VoucherPage.cart': 'in den Warenkorb',
        'VoucherPage.notnullvoucherTemplateId':
            'Wähle aus einer Gutschein Vorlage aus',
        'VoucherPage.notemptytitle': 'Der Titel muss befüllt sein',
        'VoucherPage.notemptytext': 'Der Text muss befüllt sein',
        'VoucherPage.notemptysender': 'Bitte gib einen Absender ein',
        'VoucherPage.senderError': 'Bitte gib eine valide Email Adresse ein',
        'VoucherPage.notemptyreceiver': 'Bitte gib einen Empfänger ein',
        'VoucherPage.receiverError': 'Bitte gib eine valide Email Adresse ein',
        'VoucherPage.maxtitle': 'Der Titel ist zu lang',
        'VoucherPage.maxtext': 'Der Text ist zu lang',
        'VoucherPage.lengthsender': 'Von ist zu lang',
        'VoucherPage.lengthreceiver': 'An ist zu lang',
        'VoucherPage.minamount': 'Der Betrag muss mindestens € 5,00 sein',
        'VoucherPage.invalid_requestundefined':
            'Bitte befülle alle Felder korrekt',

        'VoucherPage.fillVoucher': 'Gutschein befüllen',
        'VoucherPage.viewDetail': 'Gutscheindaten überprüfen',
        'VoucherPage.editVoucher': 'Gutschein bearbeiten',
        'VoucherPage.previewTile': 'Gutscheinvorschau',
        'VoucherPage.selectTemplateTitle': 'Wähle eine Gutscheinvorlage aus',
        'VoucherPage.errorAddingVoucher':
            'Fehler beim hinzufügen des Gutscheins. Bitte versuchen Sie es später noch einmal',

        'VoucherDownloadPage.headline': 'Gutschein Download',
        'VoucherDownloadPage.download': 'Gutschein generieren und laden',
        'VoucherDownloadPage.downloadInProgress':
            'Gutschein wird generiert und geladen...',

        'LinkExternalCardPage.description':
            'Jede manuell registrierte Keycard darf nur von der Person zur OnlineTicketbuchung ' +
            'genutzt werden, deren persönliche Daten Sie hier hinterlegen.<br/><br/>' +
            'Geben Sie hier die persönlichen Daten derjenigen Person an, welche die Keycard zur Onlinebuchung nutzen wird.<br/><br/>' +
            '<b>Falschangaben können zur Ticketungültigkeit führen.</b>',

        'LiftoperatorSelector.placeholder': 'Ort oder Namen der Bergbahn',
        'LiftoperatorSelector.sectionLiftoperators':
            'Zurück zu allen Bergbahnen',
        'LiftoperatorSelector.sectionLocations': 'Orte',
        'LiftoperatorSelector.showAll': 'Alle anzeigen',

        'ErrorPage.pageNotFound': 'Seite nicht gefunden',
        'ErrorPage.loginRequired': 'Anmeldung erforderlich',
        'ErrorPage.error': 'Fehler',
        'ErrorPage.pageNotFoundDescription':
            'Die Seite ist nicht vorhanden. Bitte versuche es erneut.',
        'ErrorPage.loginRequiredDescription':
            'Für die Seite, die du öffnen wolltest, ist eine Anmeldung erforderlich.',
        'ErrorPage.errorDescription':
            'Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut.',
        'ErrorPage.back': 'Zurück',
        'ErrorPage.home': 'zur Startseite',
        'ErrorPage.login': 'zur Anmeldung',

        'IncertVoucherRedemption.incertVoucherCode':
            'Incert voucher code eingeben',
        'IncertVoucherRedemption.inputLabel':
            'Gib hier die Barcode-Nummer deines Gutscheins ein',
        'IncertVoucherForm.tooltip':
            'Der Gutscheinwert wird nach drücken des "Bezahlen-Buttons" von der Bestellsumme abgezogen. Wenn der Gutscheinwert höher ist als deine Bestellsumme, bleibt die Differenz auf deinem Gutschein bestehen.',
        'IncertVoucherForm.success': 'Incert Gutschein erfolgreich eingelöst',

        bootstrapTableOptions: {
            noDataText: 'Keine Daten vorhanden',
            insertText: 'Neu',
            sizePerPage: 25,
        },

        'Cookie.title': 'Überraschung! Cookies :-)',
        'Cookie.dataPrivacyMessage':
            'Unsere Seite verwendet Cookies, und zwar nicht nur unsere, sondern auch die von Dritten. Diese süßen kleinen Textdateien sind notwendig, um euch ein möglichst bequemes und auch sonst großartiges Buchungserlebnis bieten zu können. Deshalb; Cookies ganz nach eigenen Vorlieben auswählen und frohen Mutes AKZEPTIEREN geklickt (garantiert kalorienfrei) - schon kann es losgehen!',
        'Cookie.dataPrivacyLabel':
            'Weitere Infos zu unseren Cookies findest du hier',
        'Cookie.buttonLabelSelection': 'Auswahl akzeptieren',
        'Cookie.buttonLabelAll': 'Cookies akzeptieren',
        'Cookie.necessaryCookies': 'Notwendige Cookies',
        'Cookie.statisticCookies': 'Statistik Cookies',
        'Cookie.necessaryExplanation':
            'Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.',
        'Cookie.statisticExplanation':
            'Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.',
        'Cookie.buttonLabel': 'OK',

        'VerifyForm.verify': 'Promotion Code einreichen',
        'Verify.header': 'Promotion Code eingeben',
        'VerifyForm.code': 'Code',
        'Verify.message':
            'Gib hier deinen starjack Promotion Code ein, gehe anschließend zu den Angeboten der Bergbahn, welche dir den Code zur Verfügung gestellt hat und schau dir die Angebote an. Ein Promotion Code berechtigt zur einmaligen Buchung des Promotion Angebotes auf die untenstehende Keycard.',
        'validation.error.code.required': 'Code ist ein Pflichtfeld',
        'validation.error.verificationFailed':
            'Der eingegebene Bergbahncode ist ungültig',
        'validation.error.linkExternalKeycard.token_expired':
            'Token ist abgelaufen',
        'validation.error.checkout.shipping_costs_do_not_match':
            'Lieferkosten stimmen nicht überein',
        'validation.error.checkout.notnull':
            'Bitte prüfe deinen Warenkorb und korrigiere die Eingabe in den rot umrandeten Feldern',
        'validation.error.checkout.invalid_request':
            'Bitte prüfe deinen Warenkorb und korrigiere die Eingabe in den rot umrandeten Feldern',

        'ExternalKeycardForm.success':
            'Externe Keycard registrieren erfolgreich',
        'NewKeycardForm.success': 'Keycard registrieren erfolgreich',
        'LinkExternalKeycard.toCheckout': 'Zur Kasse gehen',
        'LinkExternalKeycard.toTickets': 'zu den Ticketangeboten',

        tomorrow: 'morgen',
        today: 'heute',

        'Header.login': 'Anmelden',
        'Header.register': 'Registrieren',
        'Header.myKeycards': 'Meine Keycards',
        'Header.myData': 'Meine Daten',
        'Header.myTickets': 'Meine Tickets',
        'Header.myOrders': 'Meine Bestellungen',
        'Header.changePassword': 'Passwort ändern',
        'Header.manageCreditCards': 'Kreditkarten',
        'Header.manageCredit': 'Guthaben',
        'Header.couponManagement': 'Coupon einlösen',
        'Header.redeemCoupon': 'Gutschein einlösen',
        'Header.faq': 'FAQs',
        'Header.logout': 'Abmelden',

        'Error.required': 'Pflichtfeld',
        'ProductInfoModal.pictureRequired':
            'Zur Buchung dieses Tickets ist ein digitales Foto Voraussetzung; Auf deiner Keycard oder, falls aktiviert, als Upload im Bestellformular.',

        'Core.dayTicket': 'Tages - Mehrtagesticket',
        'Core.seasonTicket': 'Saison- & Jahreskarten',
        'Core.dayTickets': 'Tages & Mehrtagestickets',
        'Core.seasonTickets': 'Saison- & Jahreskarten',
        'Core.combinationTickets': 'Packages & Familientickets',
        'Core.chooseDate': 'Datum wählen',
        'Core.otherDuration': 'Andere Dauer',
        'Core.day': ' Tag',
        'Core.days': ' Tage',
        'Core.close': ' Schließen',
        'Core.password': 'Passwort',
        'Core.delete': 'löschen',

        'Core.season': 'Saison- & Jahreskarten',
        'Core.validAt': 'Gültig am: ',
        'Core.validFrom': 'Gültig ab: ',
        'Core.validTo': 'Gültig bis',
        'Core.more': 'Mehr',
        'Core.or': 'oder',
        'Core.chose': ' Auswählen',
        'Core.bookableWithoutKeycard': 'Buchung ohne Keycard möglich',
        'Core.photoRequired': 'Fotopflicht',
        'Core.contingent': 'Noch {{tickets}} Tickets buchbar',
        'Core.noContingentLeft':
            'Keine Tickets mehr verfügbar (Kontingent ausgebucht)',
        'Core.addToCart': 'In den Warenkorb',
        'Core.ticketAddedToCart': 'Ticket wurde in den Warenkorb gelegt',
        'Core.bookMoreTickets': 'weiteres Ticket bestellen',
        'Core.toCart': 'Zum Warenkorb',
        'Core.back': 'zurück',
        'Core.deliveryAddress': 'Lieferadresse:',
        'Core.error.deliveryAddress':
            'Bitte gib deine Rechnungs- / Lieferadresse ein',
        'Core.yourDeliveryAddress': 'Deine Rechnungs- / Lieferadresse',
        'Core.totalCost': 'Kosten gesamt',
        'Core.payWith': 'Bezahle mit',
        'Core.redeemCoupon': 'Gutschein einlösen',
        'Core.total': 'Gesamt: ',
        'Core.accountWithBalance': 'Aktueller Guthabenstand {{val}}',
        'Core.balanceWillBeUsed': 'auf und wird vorrangig gebucht.',
        'Core.redeemPromotionCode': 'Promotion Code einlösen',
        'Core.next': 'Weiter',
        'Core.changeDeliveryAddress': 'Lieferadresse ändern',
        'Core.goToPayment': 'Weiter zur Zahlung',
        'Core.cancel': 'Abbrechen',
        'Core.birthdayFrom': 'Geburtstag vom: ',
        'Core.newKeycard': 'Keycard',
        'Core.to': 'bis:',
        'Core.from': 'von',
        'Core.change': 'Ändern',
        'Core.orderKeycard': 'Keycard bestellen',
        'Core.registerExternalKeycard': 'Vorhandene Keycard registrieren',
        'Core.noTicketsFound':
            'Das gewählte Unternehmen bietet derzeit keine Tickets zum Onlinekauf an.',
        'Core.chooseAnother': 'anderes Skigebiet wählen',
        'Core.cancellationBlocked': 'nicht stornierbar',
        'TripLengthModal.headline': 'Andere Dauer Wählen',
        'ExternalKeycard.headline': 'Eine Keycard registrieren ',
        'ExternalKeycard.button':
            'Richtigkeit der Daten bestätigen – Keycard registrieren',
        'ExternalKeycard.explanation':
            'Jede Keycard, deren Seriennummer mit „01-…“ beginnt, kannst du hier registrieren und sofort zur Onlinebuchung nutzen! <br/> <b>Wichtiger Hinweis:</b><br/> Jede manuell registrierte Keycard darf nur von der Person zur Online-Ticketbuchung genutzt werden, deren persönliche Daten hier digital hinterlegt werden. Diese digitalen Daten werden bei einer Ticketbuchung an die Bergbahn übermittelt und haben Verbindlichkeit / Gültigkeit. <br/>Falschangaben können zur Ticketungültigkeit und weiteren rechtlichen Folgen führen (siehe AGBs der Bergbahn, bei welcher du buchst)',
        'ExternalKeycard.processing': 'Karte wird hinzugefügt...',
        'ExternalKeycard.serialNumberExplanation':
            'Gib hier die 23-stellige Seriennummer ein, deren ID mit 01 beginnt - ohne Trennstrich und Leerzeichen.',
        'ExternalKeycard.register': 'Registrieren',
        'RenderImageField.instructions':
            'Es ist erforderlich ein Foto einzufügen, um auch fotopflichtige Tickets buchen zu können. ',
        'NewKeycard.headline': 'Keycard bestellen ',
        'NewKeycard.explanation': `
            <strong>
                Einmal nach Hause bestellen, viele Jahre genießen: Hol dir deine starjack-Keycard und buche deine Tickets ab sofort nur noch online, 
                in Sekundenschnelle - über deinen Account direkt auf deine Keycard. Die starjack Keycard ist personalisiert, daher ist sie nach Bestellabschluss weder änderbar noch stornierbar.
                <br /><br/>Bitte beachte: Die Keycard wird nach Bestellung sofort produziert, bitte plane für den Postversand zu dir ein paar Tage ein!
            </strong>
        `,
        'NewKeycard.order': 'Keycard bestellen',

        'Keycard.pickup':
            'Deinen Abhol-Voucher erhältst du sofort nach Bestellabschluss als PDF per E-Mail. Damit holst du deine Keycard mit dem aufgebuchten Ticket direkt bei der Bergbahn an der Kasse oder, falls vorhanden, am Abholautomaten ab.',
        'Keycard.barcode':
            'Dein eTicket wird dir als PDF an deine E-Mail-Adresse geschickt und kann für den gebuchten Tag / die gebuchten Tage zum direkten Zugang verwendet werden. Gib hier die Daten der Person ein, die das eTicket nutzen wird. Das eTicket ist nicht übertragbar!',
        'Keycard.eTicket':
            'Dein eTicket wird dir als PDF an deine E-Mail-Adresse geschickt und kann für den gebuchten Tag / die gebuchten Tage zum direkten Zugang verwendet werden. Gib hier die Daten der Person ein, die das eTicket nutzen wird. Das eTicket ist nicht übertragbar!',

        'Core.bookKeycard': 'Ticket auf eine Keycard buchen.',
        'Core.bookeTicket':
            'Dein Zugangsticket (eTicket) erhältst du per Email. Ausdruck empfohlen. Keine Keycard zur Buchung notwendig. ',
        'Core.table.rowsPerPage': 'Zeilen pro Seite',
        'Core.bookVoucher':
            'Buchung als Abholticket (keine Keycard notwendig), Zustellung per E-Mail. Nach Vorlage des Vouchers beim gebuchten Unternehmen an der Ticketkasse oder am pickup-Automaten (wenn vorhanden) erhältst du ein Zutrittsticket. ',
        birthday_mismatch:
            'Das Alter des Keycard Inhabers entspricht nicht den Ticket Richtlinien.',
        FEMALE: 'weiblich',
        MALE: 'männlich',
        bad_credentials: 'Login fehlgeschlagen',
        bad_order_status: '',
        'checkoutError.deliveryAddressInvalid':
            'Die Lieferadresse ist noch unvollständig. Bitte ergänze die fehlenden Angaben.',

        'CreditModal.title': 'Gib hier deinen Gutscheincode ein',
        'CreditModal.placeholder': 'Gutscheincode',
        'CreditModal.submit': 'Gutschein einlösen',

        deliveryCost: 'Lieferkosten',
        'Core.buyVoucher': 'Gutschein kaufen',
        'Core.nextHelperText':
            'Bitte wähle pro Ticket eine Produktionsart aus, um mit der Bestellung fortfahren zu können.',
        'Core.invalidBirthday':
            'Ticket kann nur gebucht werden, wenn der Karteninhaber zwischen {expectedFrom} und {expectedTo} geboren ist.',
        'Core.selectDifferentKeycard': 'Keycard ändern',
        'NotFound.headline': '404 Nicht gefunden',
        'NotFound.message': 'Oops! Für diese URL existiert keine Seite.',
        'Core.invalidKeycardAssignment':
            'Diese Keycard ist für die Zuordnung des gewählten Tickets nicht zulässig',
        'Request.error.isocountry': 'Bitte wähle dein Land aus.',
        'Request.error.login': 'Falsche Benutzerdaten. Versuch es noch einmal',
        'Request.error.internal':
            'Es ist ein Fehler aufgetreten. Versuch es später noch einmal',
        'Request.error.contingent':
            'Kontingent ausgeschöpft. Aktuell kann dieses Ticket kann für den ausgewählten Tag nicht mehr gebucht werden',
        'Request.error.internal_incert_error':
            'Der Gutschein kann hier im Warenkorb nicht eingelöst werden. Wenn du einen starjack-Wertgutschein hast, löse diesen im Webshop-Konto, Menü "Guthaben einlösen", ein.',

        'Promotion.enter.aha': 'Gib deinen Bonuscode ein',
        'Promotion.enter.title': 'Bonuscode eingeben',
        'Promotion.enter.ahaText':
            'Jugendliche/Erwachsene (Jg. 2004 – 2008) erhalten mit ihrer AHA CARD eine Ermäßigung von € 30,-. Einfach die Kartennummer zur Prüfung eingeben (wenn Bindestriche vorhanden sind diese auch eingeben). Nach erfolgreicher Prüfung wird das gewählte Ticket mit der Ermäßigung in den Warenkorb gelegt und die Buchung kann abgeschlossen werden.',

        'Promotion.oeamtc.dialog.title': 'Clubkartennummer eingeben',
        'Promotion.oeamtc.enter.label': 'ÖAMTC - Clubkartennummer',
        'Promotion.oeamtc.enter.title': 'ÖAMTC Rabatt Code eingeben',
        'Promotion.oeamtc.enter.body': 'ÖAMTC Input Text',
        'Promotion.oeamtc.enter.redeem': 'ÖAMTC Rabattcode einlösen',
        'Promotion.oeamtc.enter.error':
            'Fehler bei der Aktivierung des ÖAMTC Rabatt',
        'Promotion.oeamtc.enter.error.notavailable':
            'Für dieses Ticket ist kein ÖAMTC Rabatt verfügbar',
        'Promotion.oeamtc.enter.invalidMemberCode':
            'Die ÖAMTC Clubkartennummer ist ungültig',
        'Promotion.oeamtc.enter.error.doubleUsage':
            'Die ÖAMTC Clubkartennummer wurde bereits verwendet',
        'Promotion.oeamtc.enter.success':
            'Eingabe des ÖAMTC Promotions Codes erfolgreich',
        'Promotion.oeamtc.enter.activated': 'ÖAMTC Rabatt aktiviert',
        'Promotion.oeamtc.enter.activate': 'Rabatt anwenden',

        'Promotion.enter.familyText':
            'Vorarlberger und Tiroler Familienpass-Inhaber erhalten beim Kauf einer 3TälerPass-Familienkarte eine Ermäßigung von € 30,- pro Elternteil. Einfach die Kartennummer zur Prüfung eingeben (wenn Bindestriche vorhanden sind diese auch eingeben). Nach erfolgreicher Prüfung wird das gewählte Ticket mit der Ermäßigung in den Warenkorb gelegt und die Buchung kann abgeschlossen werden.',
        'Promotion.enter.error':
            'Keine Buchungsfreigabe für die Ermäßigung. Die Kartennummer konnte nicht zur Buchung der Ermäßigung freigegeben werden. Bitte überprüfe die Eingabe oder wende dich an das 3TälerPass-Büro unter info@3taeler.at oder +43 5512 2365-30.',
        'Promotion.enter.success': 'Rabatt wurde erfolgreich hinzugefügt',
        'Promotion.enter.labelAha': 'AHA - CARD',
        'Promotion.enter.labelFamily': 'Familienpass',
        'Promotion.enter': 'Bonuscode eingeben',
        'Promotion.enter.titleMoenichkirchen':
            'ÖSV, Family Pass NÖ und Family Extra Card Wien',
        'Promotion.enter.moenichkirchen': 'Gib deine Kartennummer ein',
        'Promotion.enter.labelMoenichkirchen': 'Kartennummer',
        'Promotion.enter.errorMoenichkirchen':
            'Die Kartennummer konnte nicht zur Buchung der Ermäßigung freigegeben werden. Bitte überprüfen Sie die Eingabe der Nummer.',
        'Promotion.enter.successMoenichkirchen':
            'Ermäßigung wurde berücksichtigt.',

        'Covid.enter.title':
            'Eingabe Seriennummer der 3Täler Saison- oder Jahreskarte',
        'Covid.enter.text':
            'Einmalig buchungsberechtigt für die Covid-19-Vergütung sind alle Inhaber einer 3Täler Saison- bzw. Jahreskarte 2019/20. Einfach die 20-stellige Seriennummer der Keycard, der Saison- bzw. Jahreskarte 2019/20 zur Prüfung eingeben (die ersten zwei sowie die letzte Ziffer weglassen). Nach erfolgreicher Prüfung wird das gewählte Ticket mit der Covid-19-Vergütung in den Warenkorb gelegt und die Buchung kann abgeschlossen werden.',
        'Covid.enter.label': 'C-Code',
        'Covid.enter.error':
            'Keine Buchungsfreigabe für die Covid-19-Vergütung Die Keycard-Seriennummer konnte nicht zur Buchung der Covid-19-Vergütung freigegeben werden. Bitte überprüfe die Eingabe in 15 Minuten erneut, falls der Code verwendet und der Kauf abgebrochen wurde oder wende dich an das 3TälerPass-Büro unter info@3taeler.at oder +43 5512 2365-30.',
        'Covid.enter': 'Code einlösen',
        'Core.ticket.cancel': 'Möchtest du das Ticket wirklich stornieren?',
        'Core.ticket.cancel.error': 'Fehler beim Stornieren des Tickets',
        'Core.ticket.cancel.title': 'Ticket stornieren',

        'Core.ticket.cancel.button.label': 'Stornieren',
        'Core.ticket.refund.button.label': 'Widerrufen',
        'Core.ticket.notCancelable': 'Ticket nicht stornierbar',
        'Core.backToMain': 'Zurück zur Hauptseite',

        'Keycard.order.existing': 'Bestehende Keycard verwenden',
        'Keycard.order.new': 'Neue Keycard bestellen',
        'Keycard.order.register': 'Keycard registrieren',
        'Keycard.order.no.available':
            'Du hast keine passende Keycard für dieses Ticket. Bitte wähle eine andere Option aus',

        'My.keycards.order.new': '+ Neue Keycard bestellen',
        'My.keycards.order.register': '+ Keycard registrieren',

        'Registration.registerWithFacebook': 'Mit facebook registrieren',
        'Registration.registerWithGoogle': 'Mit Google registrieren',
        'Login.loginWithFacebook': 'Mit Facebook anmelden',
        'Login.loginWithGoogle': 'Mit Google anmelden',

        'Registration.navigation.toLogin': 'Zur Anmeldung',
        'Registration.navigation.back': 'zurück',
        'Registration.error.general':
            'Fehler beim Registrieren. Bitte versuch es später noch einmal',
        'Registration.error.customerAlreadyExists':
            'Unter dieser E-Mail besteht bereits ein Konto',
        'Registration.EMAIL_PASSWORD': 'Mit E-Mail anmelden',

        'Home.oneCardForAll': 'Eine Keycard für hunderte Bergbahnen und Lifte',
        'Home.keycardExplanation':
            'Auf deine (starjack-)Keycard buchst du kontaktlos und sekundenschnell alle Tickets, die dir unsere Bergbahnen online anbieten. Von der Stunden- bis zur Saisonkarte.',

        'Home.ticketsUsable': 'Dein Ticket ist sofort einsatzbereit',
        'Home.ticketsUsableExplanation':
            'Dein bei starjack gebuchtes Ticket ist wenige Sekunden nach der Bestellung schon aktiviert (Bestätigung per E-Mail) und du kannst direkt zum Lift gehen, ohne zu warten.',

        'Home.ticketReturn': 'Rückgabe deines Tickets möglich',
        'Home.ticketReturnExplanation':
            'Dein Ticket kannst du unter bestimmten Bedingungen zurückgeben, insofern es im Webshop nicht als „nicht stornierbar“ ausgewiesen ist. In deinem Webshop-Konto, Menü „meine Tickets“, stehen dir zwei Rückgabeoptionen zur Verfügung, zwischen denen du wählen kannst.',

        'Home.flexible': 'Flexible Buchungsoptionen auch ohne Keycard',
        'Home.flexibleExplanation':
            'Unsere Bergbahnen bieten, je nach deren Möglichkeiten, auch Buchungsoptionen ohne Keycard an; den Pickup Voucher zur Abholung vor Ort oder das elektronische Zutrittsticket (eTicket) für den direkten Liftzugang mit dem Handy. Einfach Ticket in den Warenkorb legen und aus den von der Bergbahn aktivierten Buchungsoption die passende auswählen.',

        'Home.insurance': 'Versicherung Safety+ / Refund+',
        'Home.insuranceExplanation':
            'Wünschen Sie Unfallschutz auf der Piste? Falls etwas passiert, erhalten Sie unter anderem das Geld für Skipass und Skikurs zurück.',

        'Home.oldKeycard': 'Alten Skipass zur Onlinebuchung registrieren',
        'Home.oldKeycardExplanation':
            'Du hast noch eine alte Keycard zuhause? Registriere diese in deinem Webshop-Konto und du kannst sofort Tickets darauf buchen – so oft du willst. Alternativ kannst du eine starjack-Keycard bestellen.',

        'Home.orderOrRegisterKeycard': 'Keycard registrieren oder bestellen',
        'Home.orderOrRegisterKeycardExplanation':
            'Du hast noch eine alte Keycard zuhause? Registriere diese in deinem Konto und du kannst sofort deine Tickets darauf buchen. Alternativ kannst du eine starjack-Keycard bestellen.',

        'Home.keycardsReusable': 'Keycard wiederaufladbar mit Tickets',
        'Home.keycardsReusableExplanation':
            'Über dein starjack-Konto kannst du beliebig oft Tickets auf deine Keycard aufbuchen. Die starjack-Keycard kannst du mehrere Jahre hinweg nutzen.',

        'Home.accountForFamilyAndFriends':
            '1 Webshop-Konto für Freunde & Familie',
        'Home.accountForFamilyAndFriendsExplanation':
            'In deinem starjack-Konto kannst du beliebig viele Keycards durch Registrieren oder Bestellen hinterlegen und Tickets darauf buchen. Ideal für Familien.',

        'Home.sjApp': 'starjack-App',
        'Home.sjAppExplanation':
            'Mit der kostenfreien starjack-App geht das mobile Ticketbuchen noch schneller, noch komfortabler. Für iOS und Android verfügbar.',

        'Home.enjoyTime': 'Wertvolle Zeit genießen',
        'Home.enjoyTimeExplanation':
            'Denn darum gehts: Um deine genussvolle Zeit! Wir machen das Ticketbuchen für dich einfach und bequem. Wann und wo du willst. Öffnungszeiten oder Warteschlangen an Ticketkassen gibts für dich nicht mehr – du bist starjacker!',

        'Promotion.codeAdd': 'Promtion Code erfolgreich hinzgefügt',

        'Keycard.added':
            'Die neue Keycard wurde im Warenkorb erfolgreich hinterlegt',

        'Home.show.allOffers': 'Zeig mir alle Angebote',

        'Core.nextPerson': 'nächste Person',

        'Core.previousPerson': 'vorherige Person',

        'Keycard.option.send': 'Keycard wird zugeschickt',

        'Keycard.option.useExisting': 'Bestehende Keycard verwenden',

        'Keycard.option.pickup': 'Abholung Kasse oder Automat',

        'Keycard.order.explanation':
            'Auch bei der Online-Ticketbuchung braucht jeder Gast eine eigene Keycard. Bestell dir deine starjack-Keycard nach Hause und nutze sie viele Jahre, um deine Tickets online zu buchen. In Sekundenschnelle werden deine online gebuchten Tickets auf deiner Keycard einsatzbereit sein.',

        'Core.invalidLink': 'Ungültiger Link',
        'Core.backToHome': 'Zurück zur Startseite',

        'LocationSearch.location': 'Ort',
        'LocationSearch.liftoperator': 'Bergbahn',

        'GTC.title':
            'Allgemeine Geschäftsbedingungen (AGB) der Onlinebuchungsplattform „Starjack“',
        'GTC.content': 'Test *test <br>',

        'KeycardOption.pickup': 'Als Abhol-Voucher (Pick-Up) bestellen',
        'KeycardOption.barcode': 'Als eTicket bestellen',

        'RequestError.externalKeycardNotAllowed':
            'Tickets des gewählten Unternehmens können leider nicht auf manuell registrierte Keycard gebucht werden.',

        'KeycardTransfer.noTransfers':
            'Zurzeit stehen keine Keycardübertragungen aus',
        'KeycardTransfer.linkToTransfers': 'zur Übertragung',
        'KecardTransfer.waitingTransfer':
            '{{email}} will dir eine Keycard übertragen',

        'Link.backToHome': 'zurück zur Startseite',
        'Ticket.identificationNames': 'Gebucht für',

        'ticketStatus.INTERNAL_PRODUCTION.REFUNDED': 'Ticket storniert',
        'ticketStatus.INTERNAL_PRODUCTION.PAID': 'Ticket bezahlt',
        'ticketStatus.INTERNAL_PRODUCTION.PRODUCED':
            'Ticket wurde auf die Keycard aufgebucht',
        'ticketStatus.INTERNAL_PRODUCTION.NEW': 'Ticket neu',
        'ticketStatus.INTERNAL_PRODUCTION.ORDERED':
            'Ticket bestellt – in Bearbeitung',
        'ticketStatus.INTERNAL_PRODUCTION.CANCELED': 'Ticket storniert',
        'ticketStatus.KEYCARD.REFUNDED':
            'Ticket storniert, Guthaben ins Webshop Konto gebucht',
        'ticketStatus.KEYCARD.PAID': 'Ticket bezahlt',
        'ticketStatus.KEYCARD.PRODUCED':
            'Ticket wurde auf die Keycard aufgebucht',
        'ticketStatus.KEYCARD.NEW': 'Ticket neu',
        'ticketStatus.KEYCARD.ORDERED': 'Ticket bestellt – in Bearbeitung',
        'ticketStatus.KEYCARD.CANCELED': 'Ticket storniert',
        'ticketStatus.PICKUP.REFUNDED':
            'Ticket storniert, Guthaben ins Webshop Konto gebucht',
        'ticketStatus.PICKUP.PAID': 'Ticket Voucher bezahlt',
        'ticketStatus.PICKUP.PRODUCED':
            'Ticket Voucher erstellt und per E-Mail übermittelt',
        'ticketStatus.PICKUP.NEW': 'Ticket Voucher neu',
        'ticketStatus.PICKUP.ORDERED':
            'Ticket Voucher bestellt – in Bearbeitung',
        'ticketStatus.PICKUP.CANCELED': 'Ticket storniert',
        'ticketStatus.BARCODE.REFUNDED':
            'Ticket storniert, Guthaben ins Webshop Konto gebucht',
        'ticketStatus.BARCODE.PAID': 'eTicket bezahlt',
        'ticketStatus.BARCODE.PRODUCED':
            'eTicket erstellt und per E-Mail übermittelt',
        'ticketStatus.BARCODE.NEW': 'eTicket neu',
        'ticketStatus.BARCODE.ORDERED': 'eTicket bestellt – in Bearbeitung',
        'ticketStatus.BARCODE.CANCELED': 'Ticket storniert',
        'ticketStatus.E_TICKET.PRODUCED':
            'Smartphone-Ticket erfolgreich gebucht',

        'keycard.registerNew.error.general':
            'Es gab ein Problem beim Registrieren Ihrer Keycard. Bitte versuchen Sie es später noch einmal',
        'keycard.registerNew.error.invalidSerialNumber':
            'Die angegebene Seriennummer ist nicht gültig',

        'MyTickets.myFavorites': 'Meine Favoriten',
        'MyTickets.ticketsExpired': 'Abgelaufene Tickets',
        'MyTickets.ticketsCurrent': 'Aktuelle Tickets',
        'MyTickets.toTicketsOffer': 'Zu den Ticketangeboten',

        'Core.birthday': 'Geburtsdatum',
        'Core.birthday.dayFormat': 'TT',
        'Core.birthday.monthFormat': 'MM',
        'Core.birthday.yearFormat': 'JJJJ',
        'Core.birthday.invalidBirthday': 'Geburtsdatum ungültig',

        'Keycard.register.success':
            'Ihre Keycard wurde erfolgreich registriert',

        'country.AT': 'Österreich',
        'country.DE': 'Deutschland',
        'country.IT': 'Italien',

        invalid_activation_code:
            'Der Aktivierungslink ist nicht mehr gültig. Bitte registriere dich neu.',

        'salutation.-': '--',
        'salutation.male': 'Herr',
        'salutation.female': 'Frau',

        'core.person': 'Person',
        'core.persons': 'Personen',
        'core.selected': 'gewählt',

        'productSelection.noResult':
            'Mit dieser Filterauswahl sind keine Produkte vorhanden',

        'productCard.info': 'Info zum Produkt',

        'core.buyKeycard': 'Keycard kaufen',

        'Keycard.validityTitle': 'Gültigkeit des Tickets',
        'Keycard.validitySendInformation':
            'Du erhältst die Keycard inkl. Ticket per Post zugeschickt. Die Gültigkeit beginnt somit frühestens ab Erhalt der Keycard.  ',

        'Keycard.validityInformationAccept': 'Akzeptieren und fortfahren',

        'core.contact': 'Kontakt',

        'Oauth.registerLoginTitle': 'Registrieren und Anmelden',
        'Oauth.redirect': 'Sie werden in Kürze weitergeleitet',
        'Oauth.mergeAccountExplanation':
            'Ein Account mit dieser E-Mail Adresse existiert bereits. Der neue Account wird dem bereits bestehenden hinzugefügt',
        'Oauth.mergeAccountButtonLabel': 'Account hinzufügen',

        'core.day': 'Tag',
        'core.days': 'Tage',

        'core.selectNone': 'Beliebig',

        'IdentificationSelect.addKeycard': '+ neue Keycard bestellen',
        'IdentificationSelect.addSuggestion': '+ neue Personendaten anlegen',

        'ProductSelection.chooseDay': 'Anzahl Tage auswählen',
        'Core.singleTicket': 'Einzelticket',
        'Core.packageTicket': 'Packages',

        'Core.selectQuantity': 'Anzahl wählen',

        proceedToCheckout: 'Zur Kasse gehen',

        personIdentSelect: 'Gib die persönlichen Daten ein für:',
        personIdentSelects: 'Gib die persönlichen Daten ein für:',
        personSelectedSuccessful: 'Alle Personen erfolgreich ausgewählt',

        identificationFormButton: 'Speichern und fortfahren',

        INTERNAL_PRODUCTION: 'Neue Keycard',
        KEYCARD: 'Keycard',
        PICKUP: 'Pickup Voucher',
        BARCODE: 'eTicket',
        E_TICKET: 'Handyticket',
        KEYCARDAlternativeType:
            'Auf welche Keycard möchtest du das Ticket buchen?',
        TicketAlternativeType: 'Für wen möchtest du dein Ticket buchen?',
        mobileFlowText:
            '<b>Um das Smartphone-Ticket zu nutzen, brauchst du die key2ski by Skidata App.</b><br/>' +
            'Lade diese zuerst auf dein Smartphone um zu prüfen, ob dein Smartphone kompatibel ist<br/>' +
            '(nur für kompatible Geräte verfügbar). Buche anschließend dein Smartphone-Ticket.<br/>' +
            '<a href="https://play.google.com/store/apps/details?id=com.skidata.key2ski" target="_blank">Android</a><br/>' +
            '<a href="https://apps.apple.com/at/app/key2ski/" target="_blank">iOS</a><br/>' +
            'Bitte unbedingt die Einführung der App befolgen!\n',

        // new
        productionTypeProDirectUsage:
            'Direkter, kontaktloser Liftzugang, ohne an die Kasse zu müssen',
        productionTypeProRechargeable:
            'Auf Keycards kannst du immer wieder Tickets online buchen',
        productionTypeProFastBooking:
            'Keycard-Daten sind digital in deinem Webshop Konto gespeichert für sekundenschnelles Buchen',

        productionTypeConDeliveryTime:
            'Bei Keycard-Neubestellung: Lieferzeit einplanen! DE / AT = bis 4 Werktage, Rest EU bis zu 7 Werktage',
        productionTypeConDeliveryTimeInternal:
            'Lieferzeit einplanen! DE / AT = bis 4 Werktage, Rest EU = bis zu 7 Werktage',

        productionTypeProNoKeycard: 'Ticketbuchung ohne Keycard',
        productionTypeConDeposit:
            'Pfandgebühren können erhoben werden, erhältst du vor Ort bei Ticketrückgabe retour',

        productionTypeProSmartphone:
            'Direkter, kontaktloser Liftzugang, mit deinem Smartphone',
        productionTypeConAppRequired:
            'WICHTIG: Lade zuerst die „key2ski by Skidata“ App auf dein Smartphone (nur für kompatible Geräte verfügbar) und buche anschließend dein Ticket mit der Buchungsoption',

        depositCosts: 'Pfandgebühr',
        keycardCosts: 'Keycardpfand /-kosten',
        insuranceCosts: 'Versicherung (inkl. 11 % Vers. Steuer)',

        chooseTicketType: 'Wie möchtest du dein Ticket buchen?',

        'Favorite.like': 'Favorit',
        'Favorite.dislike': 'Favorit entfernen',

        coreTo: 'bis',

        gtcNoticePart1:
            'Mit dem Klick auf ”kostenpflichtig bestellen ” stimme ich den geltenden',
        gtcNoticePart2:
            'zu und bestätige die Richtigkeit meiner eingegebenen Daten.',

        gtc: 'AGBs',
        dataPrivacyPolicies: 'Datenschutzbestimmungen',

        photoUpdateSuccessful: 'Ihr Foto wurde erfolgreich geändert',

        costKeycard: 'Kosten Keycard',

        and: 'und',

        changeKeycardImageExplanation:
            'Für diese Keycard kannst du hier ein digitales Foto hochladen oder dieses ändern',

        changePhotoData: 'Fotodaten ändern',

        chooseBookingBeforePay:
            'Bitte links Buchungsoptionen für die Tickets auswählen',

        chooseBookingBeforePayMobile:
            'Bitte Buchungsoptionen für die Tickets auswählen',

        productFilterNoTickets:
            'Für diese Auswahl gibt es bei dieser Bergbahn zurzeit keine Tickets',

        productFilterSelectPerson: 'Person auswählen',

        productSelectionNoResults: 'Für diese Auswahl gibt es keine Produkte',

        identificationForm: `Die angegebene Person muss zwischen {{from}} und {{to}} geboren sein.`,

        resetPasswordCustomerHasNoPassword:
            'Dieser Account ist mit einem Social-Media Account verknüpft und verfügt über kein Passwort',

        registerKeycardExplanation:
            'Jede Keycard, deren Seriennummer mit „01-…“ beginnt, kannst du hier registrieren und sofort zur Onlinebuchung nutzen!<br/><br/> <strong>Wichtiger Hinweis:</strong>Jede manuell registrierte Keycard darf nur von der Person zur Online-Ticketbuchung genutzt werden, deren persönliche Daten hier digital hinterlegt werden. Diese digitalen Daten werden bei einer Ticketbuchung an die Bergbahn übermittelt und haben Verbindlichkeit / Gültigkeit. Falschangaben können zur Ticketungültigkeit und weiteren rechtlichen Folgen führen (siehe AGBs der Bergbahn, bei welcher du buchst).',
        productFilterDateDisabled:
            'FÜr diesen Tag sind keine Tickets verfügbar',

        invalidProfile:
            'Die gespeicherten Kreditkartendaten sind ungültig oder abgelaufen und wurden gelöscht. Um die Zahlung abzuschließen, kannst du das Kartenspeichern hier aktivieren und im nächsten Schritt deine neuen Kreditkartendaten angeben.',

        select: 'Auswählen',
        resetSelection: 'Auswahl zurücksetzen',
        profileExpirationDate: 'Ablaufdatum',

        customerService: 'Kundenservice',

        links: 'Links',
        ticketOffers: 'Ticketangebote',
        orderKeycards: 'Keycards bestellen',
        buyVouchers: 'Gutscheine kaufen',
        forB2b: 'Für Unternehmen (B2B)',
        followUs: 'Folge uns',
        loginDeleted: 'Login wurde gelöscht',
        loginDeleteError:
            'Fehler beim Löschen des Logins. Versuchen Sie es später noch einmal',
        openingHours: 'Mo. - Fr. 8:00 bis 16:00 Uhr',
        selectDate: 'Datum auswählen',
        accept: 'Übernehmen',

        toPayment: 'zur Bezahlung',
        placeOrder: 'Kostenpflichtig bestellen',
        lookFurther: 'weiter stöbern',

        back: 'zurück',

        backToSuggestions: 'Vorschläge anzeigen',

        addressMissingError:
            'Addressdaten fehlen. Bitte gib deine vollständige Adresse ein',

        backToProductsPage: 'zu den Bergbahnen',

        yourCart: 'Warenkorb',
        personalizeTickets: 'Tickets personalisieren',
        personalizeTicketsSubtitle:
            'Wähle je Ticket eine Buchungsoption und gib die persönlichen Daten der Person an, die das Ticket nutzen wird.',

        personIdentificationSelectionTitle:
            'Für wen und wie möchtest du dein Ticket buchen',

        pleaseSelectPerson: 'Bitte Person auswählen',
        changeBookingOption: 'Buchungsoption ändern',
        chosenBookingOption: 'Gewählte Buchungsart:',

        personIdentSelectError:
            'Personalisiere dein(e) Ticket(s) mit "Auswählen"',
        personIdentSelectSuccess: 'Alles erfolgreich gewählt',
        personIdentSelectInfo:
            'Wähle aus, für welche Person das Ticket gebucht werden soll',

        duration: 'Dauer',
        season: 'Saison',
        price: 'Preis',
        updateCart: 'Warenkorb aktualisieren',
        add: 'hinzufügen',

        selectWayOfBookingOption: 'Buchungsoption wählen',

        productionType_KEYCARD:
            'Eine vorhandene Keycard aufladen, registrieren oder neu bestellen.',
        productionType_INTERNAL_PRODUCTION:
            'Keycard wird dir von der Bergbahn per Post zugestellt.',
        productionType_PICKUP:
            'Du erhältst einen Pick-up-Voucher per Mail und holst damit dein Ticket vor Ort an der Kasse oder Pick-up-Automaten (wenn vorhanden) ab.',
        productionType_BARCODE:
            'Du erhältst dein eTicket inkl QR-Code per Mail und kannst damit direkt zum Lift gehen.',
        productionType_E_TICKET:
            'Nur in Kombination mit der „key2ski by SKIDATA“ App nutzbar! ',

        yourOrderSummary: 'Deine Bestellung im Überblick',

        deliveryAndBillingAddress: 'Deine Liefer-/Rechnungsadresse',
        changeAddress: 'Adresse ändern',

        selectPaymentOption: 'Zahloption wählen',
        generalFaqs: "Allgemeine FAQ's",
        howToBookYourTicket: 'Wie möchtest du dein Ticket erhalten?',

        additionalTicketInformation: 'Weitere Ticketinformation',

        kilometers: 'km',

        slopeOverview: 'Pistenaufteilung',
        toProductsSelection: 'Tickets verfügbar',
        'oeamtc.toProductsSelection.noOffers': 'Zu den Details',

        checkout: 'Checkout',

        toLiftOperators: 'Bergbahnen',
        numberOfLifts: 'Anzahl der Bahnen',

        customFaqTitle: 'FAQ speziell für unsere Destination & Angebote',

        personalizeYourTicket: '{{productionType}} personalisieren',
        personalizeYourKeycard:
            'Auf welche Keycard soll das Ticket gebucht werden?',

        choosePersonToUse: 'Wähle die Person aus, die das Ticket nutzen wird.',
        noSuggestionAvailable:
            'Gewünschte Person nicht aufgelistet? Lege neue Personendaten mit dem Button unten an.',

        choosePersonToUseKeycard:
            'Wähle die Person aus, die das Ticket nutzen wird.',
        noSuggestionAvailableKeycard:
            'Keine Keycard für die gewünschte Person vorhanden? Bestelle oder registriere eine Keycard über die Buttons unten.',

        enterPersonalDataSuggestion:
            'Gib die persönlichen Daten der Person an, die das Ticket nutzen wird.',

        registerKeycardTitle:
            'Registriere hier deinen alten Skipass für die Ticketbuchung bei starjack.',
        registerKeycardSubtitle:
            'Gib deine persönlichen Daten und die Seriennummer der vorhandenen Keycard an.',

        enterRedeemCode: 'Gutscheincode eingeben',

        chooseAndAddTicket:
            'Wähle dein gewünschtes Ticket und gib die Personenanzahl bekannt. Danach Ticket in den Warenkorb legen.',

        selectTicketType: 'Wähle den gewünschten Tickettyp',
        chooseBetweenSingleOrPackage:
            'Wähle zwischen Einzel oder Gruppentickets',
        chooseDate: 'Wann willst du fahren?',
        chooseGroup: 'Wähle deine Gruppe',

        noSuggestionKeycard:
            'Keine Keycard aufgelistet? Bestelle oder registriere eine Keycard über die Buttons unten.',
        noSuggestionEticket:
            'Keine Person auswählbar? Lege neue Personendaten mit dem Button unten an.',
        noSuggestionPickup:
            'Keine Person auswählbar? Lege neue Personendaten mit dem Button unten an.',

        crop: 'zuschneiden',

        imageOnlyCropped:
            'Bitte den gewählten Ausschnitt mit "Zuschneiden" bestätigen.',

        voucherTeaserTitle: 'Gutschein schenken',
        voucherTeaserText:
            'Der starjack print@home Wertgutschein ist die ultimative Liebeserklärung für BergsportlerInnen. Einlösbar bei allen starjack-Bergbahnen für eine Vielzahl von Sommer- und Winterangeboten. Das perfekte Last-Minute-Geschenk.',
        voucherTeaserButtonText: 'zu den Wertgutscheinen',
        searchFaqs: 'FAQs durchsuchen',

        choosePhoto: 'Foto wählen',

        chooseNumberOfPerson: 'Wähle oben die Anzahl an Tickets',
        discountName: 'Rabatt',

        lifOperatorsNearby: 'Bergbahnen in der Nähe von {{location}}',
        liftOperators: 'Bergbahnen',

        ordersMerchandise: 'Merchandise',
        ordersKeycards: 'Keycard(s)',
        ordersTickets: 'Ticket(s)',
        ordersVouchers: 'Gutschein(e)',
        orderNumber: 'Bestellnummer',
        orderPosition: 'Bestellposition',

        keycardPrice: 'Keycardpreis',

        deliveryAddress: 'Lieferadresse',
        billingAddress: 'Rechnungsadresse',

        billingSameAsDelivery: 'Rechnungsadresse entspricht der Lieferadresse',

        lockedBalance:
            'Sie haben {{value}} EUR geblocktes Guthaben. Geblocktes Guthaben wird innerhalb der nächsten 30 Minuten für die Verwendung wieder zur Verfügung gestellt',

        buyStarjackVoucher: 'Starjack-Wertgutschein kaufen',

        'error.addressValidation.missing_street_number':
            'Bitte geben Sie eine Hausnummer an',

        'error.addressValidation.missing_postal_code':
            'Bitte geben Sie eine Postleitzahl an',
        'error.addressValidation.missing_locality':
            'Bitte geben Sie einen Ort an',
        'error.addressValidation.missing_general':
            'Fehler bei der Suche der Adresse. Bitte geben Sie die Adresse manuell ein',

        'googleAddressAutoComplete.enterAndSearch':
            'Adresse eingeben und suchen',
        enterManually: 'Manuelle Eingabe',
        searchAddress: 'Adresse suchen',

        'redeem_voucher.incert_voucher_not_redeemable':
            'Gutschein nicht einlösbar',
        'redeem_voucher.incert_voucher_not_partly_redeemable':
            'Gutschein nicht teilweise einlösbar',
        'redeem_voucher.incert_voucher_depleted': 'Gutscheinwert verbraucht',
        'redeem_voucher.incert_only_possible_with_integration_token':
            'Gutschein nur bei gültigen Anbietern verwendbar',
        'redeem_voucher.incert_not_active': 'Gutschein nicht aktiv',
        'redeem_voucher.invalid_currency': 'Ungültige Währung',
        'redeem_voucher.invalid_amount': 'Ungültiger Wert',
        'redeem_voucher.min': 'Voucher Mindestbetrag ungültig',
        'redeem_voucher.voucher_not_paid': 'Voucher noch nicht bezahlt',
        'redeem_voucher.incert_not_supported_in_portal':
            'Dieser Incert Code wird auf dieser Seite nicht unterstützt',
        'redeem_voucher.voucher_already_exists':
            'Dieser Gutschein Code wurde bereits verwendet',

        'keycard.multi_usage.title': 'Keycard mehrfach verwendet',
        'keycard.multi_usage.text_body':
            'Du kannst beliebig viele Tickets für verschiedene Tage und auch für unterschiedliche Bergbahnunternehmen auf deine personalisierte starjack Keycard buchen. Jedoch maximal 2 Tickets für den gleichen Gültigkeitstag bzw. der gleichen Gültigkeitsdauer. Jede Person benötigt eine separate Keycard.',

        'information_dialog.accept': 'Ich verstehe',

        checkoutIdMissing:
            'Ihr Bestellvorgang ist abgelaufen oder ungültig. Bitte starten Sie die Bestellung erneut.',

        invoiceAddressSameAsShipping:
            'Für die Rechnungsadresse wird die Lieferadresse verwendet',

        addressDialogInvoiceTitle: 'Rechnungsadresse ändern / speichern',
        addressDialogShippingTitle: 'Lieferadresse ändern / speichern',

        invoiceAddressSaved: 'Rechnungsadresse gespeichert',
        shippingAddressSaved: 'Lieferadresse gespeichert',

        personIdentificationAdd: 'Person wurde erfolgreich hinzugefügt',
        personIdentificationAddError:
            'Fehler beim Speichern der Person. Bitte versuchen Sie es später noch einmal',

        checkSerialNumberInput: 'Bitte prüfen Sie Ihre Seriennnummer',

        POSSIBLE: 'POSSIBLE',
        START_DATE_MAYBE_TOO_EARLY:
            'Diese Keycard könnte nicht rechtzeitig zum Gültigkeitsdatum verfügbar sein. Bite prüfen Sie die Lieferzeit',
        START_DATE_TOO_EARLY_HARD:
            'Diese Keycard wird nicht mehr rechtzeitig vor dem Startdatum verfügbar sein',
        AGE_OUTSIDE_RANGE: 'Geburtstag außerhalb des gültigen Alterbereichs',
        IDENTIFICATION_ALREADY_USED:
            'Diese Person wurde bereits gewählt (eventuell für ein anderes Ticket)',
        INCOMPATIBLE_TICKET_PRODUCER:
            'Für diese Bergbahn kann dieses Ticket nicht auf die Keycard gebucht werden',
        INCOMPATIBLE_IDENTIFICATION_TYPE:
            'Diese Person ist für die gewählte Buchungsart nicht möglich',
        IDENTIFICATION_PHOTO_REQUIRED: 'Foto erforderlich',

        EXTERNAL_CODE_NOT_PRESENT: 'Extern Code nicht vorhanden',

        TICKET_IDENTIFICATION_TYPE_NOT_SET: 'Bitte wähle eine Buchungsart aus',
        NO_IDENTIFICATION_ASSIGNED:
            'Bitte wähle auf welche Person das Ticket gebucht werden soll',

        createKeycardError:
            'Fehler beim Speichern deiner Keycard Eingabe. Bitte versuche es später noch einmal.',
        registerAnotherKeycard: 'Weitere Keycard registrieren',
        paymentFailed:
            'Die Bezahlung ist fehlgeschlagen. Bitte versuche es später noch einmal',

        toPay: 'zu Bezahlen',
        remainingCredit: 'Verbleibendes Guthaben',

        checkoutValidationTicketGeneralError:
            'Fehler beim Buchen dieses Tickets',

        checkoutValidationTicketGeneralWarning:
            'Bitte überprüfen Sie Ihre Eingabe noch einmal',

        readMore: 'mehr dazu',
        checkoutValidation_photo_required:
            'Für das Ticket ist ein Foto verpflichtend.',
        checkoutValidation_no_identification_assigned:
            'Bitte wähle eine Person aus, für die das Ticket gebucht werden soll.',
        checkoutValidation_start_date_too_early:
            'Die Gültigkeit des Tickets startet, bevor die Keycard bei dir eintreffen kann. Bitte wähle ein späteres Gültigkeitsdatum, indem du das Ticket vom Warenkorb entfernst und mit einem späteren Gültigkeitsdatum wieder in den Warenkorb legst.',
        checkoutValidation_start_date_too_early_hard:
            'Die Gültigkeit des Tickets startet, bevor die Keycard bei dir eintreffen kann. Bitte wähle ein späteres Gültigkeitsdatum, indem du das Ticket vom Warenkorb entfernst und mit einem späteren Gültigkeitsdatum wieder in den Warenkorb legst.',
        checkoutValidation_start_date_in_past:
            'Im Warenkorb befinden sich ungültige Tickets (Startdatum in der Vergangenheit). Lösche das Ticket und lege es nochmals neu in den Warenkorb, um die Buchung abzuschließen.',
        checkoutValidation_start_date_too_early_soft:
            'Es könnte sein, dass die Keycard bis zum Startdatum des Tickets noch nicht eingetroffen ist.',
        checkoutValidation_invalid_ref:
            'Fehler bei der Zuordnung der Buchungsart eines Tickets. Bitte wähle die Buchungsart bzw. die Keycard aus, auf welche das Ticket gebucht werden soll.',
        checkoutValidation_internal_error:
            'Es ist ein Fehler bei unserem Payment Anbieter aufgetreten. Bitte beachte, dass wir derzeit nur Mastercard und VISA unterstützen.',
        checkoutValidation_token_expired:
            'Deine Anmeldung ist abgelaufen. Bitte melde dich erneut an und wiederhole die Bestellung.',
        checkoutValidation_duplicate_ticket:
            'Du hast bereits ein Ticket auf der gewählten Keycard für diesen Zeitraum gebucht.',
        checkoutValidation_no_product_in_checkout:
            'Sie haben keine Produkte im Warenkorb',
        checkoutValidation_no_shipping_address: 'Keine Lieferadresse vorhanden',
        checkoutValidation_ticket_expired:
            'Das Ticket ist abgelaufen. Bitte entferne das Ticket aus dem Warenkorb und lege es erneut hinein, um die Buchung abzuschließen.',

        checkoutValidation_ticket_identification_type_not_set:
            'Bitte wähle eine Person aus, für die das Ticket gebucht werden soll.',
        identificationDialogNoOptionTitle:
            'Wie möchtest du dein Ticket erhalten?',

        checkoutTicketsTitle: 'Tickets',
        checkoutIdentificationsTitle: 'Keycards',
        checkoutVouchersTitle: 'Gutscheine',

        productsAvailable: 'Tickets verfügbar',
        noProductsAvailable: 'Im Schönheitsschlaf',

        'orderedVouchers.status.PAID': 'Bezahlt',
        'orderedVouchers.status.NEW': 'Neu',
        'orderedVouchers.status.REDEEMED': 'Eingelöst',

        myVouchers: 'Meine Gutscheine',

        productFilterChooseTicketType: 'Tages- oder Saisonkarte',
        productFilterProductCategory: 'Produktkategorie',

        productFilterChooseTicketVariant: 'Einzel- oder Gruppenticket',
        productFilterChoosePersons: 'Wer fährt',

        productSelectionCompleteFilter:
            'Verwende den Filter oben um passende Tickets zu finden',

        productSelectionFilterNoMoreOptions:
            'Hier gibt es keine weitere Auswahlmöglichkeit',

        'orderedVouchers.redemptionDate': 'Eingelöst am',

        'insurance.status.CREATED': 'Erstellt',
        'insurance.status.BOOKED': 'Gebucht',
        'insurance.status.PERMISSION_BLOCKED': 'Schaden in Bearbeitung',
        'insurance.status.PAID': 'Bezahlt',
        'insurance.status.NEW': 'Neu',
        'insurance.status.CANCELED': 'Storniert',
        'insurance.status.REVOKED': 'Widerrufen',

        'myInsrancePage.title': 'Meine Versicherungen',
        'myInsurancePage.tabs.label.current': 'Aktuelle Versicherungen',
        'myInsurancePage.tabs.label.expired': 'Abgelaufene Versicherungen',
        'myInsurancePage.deleteDialog.title.cancel':
            'Meine Versicherung stornieren',
        'myInsurancePage.deleteDialog.title.refund':
            'Meine Versicherung widerrufen',
        'myInsurancePage.deleteDialog.body.cancel':
            'Möchtest du deine Versicherung wirklich stornieren?',

        'myInsurancePage.deleteDialog.body.refund':
            'Möchtest du deine Versicherung wirklich widerrufen?',

        'myInsurancePage.content.label.insuranceTaker': 'Versicherung für',

        'myInsurancePage.deleteDialog.caption.cancel': `
            Mit dem Klick auf „Versicherung stornieren“ bestätigst du, dass der Betrag auf deinem Webshop-Konto gutgeschrieben wird. 
            Wenn du eine Auszahlung auf dein gewähltes Zahlungsmittel wünschst, sende bitte eine E-Mail an <a href="mailto:support@starjack.com">support@starjack.com</a> 
            mit deiner Versicherungsscheinnummer/Polizzennummer oder rufe unter der Telefonnummer <a href="tel:support@starjack.com">+43624673430999</a> an. 
            Wir überweisen den Betrag dann auf dein gewähltes Zahlungsmittel zurück. 
            Mehr Informationen gibt es in unseren <a href="/faq">FAQs</a>.
        `,

        'myInsurancePage.deleteDialog.button.cancelInsurance':
            'Versicherung stornieren',
        'myInsurancePage.deleteDialog.button.revokeInsurance':
            'Versicherung widerrufen',

        'myInsurancePage.deleteDialog.caption.refund': `
            Mit dem Klick auf „Versicherung widerrufen“ bestätigst du, dass der Betrag auf deinem Webshop-Konto gutgeschrieben wird. 
            Wenn du eine Auszahlung auf dein gewähltes Zahlungsmittel wünschst, sende bitte eine E-Mail an 
            <a href="mailto:support@starjack.com">support@starjack.com</a> mit deiner Versicherungsscheinnummer/Polizzennummer oder rufe unter der Telefonnummer <a href="tel:support@starjack.com">+43624673430999</a> an. 
            Wir überweisen den Betrag dann auf dein gewähltes Zahlungsmittel zurück. 
            Mehr Informationen gibt es in unseren <a href="/faq">FAQs</a>.
        `,

        'myInsurancePage.deleteDialog.cancel.response.success':
            'Versicherung erfolgreich storniert',
        'myInsurancePage.deleteDialog.refund.response.success':
            'Versicherung erfolgreich widerrufen',
        'myInsurancePage.deleteDialog.cancel.response.error':
            'Fehler beim Stornieren der Versicherung. Bitte versuche es später noch einmal',
        'myInsurancePage.deleteDialog.refund.response.error':
            'Fehler beim Widerrufen der Versicherung. Bitte versuche es später noch einmal',

        'myInsurancePage.noInsurance.current': 'Keine Versicherungen vorhanden',
        'myInsurancePage.noInsurance.expired':
            'Keine abgelaufenen Versicherungen vorhanden',

        'myInsurancePage.policyNumber': 'Vers.schein-/Polizzennummer',

        'myInsurancePage.insuranceName.label': 'Versicherung',
        'myInsurancePage.liftoperator.label': 'Bergbahn',
        'myInsurancePage.ticketName.label': 'Ticket',

        'insurance.productAddContainer.body.safteyPlus':
            'Möchtest du dich absichern hinsichtlich deiner Kosten für Skipass, Skikurs oder Skilehrertraining für den Fall, dass du oder eine andere Person einen Unfall auf einer Skipiste erleiden? Des Weiteren möchtest du dich und/oder weitere Personen gegen die mit dem Unfall verbundenen und in unserer Übersicht dargestellten zusätzlichen finanziellen Risiken, absichern? Wenn ja, dann wähle "Schutz hinzufügen".',
        'insurance.productAddContainer.body.refundPlus':
            'Möchtest du dich absichern hinsichtlich deiner Kosten für Skipass, Skikurs oder Skilehrertraining für den Fall, dass du oder eine andere Person einen Unfall auf einer Skipiste erleiden? Wenn ja, dann wähle "Schutz hinzufügen".',
        'insurance.productAddContainer.button.label': 'Schutz hinzufügen',
        'insurance.productAddContainer.button.labelAdded': 'Schutz hinzugefügt',
        'insurance.productAddContainer.chip.label':
            'Versicherung {{tariffName}} hinzugefügt',

        'insurance.pricePerPersonPrice.label':
            'inkl. {{tariffPrice}} Versicherung',

        'checkoutPage.insuranceAlert.label':
            'Bei einer Bestellung mit Versicherungsschutz akzeptieren und anschließend die Erklärung herunterladen um den Kauf abzuschließen.',

        'insuranceProductPage.title':
            'Safety+ oder Refund+ ab 3,99 € pro Skipass sorgt für unbeschwerten Ski-&Snowboard-Spaß!',

        'insuranceProductPage.content': `
                Wir bieten dir ab sofort auch die Möglichkeit, eine Versicherung zu deinem Ticket abzuschließen. 
                Dafür sind wir jetzt auch als Versicherungsagent tätig. Zusammen mit unseren Partnern, der Société Générale 
                Insurance und der One Underwriting Agency GmbH, bieten wir dir maßgeschneiderte Ski- und Snowboardabsicherungen an.
        `,
        'insuranceProductPage.list.item0':
            'Füge deinem Ticket einfach die passende Versicherung hinzu, um deinen Winterurlaub sorgenfrei zu genießen.',
        'insuranceProductPage.list.item1':
            'Du hast Fragen zum Versicherungsprodukt? Mehr Informationen findest du <a>hier</a>.',
        'insuranceProductPage.list.item2':
            'Informationen, wie man einen Unfall/Schaden meldet, findest du <a target="_blank" rel="noreferrer" href="/report-claim">hier</a>.',
        'insuranceProductPage.list.item3':
            'Informationen zur Stornierung, zum Widerruf und zur Widerrufsfrist findest du <a target="_blank" rel="noreferrer" href="/faq">hier</a>.',
        'insuranceProductPage.list.item4':
            'Mehr allgemeine Informationen findest du in den <a target="_blank" rel="noreferrer" href="/faq">FAQs</a>.',

        'insuranceProductPage.openingHours': 'Mo . - Fr. 8:00 bis 16:00 Uhr',

        'insuranceProductPage.supportCenter.help':
            'Bei technischen Problemen während des Abschlusses wende dich gerne an unser Service-Center:',

        'insuranceReportClaimPage.title': "Schaden melden? So geht's:",
        'insuranceReportClaimPage.content': `
            Wenn Du einen Ski- oder Snowboard-Unfall hattest und einen Schaden melden möchtest, 
            so ist das rund um die Uhr online möglich.<br /><strong style="display:inline">Wichtig:</strong> Jede versicherte Person sollte den Schaden selbst melden*, 
            daher leite bitte diese Info weiter, wenn jemand, für den du den Schutz bezahlt hast, einen Unfall hatte. 
            Jede/r Versicherte benötigt dazu den eigenen Versicherungsschein/die Polizze.
        `,

        'insuranceReportClaimPage.list.step0.title':
            'Versicherungsschein-/Polizzennummer bereithalten:',
        'insuranceReportClaimPage.list.step0.subtitle':
            'Du findest sie in deinem Versicherungsschein/in Deiner Polizze.',

        'insuranceReportClaimPage.list.step1.title':
            'Klick auf den Button „Schaden melden“ oder auf den Link zum Schadenportal unseres Partners CallUs:',
        'insuranceReportClaimPage.list.step1.subtitle':
            'CallUS handelt im Auftrag der Versicherung. Du findest den Link in den <a target="_blank" rel="noreferrer" href="/faq">FAQs</a>.',

        'insuranceReportClaimPage.list.step2.title':
            'Schadenformular ausfüllen:',
        'insuranceReportClaimPage.list.step2.subtitle':
            'Nun füllst du das <a rel="noreferrer" href="https://starjack.call-us.at/de">Online-Schadenformular</a> – bitte vollständig - aus.',

        'insuranceReportClaimPage.list.step3.title': 'Dokumente hochladen:',
        'insuranceReportClaimPage.list.step3.subtitle':
            'Dazu zählen z .B. ärztliche Berichte, Rechnungen oder Skikurs-Buchungsbelege.',

        'insuranceReportClaimPage.list.step4.title': 'Absenden',
        'insuranceReportClaimPage.list.step4.subtitle':
            'Sende das Formular ab. Ab jetzt kümmert sich der Versicherer um die Meldung. Er wird sich so schnell wie möglich bei dir melden.',

        'insuranceReportClaimPage.bottom.label':
            'Falls Du uns telefonisch erreichen möchtest, hilft dir das Team von CallUs weiter unter <a href="tel:+42131670833">+42131670833</a>. Servicezeiten: täglich von 8:00 bis 16:00 Uhr.',

        'insuranceReportClaimPage.asterix': '* Das gilt nicht für Kinder',

        'insuranceReportClaimPage.button.label': 'Schaden melden',

        'myKeycardsPage.registration.success':
            'Die Keycard wurde erfolgreich registriert',

        'checkoutTicketCollection.insuranceSwitch.insurance': 'Versicherung',

        'sidebar.merch.title': 'Merchandise',

        'home.merch.title':
            'Zeig deine Liebe für den Wintersport – mit unserem exklusiven Merchandise!',

        'home.merch.description':
            'Du liebst es, die Pisten unsicher zu machen und möchtest deine Begeisterung\n' +
            'auch abseits des Skigebiets zeigen? Dann haben wir genau das Richtige für dich! - ob auf der Piste, im Alltag\n' +
            'oder beim Après-Ski. Entdecke jetzt unser Sortiment und finde deine neuen Lieblingsstücke!',

        'home.merch.list.material.hightlight': 'Hochwertige Materialien',
        'home.merch.list.material.text':
            'Für maximalen Komfort und Langlebigkeit.\n' +
            'Nachhaltig: Wir legen Wert auf umweltfreundliche Produktion und langlebige Produkte',

        'home.merch.list.sustainable.hightlight': 'Nachhaltig',
        'home.merch.list.sustainable.text':
            'Wir legen Wert auf umweltfreundliche Produktion und langlebige Produkte.',

        'home.merch.button': 'Jetzt shoppen',
        'home.merch.caption':
            '... und die Leidenschaft mit in den Alltag nehmen!',

        'complaintsPage.title': 'Beschwerden',
        'complaintsPage.header': 'Sie möchten eine Beschwerde einreichen?',
        'complaintsPage.intro':
            'Wir sind stets bemüht, Kunden hinsichtlich ihrer Wünsche und Bedürfnisse in allen Belangen des Versicherungsgeschäftes bestmöglich zu servicieren. Bedauerlicherweise kann es in dem einen oder anderen Fall auch vorkommen, dass ein Kunde mit der erbrachten Leistung nicht zufrieden ist. In diesem Fall ersuchen wir uns diese Unzulänglichkeiten mitzuteilen.',
        'complaintsPage.instructions':
            'Um Ihre Beschwerde effizient bearbeiten zu können ersuchen wir um Bekanntgabe zu folgenden Punkten:',
        'complaintsPage.fullName':
            'Nennen Sie bitte Ihren vollständigen Namen und Ihre Adresse',
        'complaintsPage.reason':
            'Beschreiben Sie bitte den Grund Ihrer Unzufriedenheit so detailliert wie möglich',
        'complaintsPage.policyNumber':
            'Geben Sie bitte Ihre Polizzen- oder Schadennummer bekannt',
        'complaintsPage.documents':
            'Sollten für die Bearbeitung Dokumente relevant sein oder Sie Fragen dazu haben, fügen Sie diese bitte bei',
        'complaintsPage.contact':
            'Versicherungsnehmer werden ersucht, sich bei Beschwerden in erster Linie direkt an',
        'complaintsPage.sjack': 'die SJack GmbH oder',
        'complaintsPage.insuranceCompany':
            'direkt an das Versicherungsunternehmen zu werden.',
        'complaintsPage.email':
            'Ihre Anregungen oder allfällige Beschwerden über die SJack GmbH richten Sie bitte an:',
        'complaintsPage.postal':
            'Ihre Beschwerde kann natürlich gerne auch postalisch an uns gerichtet werden:',
        'complaintsPage.address':
            'SJack GmbH<br />Am Bühel 6<br />AT - 6830 Rankweil / Vorarlberg<br />(im Folgenden „Starjack“)',
        'complaintsPage.investors':
            'Kleinanleger, die Beschwerde gegen einen Hersteller von Versicherungsanlageprodukten oder gegen ein Basisinformationsblatt von Versicherungsanlageprodukten einreichen, werden über die Weiterleitung ihrer Beschwerde an die zuständige Versicherung informiert und erhalten eine sachdienliche Antwort.',
        'complaintsPage.otherOptions':
            'Sie haben auch die Möglichkeit, sich zur Weiterbearbeitung Ihrer Beschwerde an andere Stellen zu wenden, insbesondere: Beschwerdestelle über Versicherungsvermittler im BMAW, Bundesministerium für Arbeit und Wirtschaft, Abteilung VI/A/1 (Gewerberecht) Stubenring 1, 1010 Wien,',
        'complaintsPage.consumerArbitration':
            'Schlichtungsstelle für Verbrauchergeschäfte Mariahilfer Straße 103/1/18, 1060 Wien,',
        'complaintsPage.dataProtection':
            'Österreichische Datenschutzbehörde Barichgasse 40-42, 1030 Wien,',
    },
};
